import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import cogoToast from "cogo-toast";

import CollapsibleWrapper from "../CollapsibleWrapper";
import CustomColumnPopup from "./CustomColumnPopup";
import UpgradeButton from "../../shared/Button/UpgradeButton";
import HelpTooltip from "../../shared/HelpTooltip";
import DiscoveryLockedFieldMark from "../../shared/DiscoveryLockedFieldMark";
import {
  DISCOVERY_COLUMN_LABELS,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  DISCOVERY_DISPLAY_DOLLAR_CHANGE,
  DEFAULT_DISCOVERY_COLUMNS,
  DISCOVERY_MAX_COLUMN_CNT,
  DISCOVERY_MAX_CUSTOM_COLUMN_CNT,
} from "../../constants";
import { getDiscoveryColumnTitle, getPlanSubscriptionStatus, isColumnAccessible } from "../../util";

import { Types as ConfigTypes } from "../../store/config";

import styles from "./index.module.scss";

const COLUMN_LIMIT_LINE_ID = "limit-line";

function DiscoverySettings(props) {
  const { setting } = props;

  const dispatch = useDispatch();
  const {
    auth,
    // dashboard: { layout },
  } = useSelector((state) => state);

  // const { menu } = getActiveLayoutConfig(layout);

  // if (!menu[setting.id]) {
  //   return (
  //   <div className={styles['setting-wrapper']}>
  //     <div className="d-flex align-items-center">
  //       <label className="small mr-2 mb-0 setting-panel-label-1">{setting.title}</label>
  //       <label className="small mb-0 mt-1">
  //         <span className="text-symbol">(Disabled)</span>
  //       </label>
  //       </div>
  //   </div>
  //   )
  // }

  const plan = getPlanSubscriptionStatus(auth);

  const [discoverySaving, setDiscoverySaving] = useState(false);
  const [vibrate, setVibrate] = useState({});
  const [showCustomColumnPopup, setShowCustomColumnPopup] = useState(false);
  const [customColumnPopupEditItem, setCustomColumnPopupEditItem] = useState(null);

  const getColumnUniqueStr = (item) => {
    return item.column + (item.timeframe || "");
  };

  const onSaveCustomColumn = (data, editItem) => {
    const { column, timeframe } = data;
    const columnItem = {
      column,
      custom: true,
      timeframe,
    };

    if (activeColumns.length >= DISCOVERY_MAX_COLUMN_CNT) {
      columnItem.hidden = true;
    }

    // Validate data
    for (const item of setting.value) {
      if (item.custom) {
        if (!editItem || !(editItem.column === item.column && editItem.timeframe === item.timeframe)) {
          if (item.column === column && item.timeframe === timeframe) {
            cogoToast.warn(`A custom column already exists with the same config`);
            return;
          }
        }
      }
    }

    let newColumnList;
    if (!editItem) {
      if (hasDisplayOption(column)) {
        columnItem.display = DISCOVERY_DISPLAY_PERCENT_CHANGE;
      }
      newColumnList = [...activeColumns, columnItem, ...hiddenColumns, ...inactiveColumns];
    } else {
      newColumnList = [...activeColumns, ...hiddenColumns, ...inactiveColumns];
      for (const item of newColumnList) {
        if (item.custom && item.column === editItem.column && item.timeframe === editItem.timeframe) {
          item.column = column;
          item.timeframe = timeframe;
          break;
        }
      }
    }
    dispatch({
      type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
      value: {
        id: setting.id,
        columns: newColumnList,
      },
    });
    setShowCustomColumnPopup(false);
    setCustomColumnPopupEditItem(null);
    setDiscoverySaving(false);
    setTimeout(() => setDiscoverySaving(true), 100);
  };

  const onDeleteCustomColumn = (deleteItem) => {
    const newColumnList = [...activeColumns, ...hiddenColumns, ...inactiveColumns];
    const index = newColumnList.findIndex((item) => {
      return item.custom && item.column === deleteItem.column && item.timeframe === deleteItem.timeframe;
    });
    if (index > -1) {
      newColumnList.splice(index, 1);
      dispatch({
        type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
        value: {
          id: setting.id,
          columns: newColumnList,
        },
      });
      setDiscoverySaving(false);
      setTimeout(() => setDiscoverySaving(true), 100);
    }
  };

  const hasDisplayOption = (column) => {
    return column === "price_dist" || column === "atr" || column === "gap";
  };

  const toggleColumnDisplayOption = (item, option) => {
    const columns = setting.value || DEFAULT_DISCOVERY_COLUMNS;

    dispatch({
      type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
      value: {
        id: setting.id,
        columns: columns.map((item1) => {
          if (item1.column !== item.column || item1.timeframe !== item.timeframe) return item1;
          return {
            ...item1,
            display: option,
          };
        }),
      },
    });

    setDiscoverySaving(false);
    setTimeout(() => setDiscoverySaving(true), 100);
  };

  const toggleColumn = (item) => {
    if (activeColumns.length >= DISCOVERY_MAX_COLUMN_CNT && item.hidden) {
      setVibrate({
        ...vibrate,
        [getColumnUniqueStr(item)]: true,
      });
      setTimeout(
        () =>
          setVibrate({
            ...vibrate,
            [getColumnUniqueStr(item)]: false,
          }),
        500
      );
      cogoToast.warn(
        <span>
          You can have max <b>{DISCOVERY_MAX_COLUMN_CNT}</b> visible columns.
        </span>
      );
      return;
    }
    const columns = setting.value || DEFAULT_DISCOVERY_COLUMNS;
    const updatedColumns = columns.map((item1) => {
      if (item1.column === item.column && item1.timeframe === item.timeframe) {
        return {
          ...item1,
          hidden: !item1.hidden,
        };
      }
      return item1;
    });
    dispatch({
      type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
      value: {
        id: setting.id,
        columns: [
          ...updatedColumns.filter((item) => !item.hidden && isColumnAccessible(item, plan)),
          ...updatedColumns.filter((item) => item.hidden && isColumnAccessible(item, plan)),
          ...updatedColumns.filter((item) => !isColumnAccessible(item, plan)),
        ],
      },
    });

    setDiscoverySaving(false);
    setTimeout(() => setDiscoverySaving(true), 100);
  };

  const renderColumn = (item) => {
    let hasExtraOptions;
    if (hasDisplayOption(item.column) && !item.hidden) {
      hasExtraOptions = true;
    }
    return (
      <div key={`${getColumnUniqueStr(item)}`} className={"mb-1"}>
        <div
          className={`${styles["discovery-column-block"]} ${
            vibrate[getColumnUniqueStr(item)] ? styles["vibrate"] : ""
          } align-items-center item-content mt-1 px-2 py-1 row mx-0`}
        >
          <div
            className={`d-flex align-items-center ${item.hidden ? "text-symbol" : ""} ${
              hasExtraOptions ? "col-6 col-sm-3" : "col-6"
            } order-1 px-0`}
          >
            <i className={`${styles["sort-handler"]} mdi mdi-menu`}></i>
            <span className="small" style={{ whiteSpace: "nowrap" }}>
              {DISCOVERY_COLUMN_LABELS[item.column]}
            </span>
            {item.custom && (
              <DiscoveryLockedFieldMark timeframe={item.timeframe} disabled={item.hidden} palette={"light"} />
            )}
            {item.custom && renderCustomColumnBtnToolset(item)}
          </div>
          {hasExtraOptions && (
            <div
              className={`${styles["discovery-display-option"]} col-12 col-sm-6 d-flex justify-content-start align-items-center order-3 order-sm-2`}
            >
              <span className="custom-radio-wrapper-label">Display: </span>
              {[DISCOVERY_DISPLAY_PERCENT_CHANGE, DISCOVERY_DISPLAY_DOLLAR_CHANGE].map((option) => {
                const isActive = item.display ? item.display === option : DISCOVERY_DISPLAY_PERCENT_CHANGE === option;
                return (
                  <div
                    key={getColumnUniqueStr(item) + option}
                    className={`custom-radio-wrapper ${isActive ? "active" : ""}`}
                    style={{ width: "unset" }}
                    onClick={() => toggleColumnDisplayOption(item, option)}
                  >
                    <span className="custom-radio-badge"></span>
                    <span className="custom-radio-label">{option} CHANGE</span>
                  </div>
                );
              })}
            </div>
          )}
          <div
            className={`d-flex justify-content-end align-items-center ${
              hasExtraOptions ? "col-6 col-sm-3 order-2 order-sm-12" : "col-6 order-12"
            } px-0`}
          >
            {item.hidden ? (
              <div
                className={"btn-toggle-state"}
                onClick={() => {
                  toggleColumn(item);
                }}
              >
                Disabled
              </div>
            ) : (
              <div
                className={"btn-toggle-state active"}
                onClick={() => {
                  toggleColumn(item);
                }}
              >
                Enabled
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderCustomColumnBtnToolset = (item) => {
    // if (item.hidden) {
    //   return null;
    // }
    return (
      <div className={styles["discovery-custom-toolset"]}>
        <button
          className="bg-transparent border-0"
          onClick={() => {
            setShowCustomColumnPopup(true);
            setCustomColumnPopupEditItem(item);
          }}
        >
          <i className="mdi mdi-pencil text-light" />
        </button>
        <button className="bg-transparent border-0" onClick={() => onDeleteCustomColumn(item)}>
          <i className="mdi mdi-close text-white" />
        </button>
      </div>
    );
  };

  const renderColumnLimitLine = () => {
    return (
      <div className={`${styles["column-limit-line"]} mx-3 mx-sm-5`}>
        <hr />
        <span className="small">
          Visible Columns:&nbsp;&nbsp;
          <b>{activeColumns.length}</b>
          &nbsp;/ {DISCOVERY_MAX_COLUMN_CNT}&nbsp;&nbsp;
          <HelpTooltip
            title={"Column Limit Tooltip"}
            placement={"top"}
            content={
              <span style={{ color: "white" }}>
                You can have a max of <b>{DISCOVERY_MAX_COLUMN_CNT}</b> visible columns.
              </span>
            }
          />
        </span>
        <hr />
      </div>
    );
  };

  const renderAddCustomColumnButton = () => {
    return (
      <div
        onClick={(e) => {
          if (e.target.id !== `btn-link-upgrade-${setting.id}`) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <button
          key="btn-discovery-custom-column"
          className="btn bg-transparent border-0 px-0 small text-alert cursor-pointer"
          disabled={!plan.isProPlus}
          onClick={(e) => {
            const customColumnCnt = setting.value.filter((item) => item.custom);
            if (customColumnCnt.length >= DISCOVERY_MAX_CUSTOM_COLUMN_CNT) {
              cogoToast.warn(
                <span>
                  You can have max <b>{DISCOVERY_MAX_CUSTOM_COLUMN_CNT}</b> custom columns.
                </span>
              );
              return;
            }

            setShowCustomColumnPopup(true);
            setCustomColumnPopupEditItem(null);
          }}
        >
          Add Custom
        </button>
        <HelpTooltip
          title={"Custom Column Tooltip"}
          placement={"bottom"}
          content={
            <span style={{ color: "white" }}>
              Configure a custom column to show in Discovery per your need.
              {!plan.isProPlus && (
                <span>
                  {" "}
                  <a
                    id={`btn-link-upgrade-${setting.id}`}
                    title=""
                    className={styles["anchor-btn-upgrade"]}
                    href="/profile/plan"
                  >
                    Upgrade
                  </a>{" "}
                  your plan to enable this feature.
                </span>
              )}
            </span>
          }
        />
      </div>
    );
  };

  const categorizeColumns = () => {
    const activeColumns = setting.value.filter((item) => !item.hidden && isColumnAccessible(item, plan));
    const hiddenColumns = setting.value.filter((item) => item.hidden && isColumnAccessible(item, plan));
    const inactiveColumns = setting.value.filter((item) => !isColumnAccessible(item, plan));
    return [activeColumns, hiddenColumns, inactiveColumns];
  };

  const [activeColumns, hiddenColumns, inactiveColumns] = categorizeColumns();

  return (
    <div className={styles["setting-wrapper"]}>
      <CollapsibleWrapper
        title={setting.title}
        labelClassName="setting-panel-label-1"
        storeKey="discovery"
        storeSubKey={setting.id}
        triggerAnim={discoverySaving}
        dimmed={!(plan.isPro || plan.isProPlus)}
        toolSet={[renderAddCustomColumnButton()]}
      >
        {plan.isPro || plan.isProPlus ? (
          <>
            <div className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2 py-1"}>
              <div className="d-flex align-items-center">
                <i className={`${styles["sort-handler"]} mdi mdi-pin`} style={{ color: "gray" }}></i>
                <span className="small">Symbol</span>
              </div>
            </div>
            <ReactSortable
              animation={200}
              handle={`.${styles["sort-handler"]}`}
              list={activeColumns}
              setList={(newState) => {
                dispatch({
                  type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
                  value: {
                    id: setting.id,
                    columns: [
                      ...newState.filter((item) => item.column !== COLUMN_LIMIT_LINE_ID),
                      ...hiddenColumns,
                      ...inactiveColumns,
                    ],
                  },
                });
                let shouldAnimate = false;
                for (let i = 0; i < activeColumns.length; i++) {
                  if (activeColumns[i].column != newState[i].column) {
                    shouldAnimate = true;
                    break;
                  }
                  if (activeColumns[i].hidden != newState[i].hidden) {
                    shouldAnimate = true;
                    break;
                  }
                }
                if (shouldAnimate) {
                  setDiscoverySaving(false);
                  setTimeout(() => setDiscoverySaving(true), 100);
                }
              }}
            >
              {activeColumns.map((item, index) => renderColumn(item, index))}
            </ReactSortable>
            {renderColumnLimitLine()}
            {hiddenColumns.map((item) => renderColumn(item))}
            {inactiveColumns.map((item) => (
              <div key={`${getColumnUniqueStr(item)}`} className={"mb-1"}>
                <div className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2 py-1"}>
                  <div className="d-flex align-items-center text-symbol">
                    <i className={`${styles["sort-handler"]} mdi mdi-menu`}></i>
                    <span className="small" style={{ whiteSpace: "nowrap" }}>
                      {DISCOVERY_COLUMN_LABELS[item.column]}
                    </span>
                    {item.custom && (
                      <DiscoveryLockedFieldMark timeframe={item.timeframe} disabled={true} palette={"light"} />
                    )}
                  </div>
                  <div className={"d-flex justify-content-end align-items-center flex-grow-1"}>
                    <UpgradeButton label="PRO + ONLY" />
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <UpgradeButton label="PRO ONLY" />
        )}
      </CollapsibleWrapper>
      <CustomColumnPopup
        id={setting.id}
        open={showCustomColumnPopup}
        onSave={onSaveCustomColumn}
        onHide={() => {
          setShowCustomColumnPopup(false);
          setCustomColumnPopupEditItem(null);
        }}
        editItem={customColumnPopupEditItem}
        plan={plan}
      />
    </div>
  );
}

DiscoverySettings.propTypes = {
  setting: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.array,
  }),
};

export default DiscoverySettings;
