import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import cogoToast from "cogo-toast";
import PropTypes from "prop-types";

import StepBar from "./StepBar";
import AlertFieldInput from "./AlertFieldInput";
import TimeframeSelector from "./TimeframeSelector";

import { FILTER_TYPE } from "../../discovery/discoveryReducer";

import { categorizeValidationResult, isBillionColumn, validateField } from "./helper";
import { checkModified } from "../../util";
import {
  ADVANCED_CRITERIA_COLUMNS,
  BASE_CRITERIA_COLUMNS,
  CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA,
  CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER,
  CONDITIONAL_ALERT_STEPS,
  CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED,
  CONDITIONAL_ALERT_STEP_CRITERIA_BASE,
  CONDITIONAL_ALERT_STEP_LABEL,
  CONDITIONAL_ALERT_STEP_RATE,
  CONDITIONAL_ALERT_STEP_TRIGGER,
  LAST_AT_FILTER_DEFAULT_THRESHOLD,
  LAST_AT_FILTER_UNIT,
  TIMEFRAME_FILTER,
  TREND_CHANGE_TYPE,
  TREND_TYPE,
  TRIGGER_COLUMNS,
} from "../../constants";

import API from "../../api";

import styles from "./index.module.scss";

function ConditionalAlertModal(props) {
  const { open, type, editItem, onHide, onAdd, onUpdate } = props;

  const editModeOrigName = useRef("");

  const [notificationName, setNotificationName] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [trigger, setTrigger] = useState([]);
  const [marketCapIncludeNull, setMarketCapIncludeNull] = useState(false);
  const [triggerType, setTriggerType] = useState(FILTER_TYPE.ALL);
  const [rate, setRate] = useState("1day");
  const [step, setStep] = useState(CONDITIONAL_ALERT_STEP_CRITERIA_BASE);

  const validBaseCriteriaSnapshot = useRef(null);
  const [matchingSymbolsCount, setMatchingSymbolsCount] = useState(null);
  const [isValidatingSymbolCount, setIsValidatingSymbolCount] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (open && type) {
      if (type === CONDITIONAL_ALERT_MODAL_TYPE_ADD) {
        setNotificationName("");
        setCriteria(
          [...BASE_CRITERIA_COLUMNS, ""].map((column) => ({
            column,
            min: "",
            max: "",
          }))
        );
        setTrigger([
          {
            column: "",
            min: "",
            max: "",
          },
        ]);
        setMarketCapIncludeNull(true);
        setTriggerType(FILTER_TYPE.ALL);
        setRate("1day");
      } else if (type === CONDITIONAL_ALERT_MODAL_TYPE_UPDATE && editItem) {
        editModeOrigName.current = editItem.name;

        setNotificationName(editItem.name || "");
        if (isNaN(editItem.criteria?.["marketCap"]?.min) || !editItem.criteria?.["marketCap"]?.min) {
          setMarketCapIncludeNull(true);
        } else {
          setMarketCapIncludeNull(!!editItem.marketCapNull);
        }
        if (editItem.triggerType === FILTER_TYPE.ALL || editItem.triggerType === FILTER_TYPE.ANY) {
          setTriggerType(editItem.triggerType);
        } else {
          setTriggerType(FILTER_TYPE.ALL);
        }
        if (TIMEFRAME_FILTER.hasOwnProperty(editItem.timeframe)) {
          setRate(editItem.timeframe);
        } else {
          setRate("1day");
        }

        const editItemCriteria = [];
        const editItemTrigger = [];
        for (const column in editItem.criteria) {
          const { min, max } = editItem.criteria[column];
          const criteriaItem = {
            column,
            min,
            max,
          };
          if (isBillionColumn(column)) {
            if (min) {
              criteriaItem.min = min / 1000000000;
            }
            if (max) {
              criteriaItem.max = max / 1000000000;
            }
          }
          const validation = validateField(criteriaItem, CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA);
          if (validation.valid) {
            editItemCriteria.push(criteriaItem);
          }
        }
        BASE_CRITERIA_COLUMNS.forEach((column) => {
          const columnExist = editItemCriteria.findIndex((item) => item.column === column) > -1;
          if (!columnExist) {
            editItemCriteria.push({
              column,
              min: "",
              max: "",
            });
          }
        });
        for (const column in editItem.trigger) {
          const { min, max } = editItem.trigger[column];
          const triggerItem = {
            column,
            min,
            max,
          };
          if (isBillionColumn(column)) {
            if (min) {
              triggerItem.min = min / 1000000000;
            }
            if (max) {
              triggerItem.max = max / 1000000000;
            }
          }
          const validation = validateField(triggerItem, CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER);
          if (validation.valid) {
            editItemTrigger.push(triggerItem);
          }
        }
        setCriteria(editItemCriteria);
        setTrigger(editItemTrigger);
      }
      setStep(CONDITIONAL_ALERT_STEP_CRITERIA_BASE);
      validBaseCriteriaSnapshot.current = null;
      setMatchingSymbolsCount(null);
      setIsValidatingSymbolCount(false);
      setErrors([]);
    }
  }, [open, type, editItem]);

  useEffect(() => {
    setErrors([]);
  }, [step]);

  useEffect(() => {
    if (validBaseCriteriaSnapshot.current) {
      const baseCriteriaValues = criteria.filter((item) => BASE_CRITERIA_COLUMNS.includes(item.column));
      const validFields = baseCriteriaValues.filter(
        (item) => validateField(item, CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA).valid
      );
      const isModified = checkModified(
        {
          criteria: validFields,
          marketCapNull: marketCapIncludeNull,
        },
        validBaseCriteriaSnapshot.current
      );
      if (isModified) {
        setMatchingSymbolsCount(null);
        validBaseCriteriaSnapshot.current = null;
      }
    }
  }, [criteria, marketCapIncludeNull]);

  const getFieldDefaultValue = (column) => {
    const value = { column };
    if (column === "squeeze") {
      value.min = {
        NOW: false,
        PRE: false,
        POST: false,
      };
      value.max = "";
    } else if (column === "trend") {
      value.min = TREND_TYPE.BUY;
      value.max = "";
    } else if (column === "trend_change") {
      value.min = TREND_CHANGE_TYPE.BUY_TO_SELL;
      value.max = "";
    } else if (column === "news" || column === "halt") {
      value.min = {
        threshold: LAST_AT_FILTER_DEFAULT_THRESHOLD,
        unit: LAST_AT_FILTER_UNIT[1],
      };
    } else {
      value.min = "";
      value.max = "";
    }
    return value;
  };

  const goNextStep = async () => {
    const newErrors = await validateStep();
    if (newErrors.length) {
      setErrors(newErrors);
    } else {
      setErrors([]);
      if (step < CONDITIONAL_ALERT_STEP_RATE) {
        setStep(step + 1);
      } else {
        handeOnSave();
      }
    }
  };

  const handeOnSave = () => {
    const payload = {
      name: notificationName,
      timeframe: rate,
      criteria: {},
      trigger: {},
      marketCapNull: marketCapIncludeNull,
      triggerType,
    };
    for (const { column, min, max } of criteria) {
      payload.criteria[column] = {
        min,
        max,
      };
      if (isBillionColumn(column)) {
        if (min) {
          payload.criteria[column].min = min * 1000000000;
        }
        if (max) {
          payload.criteria[column].max = max * 1000000000;
        }
      }
    }
    for (const { column, min, max } of trigger) {
      payload.trigger[column] = {
        min,
        max,
      };
      if (isBillionColumn(column)) {
        if (min) {
          payload.trigger[column].min = min * 1000000000;
        }
        if (max) {
          payload.trigger[column].max = max * 1000000000;
        }
      }
    }
    if (type === CONDITIONAL_ALERT_MODAL_TYPE_ADD) {
      onAdd && onAdd(payload);
    } else if (type === CONDITIONAL_ALERT_MODAL_TYPE_UPDATE) {
      onUpdate && onUpdate(editModeOrigName.current, payload);
    }
  };

  const validateStep = async () => {
    const newErrors = [];
    if (step === CONDITIONAL_ALERT_STEP_CRITERIA_BASE) {
      // Assume all base criteria fields included in criteria variable
      const values = criteria.filter((item) => BASE_CRITERIA_COLUMNS.includes(item.column));
      const validationResult = categorizeValidationResult(
        values.map((item) => validateField(item, CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA))
      );
      if (validationResult.invalidCnt) {
        if (validationResult.emptyValueCnt === BASE_CRITERIA_COLUMNS.length) {
          newErrors.push("There should be at least one base criteria configured.");
        } else if (validationResult.invalidFormat) {
          newErrors.push("One or more base criterias have invalid values.");
        }
      }
      if (!newErrors.length) {
        if (!matchingSymbolsCount || !validBaseCriteriaSnapshot.current) {
          // Proceed with matching symbols count validation
          const validFields = values.filter((item) => validateField(item, CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA).valid);
          const validationResult = await validateMatchingSymbolCount(validFields);
          if (!validationResult.valid) {
            if (validationResult.values) {
              newErrors.push(
                `${validationResult.values[1]} matching symbols found for your base criteria. Narrow it down to have ${validationResult.values[0]} symbols or below.`
              );
            } else {
              newErrors.push(`Something went wrong while validating the configuration.`);
            }
          } else {
            setMatchingSymbolsCount(validationResult.values[1]);
            validBaseCriteriaSnapshot.current = JSON.parse(
              JSON.stringify({
                criteria: validFields,
                marketCapNull: marketCapIncludeNull,
              })
            );
          }
        }
      }
    } else if (step === CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED) {
      const values = criteria.filter((item) => ADVANCED_CRITERIA_COLUMNS.includes(item.column) || !item.column);
      const validationResult = categorizeValidationResult(
        values.map((item) => validateField(item, CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA))
      );
      if (validationResult.invalidCnt) {
        if (validationResult.emptyColumnCnt) {
          newErrors.push("One or more advanced criterias have empty field.");
        } else if (validationResult.emptyValueCnt) {
          newErrors.push("One or more advanced criterias have empty values.");
        } else if (validationResult.invalidFormat) {
          newErrors.push("One or more advanced criterias have invalid values.");
        }
      }
    } else if (step === CONDITIONAL_ALERT_STEP_TRIGGER) {
      const values = trigger.filter((item) => TRIGGER_COLUMNS.includes(item.column) || !item.column);
      if (!values.length) {
        newErrors.push("There should be at least one trigger configured.");
      } else {
        const validationResult = categorizeValidationResult(
          values.map((item) => validateField(item, CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER))
        );
        if (validationResult.invalidCnt) {
          if (validationResult.emptyColumnCnt) {
            newErrors.push("One or more advanced triggers have empty field.");
          } else if (validationResult.emptyValueCnt) {
            newErrors.push("One or more advanced triggers have empty values.");
          } else if (validationResult.invalidFormat) {
            newErrors.push("One or more advanced triggers have invalid values.");
          }
        }
      }
    } else if (step === CONDITIONAL_ALERT_STEP_RATE) {
      if (!triggerType) {
        newErrors.push("Please select a timeframe as rate of change.");
      } else if (!notificationName) {
        newErrors.push("Notification name can't be empty.");
      }
    }
    return newErrors;
  };

  const validateMatchingSymbolCount = async (fields) => {
    setIsValidatingSymbolCount(true);
    let result;
    try {
      const payload = {
        criteria: {},
        marketCapNull: marketCapIncludeNull,
      };
      for (const item of fields) {
        payload.criteria[item.column] = {
          min: item.min,
          max: item.max,
        };
        if (isBillionColumn(item.column)) {
          if (item.min) {
            payload.criteria[item.column].min = item.min * 1000000000;
          }
          if (item.max) {
            payload.criteria[item.column].max = item.max * 1000000000;
          }
        }
      }
      result = await API.validateFilteredAlert(payload);
      if (!result || !result.success) {
        throw new Error();
      }
    } catch (e) {
      cogoToast.error(`Failed to validate alert configuration!`);
    }
    setIsValidatingSymbolCount(false);
    return result;
  };

  const adjustFieldValue = (column, value) => {
    let result = value;
    if (column === "vWapSlope") {
      result = Math.min(result, 10);
      result = Math.max(result, -10);
    }
    return result;
  };

  const renderStepContent = () => {
    if (step === CONDITIONAL_ALERT_STEP_CRITERIA_BASE) {
      return (
        <>
          {BASE_CRITERIA_COLUMNS.map((column) => {
            const value = criteria.find((item) => item.column === column);
            if (!value) return null;
            return (
              <div key={`criteria-${column}`}>
                <AlertFieldInput
                  type={CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA}
                  value={value}
                  columnList={[]}
                  updateFieldValue={(type, fieldValue) => {
                    setCriteria(
                      criteria.map((item) => {
                        if (item.column !== column) return item;
                        return {
                          ...item,
                          [type]: fieldValue,
                        };
                      })
                    );
                  }}
                />
                {column === "marketCap" && value.min > 0 && (
                  <div className={`d-flex flex-row align-items-top ${styles["second-level-input"]}`}>
                    <div
                      className={marketCapIncludeNull ? "industry-checked" : "industry-unchecked"}
                      style={{ marginTop: "2px" }}
                      onClick={() => setMarketCapIncludeNull(!marketCapIncludeNull)}
                    />
                    <span className="small industry-txt">
                      Include if null (will append ETF's and other symbols without market cap)
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </>
      );
    } else if (step === CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED) {
      const existingColumns = criteria
        .filter((item) => ADVANCED_CRITERIA_COLUMNS.includes(item.column) || item.column === "")
        .map((item) => item.column);
      const availableColumns = ADVANCED_CRITERIA_COLUMNS.filter((column) => !existingColumns.includes(column));
      return (
        <>
          {existingColumns.map((column) => {
            const value = criteria.find((item) => item.column === column);
            return (
              <AlertFieldInput
                key={`criteria-${column}`}
                type={CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA}
                value={value}
                columnSelectable={true}
                columnList={availableColumns}
                removable={true}
                updateFieldColumn={(newColumn) => {
                  setCriteria(
                    criteria.map((item) => {
                      if (item.column !== column) {
                        return item;
                      }
                      return getFieldDefaultValue(newColumn);
                    })
                  );
                }}
                updateFieldValue={(type, fieldValue) => {
                  setCriteria(
                    criteria.map((item) => {
                      if (item.column !== column) return item;
                      return {
                        ...item,
                        [type]: adjustFieldValue(column, fieldValue),
                      };
                    })
                  );
                }}
                deleteFilterItem={(column) => {
                  setCriteria(criteria.filter((item) => item.column !== column));
                }}
              />
            );
          })}
        </>
      );
    } else if (step === CONDITIONAL_ALERT_STEP_TRIGGER) {
      const existingColumns = trigger
        .filter((item) => TRIGGER_COLUMNS.includes(item.column) || item.column === "")
        .map((item) => item.column);
      const availableColumns = TRIGGER_COLUMNS.filter((column) => !existingColumns.includes(column));
      return (
        <>
          {existingColumns.map((column) => {
            const value = trigger.find((item) => item.column === column);
            return (
              <AlertFieldInput
                key={`trigger-${column}`}
                type={CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER}
                value={value}
                columnSelectable={true}
                columnList={availableColumns}
                removable={true}
                updateFieldColumn={(newColumn) => {
                  setTrigger(
                    trigger.map((item) => {
                      if (item.column !== column) {
                        return item;
                      }
                      return getFieldDefaultValue(newColumn);
                    })
                  );
                }}
                updateFieldValue={(type, fieldValue) => {
                  setTrigger(
                    trigger.map((item) => {
                      if (item.column !== column) return item;
                      return {
                        ...item,
                        [type]: fieldValue,
                      };
                    })
                  );
                }}
                deleteFilterItem={(column) => {
                  setTrigger(trigger.filter((item) => item.column !== column));
                }}
              />
            );
          })}
        </>
      );
    } else if (step === CONDITIONAL_ALERT_STEP_RATE) {
      return (
        <>
          <TimeframeSelector value={rate} onChangeRate={(value) => setRate(value)} />
        </>
      );
    } else {
      return null;
    }
  };

  const renderAddRow = () => {
    if (step === CONDITIONAL_ALERT_STEP_CRITERIA_BASE || step === CONDITIONAL_ALERT_STEP_RATE) {
      return null;
    }
    return (
      <div className="d-flex align-items-center justify-content-end mt-2">
        <div
          role="button"
          onClick={() => {
            let arr;
            let updateMethod;
            if (step === CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED) {
              arr = criteria;
              updateMethod = setCriteria;
            } else if (step === CONDITIONAL_ALERT_STEP_TRIGGER) {
              arr = trigger;
              updateMethod = setTrigger;
            }
            if (arr && updateMethod) {
              const hasEmptyRow = !!arr.find((item) => item.column === "");
              if (!hasEmptyRow) {
                updateMethod([
                  ...arr,
                  {
                    column: "",
                    min: "",
                    max: "",
                  },
                ]);
              }
            }
          }}
        >
          <i className="mdi mdi-plus" />
          Add row
        </div>
      </div>
    );
  };

  const renderTriggerType = () => {
    if (step === CONDITIONAL_ALERT_STEP_TRIGGER) {
      return (
        <Form.Group className="d-flex align-items-center justify-content-end mb-0" style={{ color: "white" }}>
          <Form.Check
            className="d-flex white"
            inline
            label={FILTER_TYPE.ALL}
            type="radio"
            id={`inline-radio-1`}
            name="type"
            checked={triggerType === FILTER_TYPE.ALL}
            onChange={(e) => {
              if (e.target.checked) {
                setTriggerType(FILTER_TYPE.ALL);
              }
            }}
          />
          <Form.Check
            className="d-flex"
            inline
            label={FILTER_TYPE.ANY}
            type="radio"
            id={`inline-radio-2`}
            name="type"
            checked={triggerType === FILTER_TYPE.ANY}
            onChange={(e) => {
              if (e.target.checked) {
                setTriggerType(FILTER_TYPE.ANY);
              }
            }}
          />
          <span style={{ height: 20 }}>triggers are met</span>
        </Form.Group>
      );
    } else {
      return null;
    }
  };

  const renderErrors = () => {
    if (!errors?.length) {
      return null;
    }
    return (
      <div className="mt-3 mb-1">
        {errors.map((error, index) => {
          return (
            <div key={`filtered-alert-error-${index}`} className={styles["alert-error"]}>
              {error}
            </div>
          );
        })}
      </div>
    );
  };

  const renderMatchingSymbolsCount = () => {
    if (!matchingSymbolsCount) {
      return null;
    }
    return <span className={styles["alert-success"]}>Matching symbols: {matchingSymbolsCount}</span>;
  };

  return (
    <Modal
      id="setting-filtered-alert-modal"
      className={"modal-drak-overlay"}
      show={open && !!type}
      onHide={() => {
        onHide && onHide(false);
      }}
      aria-labelledby="Conditional Alert Modal"
      backdrop={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Enter notification name"
            style={{ width: 200, color: "white" }}
            value={notificationName}
            onChange={(e) => {
              e.preventDefault();
              setNotificationName(e.target.value);
            }}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="filtered-alerts">
        <StepBar steps={CONDITIONAL_ALERT_STEPS} current={step} goToStep={(stepId) => setStep(stepId)} />
        <div className={styles["step-title"]}>
          <span>{CONDITIONAL_ALERT_STEP_LABEL[step]}</span>
        </div>
        {renderStepContent()}
        {renderAddRow()}
        {renderTriggerType()}
        {renderErrors()}
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <div>{renderMatchingSymbolsCount()}</div>
        <div>
          <Button
            variant="primary"
            className="mr-2"
            disabled={step === CONDITIONAL_ALERT_STEP_RATE || isValidatingSymbolCount}
            onClick={() => goNextStep()}
          >
            {isValidatingSymbolCount ? <i className="fa fa-circle-o-notch fa-spin mx-1"></i> : "Next"}
          </Button>
          <Button
            variant="success"
            onClick={() => goNextStep()}
            disabled={step !== CONDITIONAL_ALERT_STEP_RATE || isValidatingSymbolCount}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export const CONDITIONAL_ALERT_MODAL_TYPE_ADD = "add";
export const CONDITIONAL_ALERT_MODAL_TYPE_UPDATE = "update";

ConditionalAlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([CONDITIONAL_ALERT_MODAL_TYPE_ADD, CONDITIONAL_ALERT_MODAL_TYPE_UPDATE]).isRequired,
  editItem: PropTypes.any,
  onHide: PropTypes.func,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
};

ConditionalAlertModal.defaultProps = {
  open: false,
};

export default ConditionalAlertModal;
