import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "react-tooltip";

import DiscPresets from "./DiscPresets";
import ColumnDropdown from "./ColumnDropdown";
import ShareModal from "./ShareModal";
import ColorPalette from "../../shared/ColorPalette";
import ModalFormRowDesc from "../../shared/ModalFormRowDesc";

import { DiscoveryActions } from "../../store";
import { FILTER_TYPE } from "../discoveryReducer";
import { getDeviceActiveLayoutConfig } from "../../dashboard/dashboardReducer";

import { DISCOVERY_COLUMN_RELATED_FILTER_FIELDS } from "../DiscoveryUtils";
import {
  decodeDiscoveryFieldForTimeframe,
  encodeDiscoveryFieldForTimeframe,
  isColumnAccessible,
  getInaccessibleFieldPlanName,
  isFieldAccessible,
} from "../../util";
import { APP_COLORS, LAST_AT_FILTER_DEFAULT_THRESHOLD, LAST_AT_FILTER_UNIT, TREND_TYPE } from "../../constants";

import styles from "./style.module.scss";

class SmartFiltersModal extends React.Component {
  baseColumns = [
    "atr",
    "avgVolume",
    "float",
    "gap",
    "halt",
    "last",
    "price_dist",
    "marketCap",
    "momentum",
    "mf_price",
    "mf_dist",
    "news",
    "rs",
    "short_ratio",
    "squeeze",
    "tradeCount",
    "tradeCount_dist",
    "trend",
    "uVol",
    "vWapSlope",
    "rv_price",
    "rv_dist",
    "vWapDist",
  ];

  defaultState = {
    mode: "add",
    name: "High Volume",
    type: FILTER_TYPE.ALL,
    color: "",
    filters: [{ column: "", min: "", max: "" }],
    discPreset: {
      sort: {
        enabled: true,
        sortBy: "",
        order: "desc",
      },
      timeframe: {
        enabled: true,
        value: "1day",
      },
      sector: {
        enabled: true,
        value: [],
      },
    },
    columns: [],
    sharedFilterLoaded: false,
    shareData: null,
  };
  state = this.defaultState;
  // Testing
  // state = {
  //   mode: "edit",
  //   name: "volatile volume",
  //   type: "ALL",
  //   color: "",
  //   filters: [
  //     { column: "rv_price", max: "", min: 2000 },
  //     { column: "tradeCount", max: 13, min: 10 },
  //   ],
  //   discPreset: {
  //     sort: {
  //       enabled: true,
  //       sortBy: "",
  //       order: "desc",
  //     },
  //     timeframe: {
  //       enabled: true,
  //       value: "1day",
  //     },
  //     sector: {
  //       enabled: true,
  //       value: [],
  //     },
  //   },
  // };

  updatingFilterName = "";

  constructor(props) {
    super(props);
    props.forwardRef.current = this;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.config !== prevProps.config ||
      this.props.isProPlus !== prevProps.isProPlus ||
      !this.state.columns.length
    ) {
      const { config: discoveryConfig, isPro, isProOld, isProPlus } = this.props;
      const plan = { isPro, isProOld, isProPlus };
      const customColumns = discoveryConfig
        .filter((column) => column.custom && isColumnAccessible(column, plan))
        .reduce((acc, column) => {
          return [
            ...acc,
            ...DISCOVERY_COLUMN_RELATED_FILTER_FIELDS[column.column].map((field) =>
              encodeDiscoveryFieldForTimeframe(field, column.timeframe)
            ),
          ];
        }, []);
      const columns = this.baseColumns.concat(customColumns).sort((a, b) => {
        return this.getColumnTitle(a).localeCompare(this.getColumnTitle(b));
      });
      this.setState({
        columns: columns,
      });
    }

    if (this.state.sharedFilterLoaded !== prevState.sharedFilterLoaded && this.state.sharedFilterLoaded) {
      this.props.setOpenModal(true);
    }
    if (this.props.open !== prevProps.open && !this.props.open) {
      this.setState({
        ...this.defaultState,
        columns: this.state.columns,
      });
    }

    //Load click loaded filter
    const activeLayoutConfig = getDeviceActiveLayoutConfig(this.props.layout);
    if (
      activeLayoutConfig.menu.discovery1 !== activeLayoutConfig.menu.discovery2 ||
      this.props.widget === "discovery1"
    ) {
      if (this.props.loadedFilterShare) {
        this.setState({
          ...this.props.loadedFilterShare,
          mode: "add",
          sharedFilterLoaded: true,
        });
        this.props.setLoadedFilterShare(null);
      }
    }
  }

  getColumnTitle = (column) => {
    const { column: col } = decodeDiscoveryFieldForTimeframe(column);
    if (col === "avgVolume") {
      return "Avg Volume";
    }
    if (col === "float") {
      return "Float";
    }
    if (col === "last") {
      return "Last";
    }
    if (col === "marketCap") {
      return "Market Cap";
    }
    if (col === "momentum") {
      return "Momentum";
    }
    if (col === "mf_price") {
      return "Money Flow $";
    }
    if (col === "mf_dist") {
      return "Money Flow %";
    }
    if (col === "price_dist") {
      return "Last %";
    }
    if (col === "squeeze") {
      return "Squeeze";
    }
    if (col === "tradeCount") {
      return "Trades";
    }
    if (col === "tradeCount_dist") {
      return "Trades %";
    }
    if (col === "trend") {
      return "Trend";
    }
    if (col === "uVol") {
      return "UVol";
    }
    if (col === "vWapDist") {
      return "VWAP Dist";
    }
    if (col === "vWapSlope") {
      return "Vector";
    }
    if (col === "rs") {
      return "RelStr %";
    }
    if (col === "rv_price") {
      return "Volume";
    }
    if (col === "rv_dist") {
      return "Volume %";
    }
    if (col === "short_ratio") {
      return "Short Ratio";
    }
    if (col === "atr") {
      return "ATR %";
    }
    if (col === "gap") {
      return "Gap %";
    }
    if (col === "news") {
      return "News";
    }
    if (col === "halt") {
      return "Halt";
    }
    return col;
  };

  addRow = () => {
    if (this.state.filters.length < this.state.columns.length) {
      const filters = [...this.state.filters];
      filters.push({
        column: "",
        min: "",
        max: "",
      });
      this.setState({
        filters,
      });
    }
  };

  updateFilterColumn = (index, c) => {
    const filters = [...this.state.filters];
    filters[index].column = c;
    const columnInfo = decodeDiscoveryFieldForTimeframe(c);
    if (columnInfo.column === "squeeze") {
      filters[index].min = {
        NOW: false,
        PRE: false,
        POST: false,
      };
      filters[index].max = "";
    } else if (columnInfo.column === "trend") {
      filters[index].min = TREND_TYPE.BUY;
      filters[index].max = "";
    } else if (columnInfo.column === "news" || columnInfo.column === "halt") {
      filters[index].min = {
        threshold: LAST_AT_FILTER_DEFAULT_THRESHOLD,
        unit: LAST_AT_FILTER_UNIT[1],
      };
    } else {
      filters[index].min = "";
      filters[index].max = "";
    }
    this.setState({
      filters,
    });
  };

  updateFilterValue = (index, type, value) => {
    const filters = [...this.state.filters];
    filters[index][type] = value;
    this.setState({
      filters,
    });
  };

  updateFilterType = (type) => {
    this.setState({
      type,
    });
  };

  deleteFilterRow = (index) => {
    const filters = [...this.state.filters];
    filters.splice(index, 1);
    this.setState({
      filters,
    });
  };

  clear = () => {
    this.setState({
      name: "",
      type: FILTER_TYPE.ALL,
      color: "",
      filters: [{ column: "", min: "", max: "" }],
    });
  };

  initForAdd = () => {
    this.setState({
      mode: "add",
      name: "",
      type: FILTER_TYPE.ALL,
      color: "",
      filters: [{ column: "", min: "", max: "" }],
    });
  };

  initForUpdate = (name, filter) => {
    const values = [];
    for (let key in filter.values) {
      if (this.state.columns.includes(key)) {
        let { min, max } = filter.values[key];
        const columnInfo = decodeDiscoveryFieldForTimeframe(key);
        if (columnInfo.column === "squeeze") {
          if (Object.prototype.toString.call(min) !== "[object Object]") {
            min = {};
          } else {
            if (min["PRE"] === true) min["PRE"] = 7;
            if (min["POST"] === true) min["POST"] = -2;
          }
          max = null;
        } else if (columnInfo.column === "trend") {
          if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
            min = TREND_TYPE.BUY;
          }
          max = "";
        } else if (columnInfo.column === "news" || columnInfo.column === "halt") {
          if (Object.prototype.toString.call(min) !== "[object Object]") {
            min = {};
          } else {
            if (isNaN(min["threshold"])) min["threshold"] = LAST_AT_FILTER_DEFAULT_THRESHOLD;
            if (!LAST_AT_FILTER_UNIT.includes(min["unit"])) min["unit"] = LAST_AT_FILTER_UNIT[1];
          }
          max = null;
        } else {
          if (isNaN(min)) min = "";
          else if (!min && min !== 0 && min !== "0") min = "";
          if (isNaN(max)) max = "";
          else if (!max && max !== 0 && max !== "0") max = "";
        }
        if (this.isBillionColumn(key)) {
          if (min) {
            min = min / 1000000000;
          }
          if (max) {
            max = max / 1000000000;
          }
        }
        values.push({
          column: key,
          min: min,
          max: max,
        });
      }
    }

    const discPreset = {
      sort: {
        enabled: false,
        column: "",
        order: "desc",
      },
      timeframe: {
        enabled: false,
        value: "1day",
      },
      sector: {
        enabled: false,
        value: [],
      },
      ...filter.discPreset,
    };

    this.updatingFilterName = name;
    this.setState({
      mode: "edit",
      name,
      type: filter.type,
      color: filter.color || "",
      filters: [...values],
      discPreset,
    });
  };

  updateFilterName = (name) => {
    this.setState({
      name,
    });
  };

  handleFilter = () => {
    this.props.onFilter({
      name: this.state.name,
      type: this.state.type,
      color: this.state.color,
      values: this.state.filters.reduce((acc, curr) => {
        const columnInfo = decodeDiscoveryFieldForTimeframe(curr.column);
        acc[curr.column] = {
          min: ["squeeze", "trend", "news", "halt"].includes(columnInfo.column) ? curr.min : parseFloat(curr.min),
          max: parseFloat(curr.max),
        };
        if (this.isBillionColumn(curr.column)) {
          if (curr.min) {
            acc[curr.column].min = curr.min * 1000000000;
          }
          if (curr.max) {
            acc[curr.column].max = curr.max * 1000000000;
          }
        }
        return acc;
      }, {}),
      discPreset: this.state.discPreset,
    });
  };

  handleUpdate = () => {
    this.props.onUpdate(this.updatingFilterName, {
      name: this.state.name,
      type: this.state.type,
      color: this.state.color,
      values: this.state.filters.reduce((acc, curr) => {
        const columnInfo = decodeDiscoveryFieldForTimeframe(curr.column);
        acc[curr.column] = {
          min: ["squeeze", "trend", "news", "halt"].includes(columnInfo.column) ? curr.min : parseFloat(curr.min),
          max: parseFloat(curr.max),
        };
        if (this.isBillionColumn(curr.column)) {
          if (curr.min) {
            acc[curr.column].min = curr.min * 1000000000;
          }
          if (curr.max) {
            acc[curr.column].max = curr.max * 1000000000;
          }
        }
        return acc;
      }, {}),
      discPreset: this.state.discPreset,
    });
  };

  isMoneyColumn = (column) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(column);
    return ["last", "mf_price", "marketCap"].includes(columnInfo.column);
  };

  isPercentColumn = (column) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(column);
    return ["price_dist", "uVol", "vWapDist", "mf_dist", "rv_dist", "atr", "gap", "rs", "tradeCount_dist"].includes(
      columnInfo.column
    );
  };

  isBillionColumn = (column) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(column);
    return columnInfo.column === "marketCap";
  };

  isFormValid = (params) => {
    const { requiresName } = params || {};
    const { name, filters } = this.state;

    if (requiresName && !name) {
      return false;
    }

    const validFilters = filters.filter((item) => {
      if (!item.column) return false;
      const columnInfo = decodeDiscoveryFieldForTimeframe(item.column);
      if (columnInfo.column === "squeeze") {
        if (!item.min || Object.keys(item.min).length === 0) return false;
        const checkedCnt = Object.keys(item.min).filter((key) => item.min[key]).length;
        if (checkedCnt === 0) return false;
      } else if (columnInfo.column === "trend") {
        if (item.min !== TREND_TYPE.BUY && item.min !== TREND_TYPE.SELL) return false;
      } else if (columnInfo.column === "news" || columnInfo.column === "halt") {
        if (isNaN(item.min?.threshold) || item.min?.threshold <= 0 || !LAST_AT_FILTER_UNIT.includes(item.min?.unit))
          return false;
      } else {
        if (!item.min && item.min !== 0 && !item.max && item.max !== 0) return false;
      }
      return true;
    });
    if (validFilters.length < filters.length) {
      return false;
    }

    return true;
  };

  getHiddenFields = () => {
    const { config: discoveryConfig } = this.props;
    return discoveryConfig.reduce((acc, item) => {
      if (item.hidden) {
        acc.push(
          ...DISCOVERY_COLUMN_RELATED_FILTER_FIELDS[item.column].map((field) =>
            encodeDiscoveryFieldForTimeframe(field, item.timeframe)
          )
        );
      }
      return acc;
    }, []);
  };

  adjustFieldValue = (column, value) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(column);
    let result = value;
    if (columnInfo.column === "vWapSlope") {
      result = Math.min(result, 10);
      result = Math.max(result, -10);
    }
    return result;
  };

  updateDiscPreset = (newDiscPreset) => {
    this.setState({ discPreset: newDiscPreset });
  };

  renderMinMaxFilterItem = (index, filter) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMin">
          Min
        </Form.Label>
        {/* <Form.Control
        size="sm"
        type="number"
        placeholder="min"
        id="inlineMin"
        className="ml-1 input-number-hide-spinner"
        value={filter.min}
        style={{color: 'white'}}
        onChange={(e) => {
          e.preventDefault();
          this.updateFilterValue(index, "min", e.target.value);
        }}
      /> */}
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="min"
          prefix={this.isMoneyColumn(filter.column) ? "$" : ""}
          suffix={this.isPercentColumn(filter.column) ? "  %" : this.isBillionColumn(filter.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={filter.min}
          onValueChange={(values) => {
            this.updateFilterValue(index, "min", this.adjustFieldValue(filter.column, values.floatValue));
          }}
        />
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMax">
          Max
        </Form.Label>
        {/* <Form.Control
        size="sm"
        type="number"
        placeholder="max"
        className="ml-1 input-number-hide-spinner"
        style={{color: 'white'}}
        value={filter.max}
        onChange={(e) => {
          e.preventDefault();
          this.updateFilterValue(index, "max", e.target.value);
        }}
      /> */}
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="max"
          prefix={this.isMoneyColumn(filter.column) ? "$" : ""}
          suffix={this.isPercentColumn(filter.column) ? "  %" : this.isBillionColumn(filter.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={filter.max}
          onValueChange={(values) => {
            this.updateFilterValue(index, "max", this.adjustFieldValue(filter.column, values.floatValue));
          }}
        />
      </div>
    );
  };

  renderSqueezeFilterItem = (index, filter) => {
    const values = ["PRE", "NOW", "POST"];
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {values.map((item, id) => (
          <div
            key={`filter-squeeze-${id}`}
            className={`input-group-checkbox`}
            onClick={() => {
              const min = filter.min;
              if (!min[item]) {
                if (item === "PRE") {
                  if (min["POST"]) return;
                  else {
                    min[item] = 7;
                  }
                } else if (item === "POST") {
                  if (min["PRE"]) return;
                  else {
                    min[item] = -2;
                  }
                } else {
                  if (min["PRE"] && min["POST"]) return;
                  else min[item] = true;
                }
              } else {
                min[item] = false;
              }
              this.updateFilterValue(index, "min", min);
            }}
          >
            <span className={`chk-box ${filter.min[item] ? "checked" : ""}`}></span>
            <span className="chk-label">{item}</span>
            {item !== "NOW" && (
              <NumberFormat
                className="form-control form-control-sm px-1 ml-1 text-right"
                inputMode="numeric"
                placeholder=""
                style={{ color: "white", maxWidth: "40px", height: "24px" }}
                disabled={!filter.min[item]}
                value={filter.min[item] || ""}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onValueChange={(values) => {
                  const min = filter.min;
                  min[item] = values.value;
                  if (item === "PRE") {
                    if (min[item] !== "" && min[item] < 1) {
                      min[item] = 1;
                    }
                  }
                  if (item === "POST") {
                    if (min[item] !== "") {
                      if (min[item] < -5) {
                        min[item] = -5;
                      } else if (min[item] > -1) {
                        min[item] = -1;
                      }
                    }
                  }
                  this.updateFilterValue(index, "min", min);
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  renderTrendFilterItem = (index, filter) => {
    const trendOptions = [TREND_TYPE.BUY, TREND_TYPE.SELL];
    return (
      <div className="d-flex justify-content-end align-items-center flex-grow-1">
        <Dropdown varaint="btn btn-outline-secondary" className="mx-2" style={{ borderBottom: "1px solid white" }}>
          <Dropdown.Toggle className="industry_input d-flex align-items-center px-1">
            <div className="d-flex justify-content-start align-items-center">
              <div className={`trend-wrapper ${filter.min === TREND_TYPE.BUY ? "trending mb-1" : "mb-1"} mr-2`}>
                <div className="triangle"></div>
              </div>
              <span style={{ textTransform: "capitalize" }}>{filter.min}</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {trendOptions.map((option) => {
              return (
                <Dropdown.Item
                  key={`discovery-filter-trend-${option}`}
                  onClick={() => {
                    this.updateFilterValue(index, "min", option);
                  }}
                  tabIndex="1"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className={`trend-wrapper ${option === TREND_TYPE.BUY ? "trending mb-1" : ""} mr-2`}>
                      <div className="triangle"></div>
                    </div>
                    <span style={{ textTransform: "capitalize" }}>{option}</span>
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderLastAtFilterItem = (index, filter) => {
    const { threshold, unit } = filter.min || {};
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {/* <div className={`input-group-checkbox`} onClick={() => {
        }}>
          <span className={`chk-box checked`}></span>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <NumberFormat
            className="form-control form-control-sm px-1 ml-1 text-right"
            inputMode="numeric"
            placeholder=""
            style={{ color: "white", maxWidth: "40px", marginTop: "2px" }}
            value={threshold}
            onValueChange={(values) => {
              this.updateFilterValue(index, "min", {
                threshold: values.value,
                unit,
              });
            }}
          />
          <Dropdown varaint="btn btn-outline-secondary" className="ml-2" style={{ borderBottom: "1px solid white" }}>
            <Dropdown.Toggle className="industry_input px-1">
              <span className="" style={{}}>
                {unit || "unit"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {LAST_AT_FILTER_UNIT.map((key) => {
                return (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      this.updateFilterValue(index, "min", {
                        threshold,
                        unit: key,
                      });
                    }}
                    tabIndex="1"
                  >
                    <span
                      style={{
                        color: `${key === unit ? "#f6c548" : ""}`,
                      }}
                    >
                      {key}
                    </span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    const { setOpenModal, open } = this.props;
    const shareData = this.state.shareData;
    const modalOpen = open && !shareData;
    return (
      <>
        <Modal
          id="discovery-smart-filter"
          className={"modal-drak-overlay"}
          show={modalOpen}
          onHide={() => {
            setOpenModal(false);
          }}
          aria-labelledby="Discovery Filter Modal"
          backdrop={false}
          centered
        >
          <Tooltip id="filter-type-tooltip" place="top-start" className="app-ui-hover-tooltip" clickable />
          {this.state.sharedFilterLoaded && (
            <Modal.Header closeButton>
              <Modal.Title className="mb-2">Load Filter</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Header closeButton={!this.state.sharedFilterLoaded}>
            <Modal.Title className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
              <Form.Control
                className="mr-0 mr-md-2 mb-2 mb-md-0"
                size="sm"
                type="text"
                placeholder="Enter filter name"
                style={{ width: 200, color: "white" }}
                value={this.state.name}
                onChange={(e) => {
                  e.preventDefault();
                  this.updateFilterName(e.target.value);
                }}
              />
              <ColorPalette activeColor={this.state.color} onChangeColor={(color) => this.setState({ color })} />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="discovery-filters">
            {this.state.filters.map((filter, index) => {
              const columnInfo = decodeDiscoveryFieldForTimeframe(filter.column);
              return (
                <div key={`filter-${index}-${filter.column}`}>
                  <Form.Group className="d-flex align-items-center mb-0">
                    <ColumnDropdown
                      selectedColumn={filter.column}
                      columns={this.state.columns.filter((c) => !this.state.filters.map((f) => f.column).includes(c))}
                      disabledColumns={Object.fromEntries(
                        this.baseColumns
                          .filter(
                            (c) =>
                              !isFieldAccessible(c, {
                                isPro: this.props.isPro,
                                isProOld: this.props.isProOld,
                                isProPlus: this.props.isProPlus,
                              })
                          )
                          .map((c) => [c, getInaccessibleFieldPlanName(c)])
                      )}
                      onItemClick={(column) => this.updateFilterColumn(index, column)}
                      columnTitles={this.baseColumns.reduce((acc, c) => ({ ...acc, [c]: this.getColumnTitle(c) }), {})}
                      inactiveColumns={this.getHiddenFields()}
                      inactiveTooltipContent={
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            const path = "/settings/discovery";
                            this.props.history.push(path);
                          }}
                        >
                          <span>{"Column is disabled, but can be used for filtering. "}</span>
                          <span style={{ textDecoration: "underline" }}>Enable</span>
                        </div>
                      }
                      allowInactiveSelection
                    />

                    <div className="d-flex align-items-center justify-content-between flex-grow-1">
                      {columnInfo.column === "squeeze" && this.renderSqueezeFilterItem(index, filter)}
                      {columnInfo.column === "trend" && this.renderTrendFilterItem(index, filter)}
                      {(columnInfo.column === "news" || columnInfo.column === "halt") &&
                        this.renderLastAtFilterItem(index, filter)}
                      {!["squeeze", "trend", "news", "halt"].includes(columnInfo.column) &&
                        this.renderMinMaxFilterItem(index, filter)}
                      <i
                        className="mdi mdi-trash-can pl-2 pr-0 pl-sm-3 pr-sm-3 py-2"
                        role="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.deleteFilterRow(index);
                        }}
                      />
                    </div>
                  </Form.Group>
                  {columnInfo.column === "marketCap" && filter?.min > 0 && (
                    <ModalFormRowDesc content="Any ETF's, ETN's, and Indices will be removed as they don't have market cap value." />
                  )}
                  {columnInfo.column === "vWapSlope" && <ModalFormRowDesc content="Vector value range:  -10 to 10" />}
                </div>
              );
            })}

            {this.state.columns.length > this.state.filters.length ? (
              <div
                className="d-flex align-items-center justify-content-start ml-3 mt-2"
                style={{ color: APP_COLORS.active }}
              >
                <div
                  role="button"
                  onClick={() => {
                    this.addRow();
                  }}
                >
                  <i className="mdi mdi-plus mt-3" />
                  Add row
                </div>
              </div>
            ) : null}

            <Form.Group className="d-flex align-items-center justify-content-end" style={{ color: "white" }}>
              <div
                data-tooltip-id="filter-type-tooltip"
                data-tooltip-content="Include results only if all criteria are met (default)"
                className={styles.conditionRadioCont}
              >
                <Form.Check
                  className="d-flex white"
                  inline
                  label={FILTER_TYPE.ALL}
                  type="radio"
                  id={`inline-radio-1`}
                  name="type"
                  checked={this.state.type === FILTER_TYPE.ALL}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.updateFilterType(FILTER_TYPE.ALL);
                    }
                  }}
                />
              </div>
              <div
                data-tooltip-id="filter-type-tooltip"
                data-tooltip-content="Include results if at least one of the criteria is met"
                className={styles.conditionRadioCont}
              >
                <Form.Check
                  className="d-flex"
                  inline
                  label={FILTER_TYPE.ANY}
                  type="radio"
                  id={`inline-radio-2`}
                  name="type"
                  checked={this.state.type === FILTER_TYPE.ANY}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.updateFilterType(FILTER_TYPE.ANY);
                    }
                  }}
                />
              </div>

              <span style={{ height: 20 }}>conditions met</span>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-end" style={{ color: APP_COLORS.active }}>
              {!this.state.sharedFilterLoaded && (
                <div
                  role="button"
                  onClick={() => {
                    const shareData = {
                      shareData: {
                        filters: this.state.filters,
                        name: this.state.name,
                        color: this.state.color,
                        type: this.state.type,
                        discPreset: this.state.discPreset,
                      },
                    };
                    this.setState(shareData);
                  }}
                >
                  <i className="mdi mdi-share-variant mr-2 ml-2" />
                  <span>Share</span>
                </div>
              )}
            </div>

            <DiscPresets
              widget={this.props.widget}
              columns={this.state.columns}
              getColumnTitle={this.getColumnTitle}
              discPreset={this.state.discPreset}
              updateDiscPreset={this.updateDiscPreset}
              disabledColumns={Object.fromEntries(
                this.baseColumns
                  .filter(
                    (c) =>
                      !isFieldAccessible(c, {
                        isPro: this.props.isPro,
                        isProOld: this.props.isProOld,
                        isProPlus: this.props.isProPlus,
                      })
                  )
                  .map((c) => [c, getInaccessibleFieldPlanName(c)])
              )}
            />
          </Modal.Body>

          <Modal.Footer className="justify-content-between">
            <Button variant="secondary" onClick={() => this.clear()}>
              Clear
            </Button>
            {this.state.mode === "add" ? (
              <div>
                <Button
                  variant="success"
                  className="mr-2"
                  onClick={() => {
                    this.handleFilter();
                  }}
                  disabled={!this.isFormValid()}
                >
                  Filter
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.handleFilter();
                  }}
                  disabled={!this.isFormValid({ requiresName: true })}
                >
                  Save & Filter
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.handleUpdate();
                  }}
                  disabled={!this.isFormValid({ requiresName: true })}
                >
                  Update
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        <ShareModal
          close={() => {
            this.setState({ shareData: null });
          }}
          open={!!shareData}
          shareData={shareData}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  setLoadedFilterShare: DiscoveryActions.setLoadedFilterShare,
};

const mapStateToProps = (state, props) => ({
  loadedFilterShare: state.discovery.loadedFilterShare,
  layout: state.dashboard.layout,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SmartFiltersModal));
