import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import ChatService from "../ChatService";

import { ChatTypes } from "../chatReducer";

import styles from "./index.module.scss";

const defaultAvatar = require("../../../assets/images/chat/no-image-user.png");
const messageReplyButton = require("../../../assets/images/chat/reply-arrow-selected.png");
const badgeVIP = require("../../../assets/images/chat/crownvip.png");
const badgeModAdmin = require("../../../assets/images/chat/mod-admin.png");
const badge1yrService = require("../../../assets/images/chat/diamond1yr.png");

function MsgBalloon({
  chatId,
  user,
  content,
  files,
  vote,
  me_vote,
  reply,
  created_at,
  disableActions,
  onOpenImageViewer,
  observer,
}) {
  const dispatch = useDispatch();
  const { user: auth_user } = useSelector((state) => state.auth);
  const isMyMsg = auth_user?.id === user.id;
  const balloonRef = useRef();

  const [updateKey, setUpdateKey] = useState(1);

  const scheduleRerender = () => {
    setTimeout(() => {
      setUpdateKey((value) => value + 1);
    }, 60 * 1000);
  };

  let dateStr;
  if (moment(created_at).isAfter(moment().subtract(10, "minutes"))) {
    dateStr = moment(created_at).fromNow();
    scheduleRerender();
  } else {
    dateStr = moment(created_at).format("hh:mm A");
  }

  useEffect(() => {
    if (observer && balloonRef.current) {
      observer.observe(balloonRef.current);

      return () => {
        observer.unobserve(balloonRef.current);
      };
    }
  }, [observer]);

  return (
    <div
      key={updateKey}
      className={`${styles["message"]} ${styles["message-" + (isMyMsg ? "right" : "left")]}`}
      ref={balloonRef}
      data-message-id={chatId}
    >
      <div className={`${styles["message-content"]}`}>
        {reply && (
          <div className={`${styles["message-reply-user"]}`}>
            <span>replying to </span>
            <span>{reply.user.name}</span>
          </div>
        )}
        <div className={`${styles["message-balloon"]} ${disableActions ? styles["action-disabled"] : ""}`}>
          {!isMyMsg && (
            <div className={styles["message-vote-btn"]}>
              <i
                className={`fa fa-thumbs-up ${me_vote > 0 && styles["active"]} ${me_vote < 0 && styles["disabled"]}`}
                aria-hidden="true"
                onClick={() => {
                  if (disableActions) return;
                  me_vote > 0 && ChatService.vote(chatId, 0);
                  !me_vote && ChatService.vote(chatId, 1);
                }}
              ></i>
              <i
                className={`fa fa-thumbs-up ${me_vote > 0 && styles["disabled"]} ${me_vote < 0 && styles["active"]}`}
                aria-hidden="true"
                style={{
                  transform: "rotate(180deg)",
                }}
                onClick={() => {
                  if (disableActions) return;
                  me_vote < 0 && ChatService.vote(chatId, 0);
                  !me_vote && ChatService.vote(chatId, -1);
                }}
              ></i>
            </div>
          )}
          <div className={styles["message-author"]}>
            <div className={styles["message-author-avatar"]}>
              <img src={user.avatar || defaultAvatar} />
            </div>
            <span className={styles["message-author-name"]}>{user.name}</span>
            {user.service1yr && <img className={styles["message-author-badge"]} src={badge1yrService} />}
            {user.vip && <img className={styles["message-author-badge"]} src={badgeVIP} />}
            {user.modadmin && <img className={styles["message-author-badge"]} src={badgeModAdmin} />}
          </div>
          <div className={styles["message-text-wrapper"]}>
            <p className={`${styles["message-text"]} message-text`} dangerouslySetInnerHTML={{ __html: content }}></p>
          </div>
          {Array.isArray(files) && files?.length > 0 && (
            <div className={styles["message-files"]}>
              {files.map((file, index) => {
                return (
                  <div
                    key={`${chatId}-file-${index}`}
                    onClick={() => {
                      onOpenImageViewer &&
                        onOpenImageViewer({
                          files,
                          index,
                        });
                    }}
                  >
                    <img src={file} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={styles["message-date"]}>{dateStr}</div>
        {reply && (
          <>
            <div className={styles["message-reply-wrapper"]}>
              <div className={styles["message-reply-info"]}>
                on {moment(reply.created_at).format("MM/DD/YY")} at {moment(reply.created_at).format("hh:mmA")}{" "}
                <strong>{reply.user.name}</strong> said:
              </div>
              <div>
                <p
                  className={`${styles["message-reply-content"]} message-text`}
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                ></p>
              </div>
            </div>
          </>
        )}
      </div>
      {vote > 0 && (
        <div className={`${styles["message-upvote-cnt"]} ${reply ? styles["has-reply"] : ""}`}>
          <span>+{vote}</span>
        </div>
      )}
      {!isMyMsg && !disableActions && (
        <span
          className={`${styles["message-reply-button"]} ${reply ? styles["has-reply"] : ""}`}
          onClick={() => {
            dispatch({
              type: ChatTypes.SET_REPLY_MSG,
              value: {
                id: chatId,
                content,
                created_at,
                user: {
                  id: user.id,
                  name: user.name,
                },
              },
            });
          }}
        >
          <img src={messageReplyButton} />
        </span>
      )}
    </div>
  );
}

MsgBalloon.propTypes = {
  chatId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    vip: PropTypes.bool,
    modadmin: PropTypes.bool,
    service1yr: PropTypes.bool,
  }),
  content: PropTypes.string.isRequired,
  files: PropTypes.array,
  vote: PropTypes.number,
  me_vote: PropTypes.number,
  created_at: PropTypes.string.isRequired,
  reply: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    user: PropTypes.any,
  }),
  disableActions: PropTypes.bool,
  onOpenImageViewer: PropTypes.func,
};

export default MsgBalloon;
