import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { produce } from "immer";
import { mdiChevronDown } from "@mdi/js";
import { Icon } from "@mdi/react";

import ColumnDropdown from "../ColumnDropdown";
import TimeframeSelector from "../../../shared/TimeframeSelector";
import SectorSelector from "../../../shared/SectorSelector";

import { APP_COLORS } from "../../../constants";
import { isDiscoveryColumnEnabled } from "../../../stateUtils";

import styles from "./style.module.scss";

const filterToSortMap = {
  last: "price_dist",
  rv_price: "volume",
  mf_price: "moneyflow",
  marketCap: "marketCap",
  float: "float",
  momentum: "momentum",
  tradeCount: "tradeCount",
  uVol: "uVol",
  vWapDist: "vWapDist",
  vWapSlope: "vWapSlope",
  short_ratio: "short_ratio",
  atr: "atr",
  halt: "halt",
  trend: "trend",
  gap: "gap",
  rs: "rs",
};
const sortableColumns = Object.keys(filterToSortMap);

function PresetRow({ label, enabled, onToggle, children, icon, iconSize = "15px" }) {
  return (
    <div
      className={`d-flex flex-row align-items-center cursor-pointer ${styles.presetrow}`}
      onClick={(e) => {
        const iconElement = e.target.closest("#row-icon-512");
        const labelElement = e.target.closest("#row-label-512");
        if (enabled && !iconElement && !labelElement) {
          return;
        }
        onToggle();
      }}
      style={enabled ? {} : { opacity: 0.5 }}
    >
      <div className="d-flex flex-row align-items-center" style={{ width: 110, gap: "5px" }}>
        <div id="row-icon-512" className="d-flex align-items-center" style={{ cursor: "pointer", width: 18 }}>
          <i
            className={`${icon}`}
            style={{ marginRight: "8px", fontSize: iconSize, color: enabled ? "#ffc107" : "currentColor" }}
          />
        </div>
        <span id="row-label-512" className="small white-no-wrap" style={{ fontSize: "15px" }}>
          {label}
        </span>
      </div>
      {children}
    </div>
  );
}

function DiscPresets({ widget, columns, getColumnTitle, discPreset, updateDiscPreset, disabledColumns }) {
  const [isExpanded, setIsExpanded] = useState(
    discPreset.sort.enabled || discPreset.timeframe.enabled || discPreset.sector.enabled
  );

  const [inactiveColumns, setInactiveColumns] = useState([]);

  const discoveryConfig = useSelector((state) => state.config.discovery);
  const history = useHistory();

  const freshDiscPreset = useRef(discPreset);
  freshDiscPreset.current = discPreset;

  useEffect(() => {
    const inactives = discoveryConfig
      .find((item) => item.id === widget)
      ?.value?.filter((item) => item.hidden === true)
      ?.map((item) => item.column);

    if (inactives?.length) {
      setInactiveColumns(inactives);
    }
  }, [widget, discoveryConfig]);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleRow = (rowKey) => {
    const newDiscPreset = produce(freshDiscPreset.current, (data) => {
      data[rowKey].enabled = !data[rowKey].enabled;
    });
    updateDiscPreset(newDiscPreset);
  };

  const updateRowData = (rowKey, field, value) => {
    const newDiscPreset = produce(freshDiscPreset.current, (data) => {
      data[rowKey][field] = value;
    });
    updateDiscPreset(newDiscPreset);
  };

  return (
    <div className="d-flex flex-column">
      <div className={`mb-2 d-flex flex-row ${styles.accordionHeader}`} onClick={toggleAccordion}>
        <div>View Options</div>
        <Icon
          path={mdiChevronDown}
          size={1}
          color="currentColor"
          className={`${styles.chevron} ${isExpanded ? styles.expanded : ""}`}
        />
      </div>

      <div className={`${styles.accordionContent} ${isExpanded ? styles.expanded : ""}`}>
        <div className={`d-flex flex-column`} style={{ gap: "8px" }}>
          <PresetRow
            label="Sort By"
            enabled={freshDiscPreset.current.sort.enabled}
            onToggle={() => toggleRow("sort")}
            icon="mdi mdi-sort"
          >
            <div className={`d-flex flex-row align-items-center ${styles.sortcontainer}`}>
              <ColumnDropdown
                selectedColumn={
                  Object.keys(filterToSortMap).find(
                    (key) => filterToSortMap[key] === freshDiscPreset.current.sort.sortBy
                  ) || ""
                }
                columns={sortableColumns}
                disabledColumns={disabledColumns}
                onItemClick={(column) => {
                  const sortColumn = filterToSortMap[column];
                  updateRowData("sort", "sortBy", sortColumn);
                }}
                columnTitles={columns.reduce((acc, c) => ({ ...acc, [c]: getColumnTitle(c) }), {})}
                inactiveColumns={sortableColumns.filter(
                  (col) => !isDiscoveryColumnEnabled(widget, filterToSortMap[col])
                )}
                inactiveTooltipContent={
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      const path = "/settings/discovery";
                      history.push(path);
                    }}
                  >
                    <span>{"column must be enabled in settings to set sort "}</span>
                    <span style={{ textDecoration: "underline" }}>Enable</span>
                  </div>
                }
              />
              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <Dropdown
                  style={{
                    border: "1px solid rgb(128, 128, 128)",
                    borderRadius: 4,
                    padding: 2,
                    marginLeft: "8px",
                  }}
                >
                  <Dropdown.Toggle className="industry_input" id="sort-order-dropdown" style={{ width: 80 }}>
                    <div className="d-inline-flex align-items-center">
                      <svg
                        width="17"
                        viewBox="0 0 138 55"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginRight: "5px",
                          height: "100%",
                          ...(freshDiscPreset.current.sort.order === "desc" ? { transform: "rotateY(180deg)" } : {}),
                        }}
                      >
                        <path d="M137 1L1 54H137V1Z" fill={APP_COLORS.active} />
                      </svg>

                      {freshDiscPreset.current.sort.order && (freshDiscPreset.current.sort.order + "").toUpperCase()}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setTimeout(() => updateRowData("sort", "order", "asc"), 0)}>
                      ASC
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTimeout(() => updateRowData("sort", "order", "desc"), 0)}>
                      DESC
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </PresetRow>

          <PresetRow
            label="Timeframe"
            enabled={freshDiscPreset.current.timeframe.enabled}
            onToggle={() => toggleRow("timeframe")}
            icon="fa fa-clock-o"
          >
            <div className={styles.selectorContainer}>
              <TimeframeSelector
                timeframe={freshDiscPreset.current.timeframe.value}
                setTimeframe={(value) => updateRowData("timeframe", "value", value)}
                hideIcon
              />
            </div>
          </PresetRow>

          <PresetRow
            label="Category"
            enabled={freshDiscPreset.current.sector.enabled}
            onToggle={() => toggleRow("sector")}
            icon="fa fa-industry"
            iconSize="12px"
          >
            <div className={styles.selectorContainer}>
              <SectorSelector
                selectedSectors={freshDiscPreset.current.sector.selected}
                onChange={(selected) => updateRowData("sector", "selected", selected)}
                handleMultiSelect
                hideIcon
              />
            </div>
          </PresetRow>
        </div>
      </div>
    </div>
  );
}

export default DiscPresets;
