import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Elements, CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import cogoToast from "cogo-toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

import { isPro, isProNew, isProPlusNew, isActiveSubscription } from "../../../constants";
import { addGTMAnalyticsScripts } from "../../../util";
import { AuthActions } from "../../../store";
import Api from "../../../api";
import i18n from "../../../../i18n";
import "./index.scss";

class Payment extends Component {
  state = {
    errTxt: "",
    succTxt: "",
    plans: [],
    selectedPlan: null,
    currentPlan: null,
    subscribing: false,
    showCardInput: false,
    changeCard: true,
    discountCode: "",
    name: "",
    coupon: null,
    success: false,
    invoices: [],
  };

  componentDidMount() {
    this.getData();

    addGTMAnalyticsScripts();
  }

  getData = async () => {
    console.info("Getting Invoices!");
    try {
      const res = await Api.getStripeInvoices();
      if (Array.isArray(res && res.data)) {
        this.setState({
          invoices: res.data,
        });
      }

      // console.info('Invoices:', res)
    } catch (e) {
      cogoToast.error("Failed to fetch the payment history");
    }
  };

  getInvoiceLabel = (invoice) => {
    return `${new Date(invoice.created * 1000).toLocaleDateString()} ${invoice.lines.data[0].plan.id}:  $${
      invoice.total / 100
    }`;
  };

  renderCardInputModal() {
    const { isPro, isProPlus } = this.props;
    return (
      <Modal
        show={this.state.showCardInput}
        onHide={() => {
          this.setState({
            showCardInput: false,
            selectedPlan: null,
            changeCard: false,
            coupon: null,
            discountCode: "",
          });
        }}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="logo">
              <span
                className="brand-logo"
                style={{
                  marginRight: 4,
                }}
              >
                MOMO
              </span>
              {(isPro || isProPlus) && (
                <div style={{ marginTop: 6 }}>
                  <span
                    style={{
                      paddingLeft: 2,
                      paddingRight: 2,
                      fontSize: "13px",
                      color: "#000000",
                      fontWeight: "600",
                      background: "#ffff",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {isPro && "PRO"}
                    {isProPlus && "PRO +"}
                  </span>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.selectedPlan && (
            <React.Fragment>
              <h4>Payment</h4>
              <h4>
                {" "}
                {">>"} Selected Plan: <span className="text-success">{this.state.selectedPlan.nickname}</span>
              </h4>
            </React.Fragment>
          )}

          {this.props.user.customer && !this.state.changeCard ? (
            <Form.Group>
              <label htmlFor="currentCard">Current Card</label>
              <div id="currentCard">
                {this.renderCurrentCard()}
                <button
                  className="mx-4 text-primary btn"
                  onClick={() => {
                    this.setState({ changeCard: true });
                  }}
                >
                  <small>Change</small>
                </button>
              </div>
            </Form.Group>
          ) : null}

          {this.state.changeCard && (
            <React.Fragment>
              <Form.Group>
                <label>Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control text-light"
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <label>Card Number</label>
                {this.renderStripeCard()}
              </Form.Group>
            </React.Fragment>
          )}

          {this.state.selectedPlan ? (
            <Form.Group>
              <div className="row">
                <div className="col-4 pt-2">
                  <span className="text-muted">Discount Code</span>
                </div>
                <div className="col-4">
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={this.state.discountCode}
                    onChange={(e) => {
                      this.setState({
                        discountCode: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 pt-2">
                  <button className="text-success coupon-apply" onClick={this.onClickApplyCoupon}>
                    apply
                  </button>
                </div>
              </div>
            </Form.Group>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="footer-container">
            <Button variant="success col-12" onClick={this.onClickSaveCard} className="payBt">
              {this.state.selectedPlan ? this.getPayAmount() : "Save"}
            </Button>
          </div>
          {/*<Button variant='light m-2' onClick={() => { this.setState({ showCardInput: false }) }}>Cancel</Button>*/}
        </Modal.Footer>
      </Modal>
    );
  }

  renderSuccessModal() {
    return (
      <Modal
        show={this.state.success}
        onHide={() => {
          this.props.history.replace("/dashboard");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h3">Payment complete</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <Button
              variant="success col-12"
              onClick={() => {
                this.props.history.replace("/dashboard");
              }}
              className="successBt"
            >
              Start using MOMO
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-muted text-center w-100">You may manage your account under settings</p>
        </Modal.Footer>
      </Modal>
    );
  }

  renderStripeCard() {
    return (
      <div className="p-1 card-container">
        <Elements stripe={stripePromise} className="p-4 b-1">
          <ElementsConsumer>
            {({ elements, stripe }) => {
              this.elements = elements;
              this.stripe = stripe;
              return (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#ffffff",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              );
            }}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }

  renderCurrentCard = () => {
    const { customer } = this.props.user;
    if (customer && customer.card_kind && customer.card_last_4 && customer.card_exp_year && customer.card_exp_month) {
      return (
        <div className="current-card-details">
          <span>{`${customer.card_kind} ***${customer.card_last_4}`} </span>
          <small>ending in </small>
          <span>{`${customer.card_exp_month}/${customer.card_exp_year}`}</span>
          <sup className="text-success m-1" style={{ fontSize: "60%" }}>
            (Active)
          </sup>
        </div>
      );
    } else {
      return (
        <div className="current-card-details">
          <small>No card information</small>
        </div>
      );
    }
  };

  onClickSaveCard = async () => {
    if (this.state.changeCard && this.state.name === "") {
      return cogoToast.error("Please enter the name");
    }

    try {
      if (this.state.changeCard) {
        const payload = await this.stripe.createToken(this.elements.getElement(CardElement), {
          name: this.state.name,
        });
        if (payload && payload.error) {
          cogoToast.error(payload.error.message);
          throw payload.error;
        }
        const res = await Api.createCustomer(payload.token.id);

        if (res && res.error) {
          cogoToast.error("Payment method verification failed!");
          throw res.error;
        }
        const { customer } = res;
        this.props.setUser({
          ...this.props.user,
          customer,
        });
        if (!this.state.selectedPlan) {
          cogoToast.success("Successfully saved card!");
        }
      }

      if (this.state.selectedPlan) {
        this.onClickSubscribe(this.state.selectedPlan);
      }
      this.setState({
        showCardInput: false,
      });
    } catch (e) {
      console.error("Failed to save card:", e);
    }
  };

  render() {
    const { customer } = this.props.user;

    return (
      <section>
        <h2 className="mb-3 mb-md-5">Payment</h2>
        <div className="ml-3 ml-md-4">
          <div className="row">
            <div className="col-md-3">
              <label style={{ fontSize: "0.875rem" }}>Current card details:</label>
            </div>
            <div className="col-md-6 mt-0 mt-md-4">{this.renderCurrentCard()}</div>
            <div className="col-md-3 mt-0 mt-md-4 d-flex justify-content-end justify-content-md-start">
              <button
                className="btn-payment-update btn-sm-block btn btn-primary btn-lg"
                onClick={() => {
                  this.setState({
                    showCardInput: true,
                    changeCard:
                      customer &&
                      customer.card_kind &&
                      customer.card_last_4 &&
                      customer.card_exp_year &&
                      customer.card_exp_month
                        ? false
                        : true,
                  });
                }}
              >
                Update
              </button>
            </div>
          </div>

          <div className="row mt-3 mt-md-5">
            <div className="col-md-3">
              <label style={{ fontSize: "0.875rem" }}>Payment History:</label>
            </div>
            <div className="col-md-6 payment-history">
              {this.state.invoices.map((invoice, index) => (
                <div key={index}>{this.getInvoiceLabel(invoice)}</div>
              ))}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

        {this.renderCardInputModal()}
        {this.renderSuccessModal()}
      </section>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) || isProNew(state.auth.user.subscription.plan)),
  isProPlus: isActiveSubscription(state.auth.user.subscription) && isProPlusNew(state.auth.user.subscription.plan),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
