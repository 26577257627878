import React, { Component, memo } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import * as _ from "lodash";
import { SortDirection } from "react-virtualized";

import {
  SHOW_ALL_DISCOVERY_FILTER,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DEFAULT_OPTIONS_MODE,
  DISCOVERY_DISPLAY_DOLLAR_CHANGE,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  SQUEEZE_SORT,
  SQUEEZE_SORT_NEXT,
  TEST_SYMBOLS,
  TIMEFRAME_TRANSFORM_MAP,
  TREND_TYPE,
  DISCOVERY_REALTIME_UPDATE_INTERVAL,
  DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME,
  TIMEFRAME_INVERSE_TRANSFORM_MAP,
} from "../constants";
import {
  encodeDiscoveryFieldForTimeframe,
  decodeDiscoveryFieldForTimeframe,
  getDiscoveryCustomColumns,
  getDiscoveryCustomColumnTimeframes,
  isRegularMarketOpen,
  isColumnAccessible,
  getPlanSubscriptionStatus,
  isFieldAccessible,
} from "../util";
import {
  DISCOVERY_COLUMN_RELATED_DATA_FIELDS,
  DISCOVERY_COLUMN_RELATED_FILTER_FIELDS,
  extractDiscoveryItemForCustomColumn,
  getDiscoveryFieldCodeForSubscription,
  inRange,
  sectorFilter,
  transformDiscoveryItem,
  transformDiscoveryItemForTimeframe,
  updateDiscoveryItem,
} from "./DiscoveryUtils";

import { withDataSource } from "../../contexts/datasource/hoc/withSocket";

import { store } from "../store/createStore";
import { DashboardActions, DiscoveryActions, QuoteActions } from "../store";
import { FILTER_TYPE } from "./discoveryReducer";

import API from "../api";

import DiscoveryFilters from "./DiscoveryFilters";
import TrendPopup from "./TrendPopup";
import AlertPopup from "./AlertPopup";
import VolumePopup from "./VolumePopup";
import MoneyFlowPopup from "./MoneyFlowPopup";
import TradeCountPopup from "./TradeCountPopup";
import DiscoveryTableGrid from "./DiscoveryTable";
import FilterFader from "./FilterFader";
import ChatService from "../chat/ChatService";

class Discovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discoveryData: [],
      finalTableData: [],
    };

    this.onRealtimeSymbolData = this.onRealtimeSymbolData.bind(this);
    this.onRealtimeFieldData = this.onRealtimeFieldData.bind(this);
    this.realtimeUpdateDiscoveryData = _.debounce(
      this.realtimeUpdateDiscoveryData.bind(this),
      DISCOVERY_REALTIME_UPDATE_INTERVAL,
      {
        maxWait: DISCOVERY_REALTIME_UPDATE_INTERVAL,
      }
    );
  }

  subscribedFields = {};
  storeUnsubscriber = null;

  realtimeSymbolDataWaitlist = [];

  finalTableDataUpdating = false;
  quoteSymbols = [];
  prevQuoteSymbols = [];

  tableDataToExport = [];

  filterFader;
  faderPreviousData = {
    previousFilteredData: [],
    currentFilterStructure: null,
    discoveryTimeframe: null,
    discoveryFilter: null,
  };
  faderUpdated = 0;

  validCustomDataFields = {};
  validCustomFilterFields = {};
  validCustomColumns = [];
  validCustomTimeframes = [];

  componentDidMount() {
    const discoveryTable = document.getElementById("discovery-table");
    if (discoveryTable) {
      discoveryTable.addEventListener("scroll", this.handleScroll);
    }

    if (this.props.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }

    this.filterFader = new FilterFader();

    this.subscribeStoreChange();
  }

  componentWillUnmount() {
    this.props.datasource.realtime?.off("discovery-s", this.onRealtimeSymbolData);
    this.props.datasource.realtime?.off("discovery-f", this.onRealtimeFieldData);

    window.removeEventListener("scroll", this.handleScroll);

    this.storeUnsubscriber && this.storeUnsubscriber();
    this.storeUnsubscriber = null;
    this.filterFader.clean();
  }

  componentDidUpdate(prevProps) {
    const newQuoteSymbols = this.getQuoteSymbols();
    if (!_.isEqual(newQuoteSymbols, this.quoteSymbols)) {
      this.quoteSymbols = this.getQuoteSymbols();
    }

    if (this.props.datasource.realtime && this.props.datasource.realtime !== prevProps.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }
    if (this.props.datasource.primary && this.props.datasource.primary !== prevProps.datasource.primary) {
      if (this.props.datasource.primary && !ChatService.datasource) {
        ChatService.init(this.props.datasource.primary);
      }
    }

    const finalDataDependencies = [
      "config.optionsMode",
      "config.options",
      "discoveryData",
      "discoveryFilter",
      "discoveryFilterExactMatch",
      "discoverySector",
      "discoverySort",
      "discoveryTimeframe",
      "isFavFilter",
      "selectedTableFilter",
      "tableFilters",
      "widget",
    ];

    if (
      finalDataDependencies.some((key) => _.get(this.props, key) !== _.get(prevProps, key)) ||
      this.state.discoveryData !== this.prevDiscoveryData ||
      this.quoteSymbols !== this.prevQuoteSymbols ||
      this.faderUpdated !== this.prevFaderUpdated
    ) {
      const newData = this.getTableData();
      this.setState({
        finalTableData: newData,
      });
      this.finalTableDataUpdating = false;
      this.tableDataToExport = this.getTableDataToExport(newData);
    }

    this.prevQuoteSymbols = this.quoteSymbols;
    this.prevDiscoveryData = this.state.discoveryData;
    this.prevFaderUpdated = this.faderUpdated;

    if (
      this.props.config !== prevProps.config ||
      this.props.widget !== prevProps.widget ||
      this.props.user !== prevProps.user
    ) {
      const discovery_config = this.getWidgetConfig().value;
      const customColumns = getDiscoveryCustomColumns(discovery_config, { user: this.props.user }, true);
      const customDataFields = customColumns.reduce((acc, column) => {
        if (!acc[column.timeframe]) {
          acc[column.timeframe] = [];
        }
        acc[column.timeframe].push(...DISCOVERY_COLUMN_RELATED_DATA_FIELDS[column.column]);
        return acc;
      }, {});
      const customFilterFields = customColumns.reduce((acc, column) => {
        if (!acc[column.timeframe]) {
          acc[column.timeframe] = [];
        }
        acc[column.timeframe].push(...DISCOVERY_COLUMN_RELATED_FILTER_FIELDS[column.column]);
        return acc;
      }, {});
      this.validCustomDataFields = customDataFields;
      this.validCustomFilterFields = customFilterFields;
      this.validCustomColumns = customColumns;
      this.validCustomTimeframes = getDiscoveryCustomColumnTimeframes(
        discovery_config,
        { user: this.props.user },
        true
      );
    }
  }

  subscribeStoreChange() {
    this.storeUnsubscriber = store.subscribe(() => {
      const { discovery } = store.getState();
      const { discoveryTimeframe, discoverySort, selectedTableFilter, tableFilters } = discovery;
      const { widget } = this.props;
      if (discoveryTimeframe?.[widget]) {
        const timeframe = discoveryTimeframe[widget];
        const fields = {};
        // Add fields for Sort column
        if (discoverySort?.[widget]?.sortBy) {
          const sortByColumnInfo = decodeDiscoveryFieldForTimeframe(discoverySort?.[widget]?.sortBy);
          const sortByTimeframe = sortByColumnInfo.timeframe || timeframe;
          fields[sortByTimeframe] = getDiscoveryFieldCodeForSubscription(sortByColumnInfo.column, "sort", false);
        }
        // Add fields included in current active filter
        if (selectedTableFilter?.[widget] && tableFilters?.[selectedTableFilter[widget]]?.values) {
          for (const field in tableFilters[selectedTableFilter[widget]].values) {
            const columnInfo = decodeDiscoveryFieldForTimeframe(field);
            // Should skip if field is not a valid custom field
            if (
              columnInfo.timeframe &&
              !this.validCustomFilterFields[columnInfo.timeframe]?.includes(columnInfo.column)
            ) {
              continue;
            }
            const filterTimeframe = columnInfo.timeframe || timeframe;
            if (!fields[filterTimeframe]) {
              fields[filterTimeframe] = [];
            }
            const fieldCodes = getDiscoveryFieldCodeForSubscription(columnInfo.column, "filter", false);
            for (const item of fieldCodes) {
              if (!fields[filterTimeframe].includes(item)) {
                fields[filterTimeframe].push(item);
              }
            }
          }
        }

        // Only newly added fields should be fetched, so get the difference
        let fieldsToFetch = {};
        for (const tf in fields) {
          if (Array.isArray(fields[tf]) && fields[tf].length) {
            fields[tf] = fields[tf].sort();
            if (!this.subscribedFields[tf]) {
              fieldsToFetch[tf] = fields[tf];
            } else {
              const diff = _.difference(fields[tf], this.subscribedFields[tf]);
              if (diff.length) {
                fieldsToFetch[tf] = diff;
              }
            }
          } else {
            delete fields[tf];
          }
        }
        this.subscribedFields = fields;
        this.handleFieldsChange(fieldsToFetch, timeframe);
      }
    });
  }

  async handleFieldsChange(fields, mainTimeframe) {
    if (!Object.keys(fields).length) {
      return;
    }
    try {
      const data = await Promise.all(
        Object.keys(fields).map(async (tf) => {
          const response = await API.getDiscoveryAllByField(fields[tf], TIMEFRAME_TRANSFORM_MAP[tf]);
          return { timeframe: tf, data: response };
        })
      );
      const dataMap = {};
      for (const tfData of data) {
        for (const item of tfData.data) {
          if (!dataMap[item.symbol]) {
            dataMap[item.symbol] = {};
          }
          dataMap[item.symbol][tfData.timeframe] = item;
        }
      }

      const { discoveryData } = this.state;

      this.finalTableDataUpdating = true;
      this.setState({
        discoveryData: discoveryData.map((item) => {
          const symbolData = dataMap[item.symbol];
          if (symbolData) {
            const updated = {
              ...item,
            };
            for (const tf in symbolData) {
              updateDiscoveryItem(updated, symbolData[tf], tf, this.validCustomDataFields);
              if (tf === mainTimeframe) {
                updateDiscoveryItem(updated, symbolData[tf], null);
              }
            }
            return updated;
          } else {
            return item;
          }
        }),
      });
    } catch (e) {
      cogoToast.error("Error fetching discovery data!");
    }
  }

  onRealtimeDatasourceInit() {
    this.props.datasource.realtime.on("discovery-s", this.onRealtimeSymbolData);
    this.props.datasource.realtime.on("discovery-f", this.onRealtimeFieldData);
  }

  onRealtimeSymbolData(event) {
    const {
      detail: [timeframe, data],
    } = event;

    this.realtimeSymbolDataWaitlist.push([timeframe, data]);
    this.realtimeUpdateDiscoveryData();
  }

  realtimeUpdateDiscoveryData() {
    if (!this.realtimeSymbolDataWaitlist.length) {
      return;
    }

    const { widget, discoveryTimeframe } = this.props;
    const { discoveryData } = this.state;

    const mainTimeframe = discoveryTimeframe[widget];
    this.faderPreviousData.discoveryTimeframe = mainTimeframe;
    this.finalTableDataUpdating = true;

    this.setState({
      discoveryData: discoveryData.map((item) => {
        const newData = this.realtimeSymbolDataWaitlist.filter((data) => data[1].s === item.symbol);
        if (!newData.length) {
          return item;
        }
        let newItem = {};
        for (const [timeframe, data] of newData) {
          if (!newItem[timeframe]) {
            newItem[timeframe] = {};
          }
          newItem[timeframe] = {
            ...newItem[timeframe],
            ...data,
          };
        }

        const updatedItem = _.cloneDeep(item);
        for (const timeframe in newItem) {
          const tf = TIMEFRAME_INVERSE_TRANSFORM_MAP[timeframe];
          updateDiscoveryItem(
            updatedItem,
            newItem[timeframe],
            tf === mainTimeframe ? null : tf,
            this.validCustomDataFields
          );
        }
        return updatedItem;
      }),
    });
    this.realtimeSymbolDataWaitlist = [];
  }

  onRealtimeFieldData(event) {
    const {
      detail: [field, timeframe, data],
    } = event;

    const realtimeFormat = Object.keys(data).map((symbol) => {
      return [
        timeframe,
        {
          s: symbol,
          [field]: data[symbol],
        },
      ];
    });
    // .filter((item) => this.props.viewportSymbols[widget].includes(item.s));

    if (realtimeFormat.length > 0) {
      this.realtimeSymbolDataWaitlist.push(...realtimeFormat);
      this.realtimeUpdateDiscoveryData();
    }
  }

  async onTriggerDataFetch(params = {}) {
    // const { ignoreOldData = false } = params;
    const { widget, discoveryTimeframe } = this.props;
    const timeframe = discoveryTimeframe[widget] || "1day";

    this.realtimeSymbolDataWaitlist = [];

    try {
      const stats = await API.getDiscovery({
        timeframe: TIMEFRAME_TRANSFORM_MAP[timeframe],
      });

      const symbolSPY = (stats || []).find((item) => item.s === "SPY");
      const priceDistSPY = symbolSPY ? (symbolSPY.p || [])[0] : 0;

      const data = (stats || []).map((item) => transformDiscoveryItem(item, priceDistSPY));

      if (data.length > 0) {
        this.finalTableDataUpdating = true;
        this.setState({
          discoveryData: data,
        });
      }

      this.handleFieldsChange(this.subscribedFields, timeframe);
    } catch (e) {
      cogoToast.error("Error fetching discovery data!");
    }
  }

  async onSymbolsAppear(symbols) {
    if (!Array.isArray(symbols) || !symbols.length) {
      return;
    }
    const { widget, discoveryTimeframe } = this.props;
    const timeframe = discoveryTimeframe[widget] || "1day";
    const customColumns = this.validCustomColumns;
    const customTimeframes = this.validCustomTimeframes;
    // TODO: exclude custom columns that are hidden && not included in the filter
    try {
      const data = await Promise.all(
        _.uniq([timeframe, ...customTimeframes]).map(async (tf) => {
          const response = await API.getDiscoveryFullQuote(symbols, TIMEFRAME_TRANSFORM_MAP[tf]);
          return { timeframe: tf, data: response };
        })
      );

      // Get SPY price_dist for relStr calculation
      const { discoveryData } = this.state;
      const symbolSPY = discoveryData.find((item) => item.symbol === "SPY");
      const priceDistSPY = symbolSPY ? symbolSPY["price_dist"] : 0;

      // Process symbol data for primary timeframe
      const dataMap = data[0].data.reduce((acc, item) => {
        acc[item.s] = transformDiscoveryItem(item, priceDistSPY);
        return acc;
      }, {});

      // Process data for custom Discovery columns
      if (customColumns.length) {
        for (const item of customColumns) {
          const tfData = data.find((item1) => item1.timeframe === item.timeframe);
          if (tfData) {
            for (const symbolData of tfData.data) {
              // TODO: use correct data for SPY price_dist
              const transformed = transformDiscoveryItem(symbolData, priceDistSPY);
              const extracted = extractDiscoveryItemForCustomColumn(transformed, item.column);
              const encoded = transformDiscoveryItemForTimeframe(extracted, item.timeframe);
              if (dataMap[symbolData.s]) {
                dataMap[symbolData.s] = {
                  ...dataMap[symbolData.s],
                  ...encoded,
                };
              }
            }
          }
        }
      }

      // Update state
      this.finalTableDataUpdating = true;
      this.setState({
        discoveryData: discoveryData.map((item) => {
          if (dataMap[item.symbol]) {
            return dataMap[item.symbol];
          } else {
            return item;
          }
        }),
      });
    } catch (e) {
      cogoToast.error("Error fetching full qoute for viewport symbols!");
    }
  }

  getWidgetConfig() {
    const { config, widget } = this.props;
    const discoveryConfig = config?.discovery || DEFAULT_DISCOVERY_SETTING_LIST;
    return discoveryConfig.find((item) => item.id === widget) || discoveryConfig[0];
  }

  getScrollPercent() {
    const h = document.getElementById("discovery-table"),
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  handleScroll = (e) => {
    if (this.getScrollPercent() === 100) {
      const { discoveryIndex } = this.props;
      this.props.updateDiscoveryIndex(discoveryIndex + 25);
    }
  };

  onFavPress = () => {
    const { toggleFavFilter, widget } = this.props;
    toggleFavFilter(widget);
  };

  getQuoteSymbols = () => {
    return (store.getState().quote.quotes ?? []).map((quote) => quote.symbol);
  };

  isSymbolFav = (symbol) => {
    const qouteItem = this.quoteSymbols.find((item) => item === symbol);
    return qouteItem ? true : false;
  };

  getNextSortDirection = (columnKey, current) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(columnKey);
    if (columnInfo.column === "squeeze") {
      return SQUEEZE_SORT_NEXT(current);
    } else {
      return current === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    }
  };

  getInitialSortDirection = (columnKey) => {
    const columnInfo = decodeDiscoveryFieldForTimeframe(columnKey);
    if (columnInfo.column === "squeeze") {
      return SQUEEZE_SORT.NOW;
    } else {
      return SortDirection.DESC;
    }
  };

  _sort({ sortBy }) {
    const { updateDiscoverySort, discoverySort, widget } = this.props;
    if (!["alert", "sparkline"].includes(sortBy)) {
      if (sortBy === discoverySort[widget].sortBy) {
        updateDiscoverySort(
          {
            sortBy,
            sortDirection: this.getNextSortDirection(sortBy, discoverySort[widget].sortDirection),
          },
          widget
        );
      } else {
        updateDiscoverySort(
          {
            sortBy,
            sortDirection: this.getInitialSortDirection(sortBy),
          },
          widget
        );
      }
    }
  }

  _sortList(sortBy, sortDirection, items) {
    const { column, timeframe } = decodeDiscoveryFieldForTimeframe(sortBy);
    if (column === "uVol" || column === "float") {
      const sorted = _.sortBy(
        items.filter((value) => value[sortBy] && value[sortBy] > 0),
        sortBy
      );
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => !value[sortBy] || value[sortBy] < 0),
      ];
    }
    if (column === "moneyflow" || column === "volume" || column === "marketCap" || column === "tradeCount") {
      const sorted = _.sortBy(
        items.filter((value) => !isNaN(value[sortBy]) && value[sortBy]),
        sortBy
      );
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => isNaN(value[sortBy]) || !value[sortBy]),
      ];
    }
    if (column === "squeeze") {
      if (sortDirection === SQUEEZE_SORT.NOW) {
        return items.filter((item) => item[sortBy] === 0 || item[sortBy] === "0");
      } else if (sortDirection === SQUEEZE_SORT.PRE_ASC || sortDirection === SQUEEZE_SORT.PRE_DESC) {
        let sorted = items
          .filter((item) => item[sortBy] > 0)
          .sort((itema, itemb) => {
            const a = Number(itema[sortBy]);
            const b = Number(itemb[sortBy]);
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          });
        if (sortDirection === SQUEEZE_SORT.PRE_DESC) sorted = sorted.reverse();
        return sorted;
      } else if (sortDirection === SQUEEZE_SORT.POST_ASC || sortDirection === SQUEEZE_SORT.POST_DESC) {
        let sorted = items
          .filter((item) => item[sortBy] < 0)
          .sort((itema, itemb) => {
            const a = Number(itema[sortBy]);
            const b = Number(itemb[sortBy]);
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
          });
        if (sortDirection === SQUEEZE_SORT.POST_DESC) sorted = sorted.reverse();
        return sorted;
      }
      return items;
    }
    if (column === "price_dist") {
      const discovery_config = this.getWidgetConfig().value;
      let lastDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === column && item.timeframe == timeframe) {
          lastDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (lastDistDisplayOption === DISCOVERY_DISPLAY_DOLLAR_CHANGE) {
        sortBy = encodeDiscoveryFieldForTimeframe("dollar_dist", timeframe);
      }
    }
    if (column === "atr") {
      const discovery_config = this.getWidgetConfig().value;
      let atrDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === column && item.timeframe == timeframe) {
          atrDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (atrDistDisplayOption === DISCOVERY_DISPLAY_DOLLAR_CHANGE) {
        sortBy = encodeDiscoveryFieldForTimeframe("atr_dollar_dist", timeframe);
      }
    }
    if (column === "gap") {
      const discovery_config = this.getWidgetConfig().value;
      let gapDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === column && item.timeframe == timeframe) {
          gapDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (gapDistDisplayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE) {
        sortBy = encodeDiscoveryFieldForTimeframe("gap_percent_dist", timeframe);
      }
    }
    if (column === "halt") {
      const sorted = items
        .filter((value) => value.halt || value.luld)
        .sort((a, b) => {
          if (a.halt) {
            if (b.halt) {
              return a.symbol > b.symbol ? 1 : a.symbol < b.symbol ? -1 : 0;
            } else {
              return 1;
            }
          } else {
            if (b.halt) {
              return -1;
            } else {
              return a.luld[0] > b.luld[0] ? 1 : a.luld[0] < b.luld[0] ? -1 : 0;
            }
          }
        });
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => !value.halt && !value.luld),
      ];
    }
    // if (column === 'trend') {
    //   return items.filter(value => sortDirection === SortDirection.ASC ? value[sortBy][0] : !value[sortBy][0]);
    // }
    return sortDirection === SortDirection.ASC ? _.sortBy(items, sortBy) : _.sortBy(items, sortBy).reverse();
  }

  getTableData = () => {
    const {
      user,
      options,
      config,
      widget,
      discoverySort,
      discoveryFilter,
      discoveryFilterExactMatch,
      discoverySector,
      isFavFilter,
      tableFilters,
      selectedTableFilter,
      discoveryTimeframe,
      filterFader: filterFaderData,
      filterFadeoutTime: filterFadeoutTimeData,
      viewportSymbols: viewportData,
    } = this.props;
    const viewportSymbols = viewportData?.[widget] || [];
    const filterFadeoutTime = filterFadeoutTimeData?.[widget] || DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME;
    const filterFader = filterFaderData?.[widget];

    const optionsMode = config.optionsMode || DEFAULT_OPTIONS_MODE;
    const { discoveryData } = this.state;

    const filteredItems = [];
    const unFilteredItems = [];

    const plan = getPlanSubscriptionStatus({ user });

    discoveryData.forEach((item) => {
      const pass = (() => {
        let pass = true;

        pass = pass && TEST_SYMBOLS.indexOf(item.symbol) < 0;

        if (!pass) return false;

        pass = pass && sectorFilter(item, discoverySector[widget]);

        if (!pass) return false;

        if (discoveryFilter[widget]) {
          pass =
            pass &&
            (discoveryFilterExactMatch[widget]
              ? (item.symbol || "").toUpperCase() === (discoveryFilter[widget] || "").toUpperCase()
              : (item.symbol || "").toUpperCase().includes((discoveryFilter[widget] || "").toUpperCase()));
        }

        if (isFavFilter[widget]) {
          pass = pass && this.quoteSymbols.includes(item.symbol);
        }

        if (!pass) return false;

        // .filter((item) => item.uVol >= 0) // uVol should not show negative values as it doesn't make sense to have "negative unusual volume".
        pass =
          pass &
          (() => {
            if (!tableFilters || !selectedTableFilter[widget]) {
              return true;
            }

            const tableFilter = tableFilters[selectedTableFilter[widget]];
            if (!tableFilter) {
              return true;
            }
            if (tableFilter.type === FILTER_TYPE.ALL) {
              return Object.keys(tableFilter.values).every((key) => {
                if (!key) {
                  return true;
                }
                const columnInfo = decodeDiscoveryFieldForTimeframe(key);
                // Should skip if Discovery field is not accessible
                if (!isFieldAccessible(columnInfo.column, plan)) {
                  return true;
                }
                // Should skip if Discovery field is not valid
                if (
                  columnInfo.timeframe &&
                  !this.validCustomFilterFields[columnInfo.timeframe]?.includes(columnInfo.column)
                ) {
                  return true;
                }
                let { min, max } = tableFilter.values[key];
                if (columnInfo.column === "squeeze" || columnInfo.column === "news" || columnInfo.column === "halt") {
                  if (Object.prototype.toString.call(min) !== "[object Object]") {
                    min = {};
                  }
                  max = null;
                } else if (columnInfo.column === "trend") {
                  if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
                    min = TREND_TYPE.BUY;
                  }
                  max = null;
                } else {
                  if (isNaN(min)) min = -Infinity;
                  else if (!min && min !== 0 && min !== "0") min = -Infinity;
                  if (isNaN(max)) max = Infinity;
                  else if (!max && max !== 0 && max !== "0") max = Infinity;
                }
                return inRange(item, min, max, key);
              });
            } else {
              return Object.keys(tableFilter.values).some((key) => {
                if (!key) {
                  return true;
                }
                const columnInfo = decodeDiscoveryFieldForTimeframe(key);
                // Should skip if Discovery field is not accessible
                if (!isFieldAccessible(columnInfo.column, plan)) {
                  return true;
                }
                // Should skip if Discovery field is not valid
                if (
                  columnInfo.timeframe &&
                  !this.validCustomFilterFields[columnInfo.timeframe]?.includes(columnInfo.column)
                ) {
                  return true;
                }
                let { min, max } = tableFilter.values[key];
                if (columnInfo.column === "squeeze" || columnInfo.column === "news" || columnInfo.column === "halt") {
                  if (Object.prototype.toString.call(min) !== "[object Object]") {
                    min = {};
                  }
                  max = null;
                } else if (columnInfo.column === "trend") {
                  if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
                    min = TREND_TYPE.BUY;
                  }
                  max = null;
                } else {
                  if (isNaN(min)) min = -Infinity;
                  else if (!min && min !== 0 && min !== "0") min = -Infinity;
                  if (isNaN(max)) max = Infinity;
                  else if (!max && max !== 0 && max !== "0") max = Infinity;
                }
                return inRange(item, min, max, key);
              });
            }
          })();

        if (!pass) return false;

        pass =
          pass &&
          (() => {
            if (optionsMode !== "Filter") return true;
            if (options.indexOf(item.symbol) > -1) return true;
            if (this.isSymbolFav(item.symbol)) return true;
            return false;
          })();

        return pass;
      })();

      const newItem = {
        index: filteredItems.length,
        ...item,
        alert: item.symbol,
      };

      if (pass) {
        filteredItems.push(newItem);
      }
      unFilteredItems.push({ ...newItem });
    });

    // Fader
    let fadedItems;

    if (filterFader) {
      if (Object.keys(tableFilters?.[selectedTableFilter?.[widget]]?.values ?? {}).length) {
        this.filterFader.updateFadeoutTime(filterFadeoutTime);

        if (
          (this.faderPreviousData.currentFilterStructure &&
            this.faderPreviousData.currentFilterStructure !==
              JSON.stringify(tableFilters?.[selectedTableFilter?.[widget]])) ||
          this.faderPreviousData.discoveryTimeframe !== discoveryTimeframe?.[widget] ||
          this.faderPreviousData.discoveryFilter !== discoveryFilter?.[widget] ||
          this.faderPreviousData.isFavFilter !== isFavFilter?.[widget] ||
          this.faderPreviousData.discoverySector !== discoverySector?.[widget]
        ) {
          this.filterFader.reset();

          this.previousFilteredData = filteredItems;
          this.faderPreviousData.discoveryTimeframe = discoveryTimeframe?.[widget];
          this.faderPreviousData.discoveryFilter = discoveryFilter?.[widget];
          this.faderPreviousData.isFavFilter = isFavFilter?.[widget];
          this.faderPreviousData.discoverySector = discoverySector?.[widget];
        }
        this.faderPreviousData.currentFilterStructure = JSON.stringify(tableFilters?.[selectedTableFilter?.[widget]]);

        const faderOutput = this.filterFader.update({
          data: filteredItems,
          previousData: this.previousFilteredData,
          unfilteredData: unFilteredItems,
          viewportSymbols,
          triggerUpdate: () => {
            if (!this.forced) {
              this.forced = 0;
            }
            // console.log("Forced 🛑🛑", ++this.forced);
            this.faderUpdated++;
            this.forceUpdate();
          },
        });

        if (faderOutput.changed) {
          this.faderUpdated++;
        }

        fadedItems = faderOutput.data;
        this.previousFilteredData = filteredItems;
      }
    }

    const allItems = filteredItems.concat(fadedItems ?? []);

    return this._sortList(discoverySort[widget].sortBy, discoverySort[widget].sortDirection, allItems);
  };

  getTableDataToExport = (finalTableData) => {
    const data = _.cloneDeep(finalTableData.slice(0, 1000));

    return data.map((item) => {
      if (!isNaN(item.vWapDist)) {
        item.vWapDist = Number(item.vWapDist).toFixed(4);
      }
      if (item.squeeze == 0) {
        item.squeeze = "now";
      }
      item.trend = item.trend[0] ? TREND_TYPE.BUY : TREND_TYPE.SELL;
      if (!item.halt) {
        if (Array.isArray(item.luld) && isRegularMarketOpen()) {
          item.halt = `up: ${item.luld[0]} down: ${item.luld[1]}`;
        } else {
          item.halt = "-";
        }
      }
      if (!item.marketCap) {
        item.marketCap = "-";
      }
      return item;
    });
  };

  clearDiscoveryFilter = () => {
    const {
      resetDiscoverySector,
      isFavFilter,
      toggleFavFilter,
      updateTableFilter,
      updateDiscoveryFilter,
      updateDiscoverySort,
      widget,
    } = this.props;
    resetDiscoverySector(widget);
    isFavFilter[widget] && toggleFavFilter(widget);
    updateTableFilter({
      widget,
      filterName: SHOW_ALL_DISCOVERY_FILTER,
    });
    updateDiscoveryFilter(
      {
        symbol: "",
        exactMatch: false,
      },
      widget
    );
    updateDiscoverySort(
      {
        sortBy: "price_dist",
        sortDirection: SortDirection.DESC,
      },
      widget
    );
  };

  renderDiscoveryTableResponsive = () => {
    const { discoveryIndex, discoverySort, widget } = this.props;
    const { sortBy, sortDirection } = discoverySort[widget];
    const { discoveryData } = this.state;
    const data = this.state.finalTableData;

    return (
      <>
        <DiscoveryTableGrid
          widget={widget}
          index={discoveryIndex}
          discoveryData={data}
          _sort={this._sort.bind(this)}
          sortBy={sortBy}
          sortDirection={sortDirection}
          checkIsFavorite={(symbol) => this.isSymbolFav(symbol)}
          onSetSymbolFav={(symbol) => {
            if (this.isSymbolFav(symbol)) {
              this.props.removeFromQuotes(symbol);
            } else {
              this.props.registerQuote([symbol]);
            }
          }}
          onAlertTrigger={this.props.alertSelected}
          onSymbolsAppear={this.onSymbolsAppear.bind(this)}
          tableConfig={this.getWidgetConfig().value}
        />
        {discoveryData && !this.finalTableDataUpdating && discoveryData.length > 0 && (!data || data.length === 0) && (
          <div
            className={"d-flex flex-column justify-content-center align-items-center"}
            style={{ top: "8rem", left: 0, right: 0, position: "absolute" }}
          >
            <span>NO RESULTS FOUND</span>
            <div
              className={"btn-load-recent"}
              style={{ fontSize: "1.2rem", marginTop: "1rem" }}
              onClick={this.clearDiscoveryFilter}
            >
              CLEAR FILTERS
            </div>
          </div>
        )}
      </>
    );
  };

  discoveryFilterCheckTimeoutId = null;
  discoveryFilterSymbolLastInced = null;

  onChangeDiscoveryFilter = (value, exactMatch = false) => {
    const { widget } = this.props;
    const discoveryFilter = value.toUpperCase();
    this.props.updateDiscoveryFilter(
      {
        symbol: discoveryFilter,
        exactMatch,
      },
      widget
    );

    if (this.discoveryFilterCheckTimeoutId) {
      clearTimeout(this.discoveryFilterCheckTimeoutId);
      this.discoveryFilterCheckTimeoutId = null;
    }
    if (discoveryFilter !== "" && discoveryFilter.length <= 6) {
      this.discoveryFilterCheckTimeoutId = setTimeout(() => {
        this.discoveryFilterCheckTimeoutId = null;
        const currentDiscoveryFilter = this.props.discoveryFilter[widget];
        if (currentDiscoveryFilter !== discoveryFilter) {
          return;
        }
        if (this.discoveryFilterSymbolLastInced && new Date() - this.discoveryFilterSymbolLastInced < 1000 * 60 * 60) {
          return;
        }
        API.incSymbolStats(currentDiscoveryFilter);
        this.discoveryFilterSymbolLastInced = new Date();
      }, 2000);
    }
  };

  // handleMaximize = () => {
  //   const { maximisedView, maximiseView } = this.props;
  //   maximiseView(maximisedView ? null : "discovery");
  //   window.scrollTo(0, 0);
  //   document
  //     .getElementById("discovery-table")
  //     .addEventListener("scroll", this.handleScroll);
  // };

  render() {
    // console.log({ faderUpdated: this.faderUpdated });
    const { maximisedView, widget } = this.props;

    return (
      <div className={maximisedView ? "w-100 card" : "card d-flex flex-row data-section mx-0 h-100"}>
        <div className="d-flex flex-column flex-grow-1">
          <div className="discovery-filter-container d-flex flex-row justify-content-between text-center flex-wrap mb-0 pb-0">
            <DiscoveryFilters
              widget={widget}
              settingLoaded={this.props.settingLoaded}
              maximisedView={maximisedView}
              onChangeDiscoveryFilter={this.onChangeDiscoveryFilter.bind(this)}
              tableDataToExport={this.tableDataToExport}
              triggerDataFetch={this.onTriggerDataFetch.bind(this)}
            />
          </div>
          <div
            className={(maximisedView ? "discovery-max" : "discovery-normal") + " discovery-table flex-grow-1"}
            id="discovery-table"
          >
            {this.renderDiscoveryTableResponsive()}
          </div>
        </div>
        <AlertPopup widget={widget} />
        <MoneyFlowPopup widget={widget} />
        <VolumePopup widget={widget} />
        <TradeCountPopup widget={widget} />
        <TrendPopup widget={widget} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  alertSelected: DiscoveryActions.alertSelected,
  updateDiscoveryIndex: DiscoveryActions.updateDiscoveryIndex,
  updateDiscoveryFilter: DiscoveryActions.updateDiscoveryFilter,
  resetDiscoverySector: DiscoveryActions.resetDiscoverySector,
  updateDiscoverySort: DiscoveryActions.updateDiscoverySort,
  updateTableFilter: DiscoveryActions.updateTableFilter,
  setFilterFadeoutTime: DiscoveryActions.setFilterFadeoutTime,
  toggleFavFilter: DiscoveryActions.toggleFavFilter,
  removeFromQuotes: QuoteActions.removeFromQuotes,
  registerQuote: QuoteActions.registerQuote,
  maximiseView: DashboardActions.maximiseView,
};

const mapStateToProps = (state, props) => ({
  discoveryTimeframe: state.discovery.discoveryTimeframe,
  discoveryIndex: state.discovery.discoveryIndex,
  discoverySort: state.discovery.discoverySort,
  discoveryFilter: state.discovery.discoveryFilter,
  discoveryFilterExactMatch: state.discovery.discoveryFilterExactMatch,
  discoverySector: state.discovery.discoverySector,
  isFavFilter: state.discovery.isFavFilter,
  tableFilters: state.discovery.tableFilters,
  selectedTableFilter: state.discovery.selectedTableFilter,
  filterFader: state.discovery.filterFader,
  filterFadeoutTime: state.discovery.filterFadeoutTime,
  viewportSymbols: state.discovery.viewportSymbols,
  config: state.config,
  user: state.auth.user,
  maximisedView: state.dashboard.maximisedView,
  options: state.options.options,
});

export default withDataSource(connect(mapStateToProps, mapDispatchToProps)(memo(Discovery)));
