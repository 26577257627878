import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TIMEFRAME_LABEL_ABBR } from "../../constants";

const TimeframeHighlight = styled.span`
  height: 12px;
  display: grid;
  place-content: center;
  font-size: 10px;
  font-weight: 500;
  padding: 1px;
  margin-left: 4px;
  line-height: 12px;
  color: #000;
  background-color: #f6c548;
  ${({ palette }) => {
    if (palette === "standard") {
      return `
        background-color: #f6c548;
      `;
    } else if (palette === "light") {
      return `
        background-color: #fff;
      `;
    } else if (palette === "orange") {
      return `
        background-color: transparent;
        color: rgb(246, 197, 72);
      `;
    }
  }}
  ${({ disabled }) =>
    disabled &&
    `
    background-color: #737373;
  `}
`;

function DiscoveryLockedFieldMark(props) {
  const { timeframe, disabled, palette } = props;
  return (
    <TimeframeHighlight disabled={disabled} palette={palette}>
      {TIMEFRAME_LABEL_ABBR[timeframe]}
    </TimeframeHighlight>
  );
}

DiscoveryLockedFieldMark.propTypes = {
  timeframe: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  palette: PropTypes.oneOf(["standard", "light", "orange"]),
};

DiscoveryLockedFieldMark.defaultProps = {
  disabled: false,
  palette: "standard",
};

export default DiscoveryLockedFieldMark;
