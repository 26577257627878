import { getDeviceActiveLayoutConfig } from "./dashboard/dashboardReducer";
import { store } from "./store/createStore";

export function isDiscoveryColumnEnabled(widget, column) {
  const state = store.getState();
  const discoveryConfig = state.config.discovery;

  const enabled = discoveryConfig
    .find((item) => item.id === widget)
    ?.value?.filter((item) => item.hidden === false)
    ?.map((item) => item.column);

  if (enabled?.length) {
    return enabled.includes(column);
  }
}

export function isWidgetEnabled(widget) {
  const state = store.getState();

  const layout = state.dashboard.layout;

  const activeLayoutConfig = getDeviceActiveLayoutConfig(layout);

  return activeLayoutConfig.menu[widget];
}

export function isWidgetPopout(widget) {
  const state = store.getState();

  const layout = state.dashboard.layout;

  const activeLayoutConfig = getDeviceActiveLayoutConfig(layout);

  return !!activeLayoutConfig.popout.find((item) => item.widget === widget);
}
