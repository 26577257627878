import React, { Component } from "react";
import cogoToast from "cogo-toast";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import withScreenSizes from "../shared/Utilities/withScreenSizes";
import { Dropdown, OverlayTrigger, Popover, Tab } from "react-bootstrap";
import { CSSTransitionGroup } from "react-transition-group";

import {
  PRICE_MAX,
  DEFAULT_NEWS_CONFIG,
  NEWS_RECENCY_RANGE,
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  VOICE_NOTI_LABEL,
  VOICE_NOTI_VOICE,
  VOICE_NOTI_TYPE,
  VOICE_BUFFER_RANGE,
  DEFAULT_VOICE_BUFFER,
  ALERT_TYPE_TITLE,
  DEFAULT_OPTIONS_MODE,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DEFAULT_STREAM_SETTING_LIST,
  DEFAULT_HALT_CONFIG,
  HALT_TIME_FILTER_RANGE,
  VOICE_NAME_MAP,
  DEFAULT_ALERT_RATE,
} from "../constants";
import { complementConfig } from "../util";

import ConditionalAlertModal, {
  CONDITIONAL_ALERT_MODAL_TYPE_ADD,
  CONDITIONAL_ALERT_MODAL_TYPE_UPDATE,
} from "./ConditionalAlert";
import SettingsWebhookModal from "./SettingsWebhookModal";
import StreamSettings from "./Stream";
import DiscoverySettings from "./Discovery";
import CollapsibleWrapper from "./CollapsibleWrapper";
import AlertInput from "./alertInput";

import StepSlider from "../shared/StepRangeSlider/StepSlider";
import RadioButton from "../shared/Button/RadioButton";
import UpgradeButton from "../shared/Button/UpgradeButton";
import HelpTooltip from "../shared/HelpTooltip";

import { AuthActions, ConfigActions, SettingActions } from "../store";
import { store } from "../store/createStore";

import API from "../api";

import "./settings.css";
import style from "./style.module.scss";

const alerts = [
  {
    type: "trade",
    label: "High/Low",
    webhook_type: "high_low",
    valueLabel: "Sensitivity",
  },
  {
    type: "uv",
    label: "Unusual Volume (%)",
    webhook_type: "uvol",
    valueLabel: "% Deviation",
    pro: true,
  },
  {
    type: "vwap",
    label: "VWAP dist (%)",
    webhook_type: "vwapdist",
    valueLabel: "% Dist VWAP",
    pro: true,
  },
  {
    type: "price",
    label: "Price",
    webhook_type: "price",
    valueLabel: "% Change",
    pro: true,
  },
];

const CodeButton = ({ onClick }) => {
  return (
    <span
      className="alert-code-button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      <i className="fa fa-code"></i>
    </span>
  );
};

export class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      isSmallDevice: window.matchMedia("(max-width: 768px)").matches,
      currentAlert: { category: "", rate: "" },
      editingAlertId: null,

      newsSaving: false,
      optionsSaving: false,
      voiceNotiSaving: false,
      haltSaving: false,

      voiceSettingVisible: false,

      filtered_alerts: [],
      showFilterModal: false,
      filterModalType: CONDITIONAL_ALERT_MODAL_TYPE_ADD,
      filterModalUpdateItem: null,

      alert_webhook: {},
      webhookType: null,
      showWebhookModal: false,
    };

    this.ref_WebhookModal = null;
  }

  prevConfig = null;
  storeUnsubscribe = null;
  configPendingSaveTimeoutId = null;

  componentDidMount() {
    const handler = (e) => this.setState({ isSmallDevice: e.matches });
    window.matchMedia("(max-width: 767px)").addListener(handler);

    this.getSettingConfig();
    this.getAlertSettings();
    this.getSectorSymbolsCnt();

    this.prevConfig = store.getState().config;
    this.storeUnsubscribe = store.subscribe(() => {
      const { config: currentConfig, auth } = store.getState();
      if (!auth.authenticated) {
        return;
      }
      if (currentConfig != this.prevConfig) {
        this.storeConfigChanged(currentConfig);
      }
      this.prevConfig = currentConfig;
    });
  }

  componentWillUnmount() {
    !this.storeUnsubscribe || this.storeUnsubscribe();
    if (this.configPendingSaveTimeoutId) {
      this.updateSettingConfig(this.prevConfig);
      clearTimeout(this.configPendingSaveTimeoutId);
      this.configPendingSaveTimeoutId = null;
    }
  }

  getSectorSymbolsCnt = async () => {
    try {
      const sectorInfo = await API.getSectorSymbolsCnt();
      this.props.setSectorSymbolsCnt(sectorInfo);
    } catch (e) {}
  };

  getAlertSettings = async () => {
    const response = await API.getAlerts();
    this.setState({
      alerts: response.alerts,
      filtered_alerts: response.filtered_alerts || [],
      alert_webhook: response.alert_webhook || {},
    });
  };

  getAlertsByType = (type) => {
    const { alerts } = this.state;
    return alerts.filter((alert) => alert.type === type);
  };

  getSettingConfig = async () => {
    try {
      const res = await API.getSetting();
      if (!res.success) {
        throw "error";
      }
      try {
        const config = JSON.parse(res.config);
        this.props.setConfig(complementConfig(config, this.props.auth, true));
        // setTimeout(() => {
        //   for (const streamChannel in this._refPriceSlider || {}) {
        //     this._refPriceSlider[streamChannel].setInitialStateChildren();
        //   }
        //   for (const streamChannel in this._refVolumeSlider || {}) {
        //     this._refVolumeSlider[streamChannel].setInitialStateChildren();
        //   }
        //   for (const streamChannel in this._refFloatSlider || {}) {
        //     this._refFloatSlider[streamChannel].setInitialStateChildren();
        //   }
        //   for (const streamChannel in this._refCountSlider || {}) {
        //     this._refCountSlider[streamChannel].setInitialStateChildren();
        //   }
        //   for (const streamChannel in this._refAtrSlider || {}) {
        //     this._refAtrSlider[streamChannel].setInitialStateChildren();
        //   }
        //   for (const streamChannel in this._refGapSlider || {}) {
        //     this._refGapSlider[streamChannel].setInitialStateChildren();
        //   }
        // }, 100);
      } catch {
        // Save current config
        const { config } = this.props;
        try {
          await API.updateSettingConfig(config);
        } catch (e) {}
      }
    } catch (e) {
      cogoToast.error(`Failed to get settings`);
    }
  };

  updateSettingConfig = async (config) => {
    try {
      const res = await API.updateSettingConfig(config);
      if (!res.success) {
        throw "error";
      }
    } catch (e) {
      cogoToast.error(`Failed to update setting changes`);
    }
  };

  storeConfigChanged = async (config) => {
    if (this.configPendingSaveTimeoutId) {
      clearTimeout(this.configPendingSaveTimeoutId);
    }
    this.configPendingSaveTimeoutId = setTimeout(async () => {
      this.configPendingSaveTimeoutId = null;
      this.updateSettingConfig(config);
    }, 2000);
  };

  onChangeAlert = (alert, value) => {
    const index = this.state.alerts.findIndex(({ id }) => {
      return id === value.id;
    });
    const alerts = [...this.state.alerts];
    alerts[index] = value;
    if (this.state.editingAlertId === alert.id) {
    } else if (this.state.editingAlertId == null) {
      this.setState({
        prevAlert: { ...alert },
        editingAlertId: alert.id,
      });
    } else {
      this.setState({
        prevAlert: { ...alert },
        editingAlertId: alert.id,
      });
      const prevAlertIndex = alerts.findIndex((item) => item.id === this.state.prevAlert.id);
      alerts[prevAlertIndex] = this.state.prevAlert;
    }
    this.setState({
      alerts,
    });
  };

  onClickAddAlert = (alertType) => {
    let initProgress = DEFAULT_ALERT_RATE[alertType] || 20;
    const currentAlert = {
      category: "",
      rate: initProgress,
    };
    this.setState({ alertType, currentAlert });
  };

  priceRangeFormatFrom = (value) => {
    if (value === "MIN") {
      return 0;
    } else if (value === "MAX") {
      return PRICE_MAX;
    } else {
      return value;
    }
  };

  priceRangeFormatTo = (value) => {
    if (value === 0) {
      return "MIN";
    } else if (value === PRICE_MAX) {
      return "MAX";
    } else {
      return parseInt(value);
    }
  };

  renderFilterOptions = () => {
    const optionsMode = this.props.config.optionsMode || DEFAULT_OPTIONS_MODE;

    const options = [
      {
        label: "Off",
        value: "Off",
      },
      {
        label: "Icon",
        value: "Icon",
      },
      {
        label: "Filter",
        value: "Filter",
      },
    ];

    return options.map(({ label, value }, index) => {
      return (
        <RadioButton
          key={`option-${index}`}
          label={label}
          checked={value == optionsMode}
          onClick={() => {
            this.props.updateOptionsModeFilter(value);
            this.setState({ optionsSaving: false }, () => {
              this.setState({ optionsSaving: true });
            });
          }}
        />
      );
    });
  };

  addNewVoiceItem = () => {
    const { createVoiceNotiItem } = this.props;
    let { voiceNoti } = this.props.config;
    if (!voiceNoti) {
      voiceNoti = [];
    }

    const usedZones = voiceNoti.map((item) => item.zone);
    const availableZones = Object.keys(VOICE_NOTI_LABEL).filter((item) => !usedZones.includes(item));
    const unsetZones = usedZones.filter((item) => !item);

    if (availableZones.length === 0 || unsetZones.length > 0) {
      return;
    }

    createVoiceNotiItem({ zone: availableZones[0] });
  };

  renderVoiceNotifications = () => {
    const { updateVoiceNoti, deleteVoiceNotiItem, isPro, isProOld, isProPlus } = this.props;
    let { voiceNoti } = this.props.config;
    if (!voiceNoti) {
      voiceNoti = [];
    }

    const applyChanges = (value) => {
      updateVoiceNoti(value);
      this.setState({ voiceNotiSaving: false }, () => {
        this.setState({ voiceNotiSaving: true });
      });
    };

    const usedZones = voiceNoti.map((item) => item.zone);
    const availableZones = Object.keys(VOICE_NOTI_LABEL).filter((item) => !usedZones.includes(item));

    return (
      <>
        <div className="w-100">
          {voiceNoti.map((zone, index) => {
            return (
              <div key={`voice-noti-${index}`} className="row mx-0 align-items-center item-content mt-1">
                <div className="col-6 col-sm-3">
                  <Dropdown
                    varaint="btn btn-outline-secondary"
                    style={
                      {
                        // border: "1px solid rgb(128, 128, 128)",
                        // borderRadius: 4,
                        // padding: 0
                      }
                    }
                  >
                    <Dropdown.Toggle className="industry_input" style={{}}>
                      <span className="small">{VOICE_NOTI_LABEL[zone.zone] || "Channel"}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {availableZones.map((value, i) => (
                        <Dropdown.Item
                          key={`voice-noti-zone-${i}`}
                          onClick={() => {
                            applyChanges({
                              index,
                              zone: value,
                            });
                          }}
                          disabled={!isProPlus && value === "filtered"}
                        >
                          <span className={`${!isProPlus && value === "filtered" ? "text-symbol" : ""}`}>
                            {VOICE_NOTI_LABEL[value]}
                          </span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-6 col-sm-3">
                  <Dropdown
                    varaint="btn btn-outline-secondary"
                    style={
                      {
                        // border: "1px solid rgb(128, 128, 128)",
                        // borderRadius: 4,
                        // padding: 0
                      }
                    }
                  >
                    <Dropdown.Toggle className="industry_input" style={{ width: 120 }}>
                      <span className="small">{this.mapVoiceNames(VOICE_NOTI_VOICE[zone["voice"]]) || "Voice"}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(VOICE_NOTI_VOICE).map((c, i) => (
                        <Dropdown.Item
                          key={`voice-noti-voice-${i}`}
                          onClick={() => {
                            applyChanges({
                              index,
                              voice: c,
                            });
                          }}
                        >
                          <span
                            style={{
                              color: `${zone["voice"] === c ? "#f6c548" : ""}`,
                            }}
                          >
                            {this.mapVoiceNames(VOICE_NOTI_VOICE[c])}
                          </span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-9 col-sm-4 d-flex flex-row flex-wrap align-items-center pl-4 pl-sm-1">
                  {["symbol", "name"].map((value, i) => {
                    return (
                      <div
                        key={`voice-noti-type-${i}`}
                        className={`custom-radio-wrapper ${zone["type"] == value ? "active" : ""}`}
                        style={{ width: "unset" }}
                        onClick={() => {
                          applyChanges({
                            index,
                            type: value,
                          });
                        }}
                      >
                        <span className="custom-radio-badge"></span>
                        <span className="custom-radio-label">{VOICE_NOTI_TYPE[value]}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="col-3 col-sm-2 d-flex justify-content-end align-items-center">
                  <button
                    className="bg-transparent border-0 delete-alert"
                    onClick={() => {
                      deleteVoiceNotiItem({ index });
                    }}
                  >
                    <i className="mdi mdi-close text-white popover-icon" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`w-100 text-center small ${voiceNoti.length > 0 ? "mt-2" : "mt-0"}`}>
          Note: Voice notifications require a modern processor for best experience
        </div>
      </>
    );
  };

  mapVoiceNames(voice) {
    return VOICE_NAME_MAP[voice] ?? voice;
  }

  cancelEditAlert = ({ id }) => {
    const index = this.state.alerts.findIndex((alert) => alert.id === id);
    const { prevAlert } = this.state;
    if (index > -1 && prevAlert && prevAlert.id === id) {
      // restore prev alert
      const alerts = [...this.state.alerts];
      alerts[index] = prevAlert;
      this.setState({
        prevAlert: null,
        alerts,
        editingAlertId: null,
      });
    }
  };

  onEditAlert = (alert) => {
    if (this.state.editingAlertId === alert.id) {
    } else if (this.state.editingAlertId == null) {
      this.setState({
        prevAlert: { ...alert },
        editingAlertId: alert.id,
      });
    } else {
      // editing alert is ignored
      // save ?
      // const prevAlert = this.state.alerts.find(({id}) => (this.state.editingAlertId === id))
      // if (prevAlert) {
      //   this.updateAlert(prevAlert)
      // }
      this.cancelEditAlert(this.state.prevAlert);
      this.setState({
        prevAlert: { ...alert },
        editingAlertId: alert.id,
      });
    }
  };

  updateAlert = async (alert) => {
    try {
      const result = await API.updateAlert(alert.id, {
        category: alert.category,
        rate: alert.rate,
      });
      if (result && result.success) {
        cogoToast.success(
          <div>
            Alert setting updated for <strong>{alert.category}</strong>
          </div>
        );
        this.setState({
          alerts: result.data.list,
          prevAlert: null,
          editingAlertId: null,
        });
      } else {
        throw result;
      }
    } catch (e) {
      cogoToast.error(`Failed to update the alert setting for ${alert.category}`);
    }
  };

  updateHaltAlert = async (type) => {
    if (type === "enabled" && this.haltAlertEnabled()) {
      return;
    }
    if (type === "disabled" && !this.haltAlertEnabled()) {
      return;
    }

    try {
      const result = await API.updateHaltAlert(type);
      if (result && result.success) {
        cogoToast.success(
          <div>
            Halt alert setting <strong>{type}</strong>
          </div>
        );
      } else {
        throw result;
      }
      if (type === "disabled") {
        this.setState({
          alerts: [...this.state.alerts.filter((item) => item.type !== "halt")],
        });
      } else {
        this.setState({
          alerts: [...this.state.alerts, result.data],
        });
      }
    } catch (e) {
      cogoToast.error(`Failed to update the halt alert setting for ${type}`);
    }
  };

  deleteAlert = async (alert) => {
    try {
      const result = await API.deleteAlert(alert.id);
      if (result && result.success) {
        this.setState({
          alerts: result.data, // result.data is the remaining alert settings
        });
      } else {
        throw result;
      }
    } catch (e) {
      cogoToast.error(`Failed to delete the alert setting for ${alert.category}`);
    }
  };

  registerAlert = async (type) => {
    const { currentAlert } = this.state;
    const symbol = currentAlert.category.toUpperCase();
    const rate = currentAlert.rate;

    const dic = {
      trade: "Trade",
      uv: "Unusual Volume",
      vwap: "VWap Dist",
      price: "Price",
    };
    try {
      const result = await API.addAlert({
        category: symbol,
        rate,
        high: 0,
        low: 0,
        type,
      });
      if (result && result.success) {
        cogoToast.success(
          <div>
            {dic[type]} alert added for <strong>{symbol}</strong>
          </div>
        );
        this.setState({
          alerts: result.data.list,
          alertType: null,
        });
      } else if (result && result.error) {
        throw result.error;
      }
    } catch (e) {
      if (e === "SequelizeUniqueConstraintError: Validation error") {
        cogoToast.error(`${dic[type]} alert for ${symbol} is already registered!`);
      } else {
        cogoToast.error(`Failed to register ${dic[type]} alert for ${symbol}`);
      }
    }
  };

  renderAlertInput = (type) => {
    return (
      this.state.alertType === type && (
        <AlertInput
          type={this.state.alertType}
          value={this.state.currentAlert}
          editing={true}
          onChange={(value) => {
            this.setState({
              currentAlert: value,
            });
          }}
          onDelete={() => {
            this.setState({
              alertType: null,
            });
          }}
          onSubmit={() => {
            this.registerAlert(type);
          }}
        />
      )
    );
  };

  haltAlertEnabled = () => {
    const { alerts } = this.state;
    return alerts.filter((item) => item.type === "halt").length > 0;
  };

  updateNewsHideRF(value) {
    this.props.updateNewsHideRF(value);
    this.setState({ newsSaving: false }, () => {
      this.setState({ newsSaving: true });
    });
  }

  updateHaltConfig(value) {
    this.props.updateHaltConfig(value);
    this.setState({ haltSaving: false }, () => {
      this.setState({ haltSaving: true });
    });
  }

  onFilteredAlertAdd = async (item) => {
    const { filtered_alerts } = this.state;
    const conflicts = filtered_alerts.filter((alert) => alert.name === item.name);
    if (conflicts.length > 0) {
      cogoToast.warn(`A filter already exists with the same name`);
      return;
    }

    const new_data = [...filtered_alerts, item];
    this.setState({
      showFilterModal: false,
    });
    try {
      const response = await API.updateSettingFilteredAlerts(new_data);
      if (!response || !response.success) {
        throw response.error || "err";
      }
      cogoToast.success(
        <div>
          Filtered alert setting added: <strong>{item.name}</strong>
        </div>
      );
      this.setState({
        filtered_alerts: new_data,
      });
    } catch (e) {
      if (e === "limit_exceeded") {
        cogoToast.error(
          <span>
            MOMO Pro+ is limited to <strong>5</strong> Filtered Alerts.
          </span>
        );
      } else {
        cogoToast.error(`Failed to add filtered alert`);
      }
    }
  };

  onFilteredAlertUpdate = async (origName, item) => {
    const { filtered_alerts } = this.state;
    const conflicts = filtered_alerts.filter((alert) => alert.name === item.name);
    if (origName !== item.name && conflicts.length > 0) {
      cogoToast.warn(`A filter already exists with the same name`);
      return;
    }

    const new_data = filtered_alerts.map((alert) => {
      if (alert.name === origName) {
        return item;
      }
      return alert;
    });
    this.setState({
      showFilterModal: false,
    });
    try {
      const response = await API.updateSettingFilteredAlerts(new_data);
      if (!response || !response.success) {
        throw response.error || "err";
      }
      cogoToast.success(
        <div>
          Filtered alert setting updated: <strong>{item.name}</strong>
        </div>
      );
      this.setState({
        filtered_alerts: new_data,
      });
    } catch (e) {
      if (e === "limit_exceeded") {
        cogoToast.error(
          <span>
            MOMO Pro+ is limited to <strong>5</strong> Filtered Alerts.
          </span>
        );
      } else {
        cogoToast.error(`Failed to update filtered alert`);
      }
    }
  };

  onFilteredAlertDelete = async (item) => {
    const { filtered_alerts } = this.state;
    const new_data = filtered_alerts.filter((alert) => alert.name !== item.name);
    this.setState({
      filtered_alerts: new_data,
    });
    try {
      const response = await API.updateSettingFilteredAlerts(new_data);
      if (!response || !response.success) {
        throw "err";
      }
      cogoToast.success(
        <div>
          Filtered alert setting deleted: <strong>{item.name}</strong>
        </div>
      );
    } catch (e) {
      cogoToast.error(`Failed to delete filtered alert`);
    }
  };

  onAlertWebhookSave = async ({ type, url }) => {
    const alert_webhook = {
      ...(this.state.alert_webhook || {}),
    };
    alert_webhook[type] = url;

    this.setState({
      showWebhookModal: false,
    });

    try {
      const response = await API.updateSettingAlertWebhook(alert_webhook);
      if (!response || !response.success) {
        throw "err";
      }
      cogoToast.success(
        <div>
          Alert webhook url updated: <strong>{ALERT_TYPE_TITLE[type]}</strong>
        </div>
      );
      this.setState({
        alert_webhook: {
          ...alert_webhook,
        },
      });
    } catch (e) {
      cogoToast.error(`Failed to update alert webhook url`);
    }
  };

  renderFilteredAlertModal = () => {
    const { showFilterModal, filterModalType, filterModalUpdateItem } = this.state;
    return (
      <ConditionalAlertModal
        open={showFilterModal}
        type={filterModalType}
        editItem={filterModalUpdateItem}
        onHide={() => {
          this.setState({
            showFilterModal: false,
          });
        }}
        onAdd={this.onFilteredAlertAdd.bind(this)}
        onUpdate={this.onFilteredAlertUpdate.bind(this)}
      />
    );
  };

  renderWebhookModal = () => {
    const { showWebhookModal, webhookType, alert_webhook } = this.state;
    return (
      <SettingsWebhookModal
        ref={(ref) => {
          this.ref_WebhookModal = ref;
        }}
        type={webhookType}
        url={alert_webhook[webhookType] || ""}
        open={showWebhookModal}
        onHide={() => {
          this.setState({
            showWebhookModal: false,
          });
        }}
        onSave={this.onAlertWebhookSave.bind(this)}
      />
    );
  };

  render() {
    const {
      match: {
        params: { tab: tabActiveKey },
      },
      history,
      isSmallScreen,
    } = this.props;
    const { stream, discovery } = this.props.config;
    let { voiceBuffer } = this.props.config;
    let news = this.props.config.news || DEFAULT_NEWS_CONFIG;
    let haltConfig = this.props.config.halt || DEFAULT_HALT_CONFIG;

    if (voiceBuffer == null) voiceBuffer = DEFAULT_VOICE_BUFFER;

    if (!tabActiveKey) {
      history.push("settings/general");
    }

    return (
      <div className="settings-content">
        <Tab.Container defaultActiveKey="general" activeKey={tabActiveKey}>
          <div className="tab-header-wrapper">
            {!isSmallScreen && (
              <div
                className={["mt-2", style.backButton].join(" ")}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                <div className="mdi mdi-chevron-left"></div>
                <div className={style.backText}>Back</div>
              </div>
            )}
            <div className="container-md px-0 mt-1 mt-md-3">
              <div className="tab-header d-flex align-items-center mt-2">
                <div
                  className={`tab-header-item ${tabActiveKey === "general" ? "active" : ""}`}
                  onClick={() => {
                    history.push("general");
                  }}
                >
                  <i className="mdi mdi-tune"></i>
                  <label className="ml-2 settings-label">General</label>
                </div>
                <div
                  className={`tab-header-item ${tabActiveKey === "stream" ? "active" : ""}`}
                  onClick={() => {
                    history.push("stream");
                  }}
                >
                  <i className="mdi mdi-pulse"></i>
                  <label className="ml-2 settings-label">Stream</label>
                </div>
                <div
                  className={`tab-header-item ${tabActiveKey === "discovery" ? "active" : ""}`}
                  onClick={() => {
                    history.push("discovery");
                  }}
                >
                  <i className="mdi mdi-table-search"></i>
                  <label className="ml-2 settings-label">Discovery</label>
                </div>
                <div
                  className={`tab-header-item ${tabActiveKey === "notifications" ? "active" : ""}`}
                  onClick={() => {
                    history.push("notifications");
                  }}
                >
                  <i className="mdi mdi-bell"></i>
                  <label className="ml-2 settings-label">Notifications</label>
                </div>
              </div>
            </div>
          </div>
          <Tab.Content className="py-1" style={{ border: "none" }}>
            <Tab.Pane eventKey="general">
              {/** General */}
              <div className="container-md discovery-column-sortable px-0 mt-2">
                <CollapsibleWrapper
                  title="Options"
                  labelClassName="setting-panel-label-1"
                  storeKey="options"
                  storeSubKey=""
                  triggerAnim={this.state.optionsSaving}
                >
                  <div className="mx-0 item-content px-4">
                    <div className={"d-flex justify-content-between align-items-center item-content mx-n4 pl-2 pt-1"}>
                      <span className="small">DISPLAY</span>
                    </div>
                    <div className="d-flex flex-row flex-wrap pb-2">{this.renderFilterOptions()}</div>
                  </div>
                </CollapsibleWrapper>

                <div className="mt-4"></div>
                <CollapsibleWrapper
                  title="News"
                  labelClassName="setting-panel-label-1"
                  storeKey="news"
                  storeSubKey=""
                  triggerAnim={this.state.newsSaving}
                >
                  <div className="mx-0 item-content px-4 mb-1">
                    <div className={"d-flex justify-content-between align-items-center item-content mx-n4 pl-2 py-1"}>
                      <span className="small">SEC FILINGS</span>
                      <div className={"d-flex justify-content-between align-items-center"}>
                        {!news.hide_rf ? (
                          <div
                            className={"btn-toggle-state active"}
                            onClick={() => {
                              this.updateNewsHideRF(true);
                            }}
                          >
                            Enabled
                          </div>
                        ) : (
                          <div
                            className={"btn-toggle-state"}
                            onClick={() => {
                              this.updateNewsHideRF(false);
                            }}
                          >
                            Disabled
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mx-0 item-content px-4">
                    <div className={"d-flex justify-content-between align-items-center item-content mx-n4 pl-2 py-1"}>
                      <span className="small">NEWS ICON</span>
                    </div>
                    <div className="d-flex pt-3 pb-2 align-items-baseline">
                      <div className="d-flex flex-column flex-fill mt-3">
                        <StepSlider
                          range={NEWS_RECENCY_RANGE}
                          value={parseFloat(news.recency)}
                          onChange={(value) => {
                            this.props.updateNewsRecency(parseFloat(value).toFixed(0));
                            this.setState({ newsSaving: false }, () => {
                              this.setState({ newsSaving: true });
                            });
                          }}
                          showTooltip={true}
                          tooltipFormat={"time"}
                        />
                        <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                          <div className="small">
                            MIN: 0 hrs = <b>disabled</b>
                          </div>
                          <div className="small">MAX: 5 days</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CollapsibleWrapper>

                <div className="mt-4"></div>
                <CollapsibleWrapper
                  title="Halt"
                  labelClassName="setting-panel-label-1"
                  storeKey="halt"
                  storeSubKey=""
                  triggerAnim={this.state.haltSaving}
                >
                  <div className="mx-0 item-content px-4 mb-1">
                    <div className={"d-flex justify-content-between align-items-center item-content mx-n4 pl-2 py-1"}>
                      <span className="small">
                        SYMBOL LENGTH FILTER
                        <HelpTooltip
                          title={"Symbol Filter Tooltip"}
                          placement={"bottom"}
                          content={
                            <span style={{ color: "white" }}>
                              This will ignore any Halts & Resumes in Halt Bar and Alerts if the symbol is more than 4
                              characters.
                            </span>
                          }
                        />
                      </span>
                      <div className={"d-flex justify-content-between align-items-center"}>
                        {haltConfig.symbolLenLimit ? (
                          <div
                            className={"btn-toggle-state active"}
                            onClick={() => {
                              this.updateHaltConfig({ symbolLenLimit: false });
                            }}
                          >
                            Enabled
                          </div>
                        ) : (
                          <div
                            className={"btn-toggle-state"}
                            onClick={() => {
                              this.updateHaltConfig({ symbolLenLimit: true });
                            }}
                          >
                            Disabled
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mx-0 item-content px-4">
                    <div className={"d-flex justify-content-between align-items-center item-content mx-n4 pl-2 py-1"}>
                      <span className="small">
                        TIME FILTER
                        <HelpTooltip
                          title={"Time Filter Tooltip"}
                          placement={"bottom"}
                          content={
                            <span style={{ color: "white" }}>
                              This will ignore any Halts in Halt Bar if halt time is more than specificed time.
                            </span>
                          }
                        />
                      </span>
                    </div>
                    <div className="d-flex pt-3 pb-2 align-items-baseline">
                      <div className="d-flex flex-column flex-fill mt-3">
                        <StepSlider
                          range={HALT_TIME_FILTER_RANGE}
                          value={parseFloat(haltConfig.durationLimit)}
                          onChange={(value) => {
                            this.updateHaltConfig({
                              durationLimit: parseFloat(value).toFixed(0),
                            });
                          }}
                          showTooltip={true}
                          tooltipFormat={"time"}
                        />
                        <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                          <div className="small">
                            MIN: 0 hrs = <b>disabled</b>
                          </div>
                          <div className="small">MAX: 7 days</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CollapsibleWrapper>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="stream">
              <div className="container-md mt-2 px-0">
                {(stream || DEFAULT_STREAM_SETTING_LIST).map((item) => (
                  <StreamSettings key={item.channel} setting={item} />
                ))}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="discovery">
              <div className="container-md discovery-column-sortable px-0 mt-2">
                {(discovery || DEFAULT_DISCOVERY_SETTING_LIST).map((item) => (
                  <DiscoverySettings key={item.id} setting={item} />
                ))}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="notifications">
              {/** Notifications */}
              <div className="container-md px-0 mt-2">
                <div className={"pb-3"}>
                  <CollapsibleWrapper
                    title="Voice"
                    labelClassName="setting-panel-label-1"
                    storeKey="voiceNoti"
                    storeSubKey=""
                    triggerAnim={this.state.voiceNotiSaving}
                  >
                    <div className="mx-0">
                      <div className="d-flex justify-content-end align-items-end align-items-sm-center">
                        <div className="pl-2" style={{ overflow: "hidden" }}>
                          {this.state.voiceSettingVisible && (
                            <CSSTransitionGroup
                              transitionAppear={true}
                              transitionAppearTimeout={1000}
                              transitionName="voice-setting-save"
                              transitionEnter={false}
                              transitionLeave={false}
                            >
                              <div
                                className="d-flex justify-content-between align-items-center mr-0 mr-sm-2"
                                style={{ minWidth: "220px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <span className="small mr-3">Buffer:</span>
                                <StepSlider
                                  range={VOICE_BUFFER_RANGE}
                                  value={parseFloat(voiceBuffer)}
                                  onChange={(value) => {
                                    this.props.updateVoiceBuffer(value);
                                    this.setState({ voiceNotiSaving: false }, () => {
                                      this.setState({
                                        voiceNotiSaving: true,
                                      });
                                    });
                                  }}
                                  showTooltip={false}
                                  tooltipFormat={"time"}
                                />
                                <span className="ml-2 text-left small" style={{ minWidth: "20px" }}>
                                  {voiceBuffer}s
                                </span>
                                <button
                                  className="bg-transparent border-0 delete-alert"
                                  onClick={() => {
                                    this.setState({
                                      voiceSettingVisible: false,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-close text-white popover-icon" />
                                </button>
                              </div>
                            </CSSTransitionGroup>
                          )}
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          {!this.state.voiceSettingVisible && (
                            <div
                              className="d-flex justify-content-between align-items-center mr-0 mr-sm-2"
                              style={{}}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <button
                                className="bg-transparent border-0 delete-alert"
                                onClick={() => {
                                  this.setState({
                                    voiceSettingVisible: true,
                                  });
                                }}
                              >
                                <i className="mdi mdi-settings text-white popover-icon" />
                              </button>
                            </div>
                          )}
                          <button
                            className={"btn bg-transparent border-0 px-0 small text-alert cursor-pointer text-alert"}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.addNewVoiceItem();
                            }}
                            disabled={false}
                          >
                            Add Voice
                          </button>
                        </div>
                      </div>
                      <div className="d-flex flex-row flex-wrap py-1">{this.renderVoiceNotifications()}</div>
                    </div>
                  </CollapsibleWrapper>
                </div>
              </div>
              <div className="container-md px-0 mt-4">
                <div className={"pb-3"}>
                  <CollapsibleWrapper
                    title="Conditional"
                    labelClassName="setting-panel-label-1"
                    storeKey="alert_filtered"
                    storeSubKey=""
                    dimmed={!this.props.isProPlus}
                    labelSuffix={
                      <>
                        <span className="text-symbol ml-2">(Max 5)</span>
                        <CodeButton
                          onClick={() => {
                            this.setState({
                              showWebhookModal: true,
                              webhookType: "conditional",
                            });
                          }}
                        />
                      </>
                    }
                  >
                    {this.props.isProPlus ? (
                      <>
                        <div className="d-flex flex-row justify-content-between align-items-center mx-0 symbol mt-1">
                          <label className="small text-symbol">Name</label>
                          <button
                            className={"btn bg-transparent border-0 px-0 small text-alert cursor-pointer text-alert"}
                            onClick={() => {
                              this.setState({
                                showFilterModal: true,
                                filterModalType: CONDITIONAL_ALERT_MODAL_TYPE_ADD,
                              });
                            }}
                          >
                            Add Alert
                          </button>
                        </div>
                        {this.state.filtered_alerts.map((item, index) => {
                          return (
                            <div
                              key={`filtered-alert-${index}`}
                              className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2"}
                            >
                              <span className="small">{item.name}</span>
                              <div className={""}>
                                <button
                                  className="bg-transparent border-0"
                                  onClick={() => {
                                    this.setState({
                                      showFilterModal: true,
                                      filterModalType: CONDITIONAL_ALERT_MODAL_TYPE_UPDATE,
                                      filterModalUpdateItem: item,
                                    });
                                  }}
                                >
                                  <i className="mdi popover-icon mdi-pencil text-light" />
                                </button>
                                <button
                                  className="bg-transparent border-0 delete-alert"
                                  onClick={() => {
                                    this.onFilteredAlertDelete(item);
                                  }}
                                >
                                  <i className="mdi mdi-close text-white popover-icon" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <UpgradeButton label="PRO + ONLY" />
                    )}
                  </CollapsibleWrapper>
                </div>
              </div>
              <div className="container-md px-0 mt-4">
                <div className={"pb-3"}>
                  <CollapsibleWrapper
                    title="Halts"
                    labelClassName="setting-panel-label-1"
                    storeKey="alert_halts"
                    storeSubKey=""
                    labelSuffix={
                      <>
                        <CodeButton
                          onClick={() => {
                            this.setState({
                              showWebhookModal: true,
                              webhookType: "halts",
                            });
                          }}
                        />
                      </>
                    }
                  >
                    <div className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2 py-1"}>
                      <span className="small">Global</span>
                      <div className={"d-flex justify-content-between align-items-center"}>
                        {this.haltAlertEnabled() ? (
                          <div
                            className={"btn-toggle-state active"}
                            onClick={() => {
                              this.updateHaltAlert("disabled");
                            }}
                          >
                            Enabled
                          </div>
                        ) : (
                          <div
                            className={"btn-toggle-state"}
                            onClick={() => {
                              this.updateHaltAlert("enabled");
                            }}
                          >
                            Disabled
                          </div>
                        )}
                      </div>
                    </div>
                  </CollapsibleWrapper>
                </div>
              </div>
              {alerts.map(({ type, valueLabel, label, webhook_type }, index) => {
                const disabled = !this.props.isPro && !this.props.isProPlus && index > 0;
                return (
                  <div className="container-md px-0 mt-4" key={type}>
                    <div className={"value-item" + (index === alerts.length - 1) ? " pb-3" : ""}>
                      <CollapsibleWrapper
                        title={label}
                        labelClassName="setting-panel-label-1"
                        storeKey={`alert_${type}`}
                        storeSubKey=""
                        dimmed={disabled}
                        labelSuffix={
                          <>
                            <CodeButton
                              onClick={() => {
                                this.setState({
                                  showWebhookModal: true,
                                  webhookType: webhook_type,
                                });
                              }}
                            />
                          </>
                        }
                      >
                        {!disabled ? (
                          <>
                            <div className="d-flex flex-row justify-content-between align-items-center mx-0 symbol mt-1">
                              <label className="small text-symbol">Symbol</label>
                              <label className="small text-symbol">{valueLabel}</label>
                              <button
                                className={
                                  "btn bg-transparent border-0 px-0 small text-alert cursor-pointer" +
                                  (disabled ? " text-muted" : " text-alert")
                                }
                                onClick={() => {
                                  this.onClickAddAlert(type);
                                }}
                                disabled={disabled}
                              >
                                Add Alert
                              </button>
                            </div>
                            {this.renderAlertInput(type)}
                            {this.getAlertsByType(type).map((alert) => {
                              return (
                                <AlertInput
                                  key={alert.id}
                                  value={alert}
                                  editing={this.state.editingAlertId === alert.id}
                                  type={type}
                                  onChange={(value) => {
                                    this.onChangeAlert(alert, value);
                                  }}
                                  onEdit={() => {
                                    this.onEditAlert(alert);
                                  }}
                                  onDelete={() => {
                                    if (this.state.editingAlertId === alert.id) {
                                      this.cancelEditAlert(alert);
                                    } else {
                                      this.deleteAlert(alert);
                                    }
                                  }}
                                  onSubmit={() => {
                                    this.updateAlert(alert);
                                  }}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <UpgradeButton label="PRO ONLY" />
                        )}
                      </CollapsibleWrapper>
                    </div>
                  </div>
                );
              })}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {this.renderFilteredAlertModal()}
        {this.renderWebhookModal()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  config: state.config,
  setting: state.setting,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) || isProNew(state.auth.user.subscription.plan)),
  isProOld: isActiveSubscription(state.auth.user.subscription) && isPro(state.auth.user.subscription.plan),
  isProPlus: isActiveSubscription(state.auth.user.subscription) && isProPlusNew(state.auth.user.subscription.plan),
});

const mapDispatchToProps = {
  updateOptionsModeFilter: ConfigActions.updateOptionsModeFilter,
  updateNewsIconVisible: ConfigActions.updateNewsIconVisible,
  updateNewsRecency: ConfigActions.updateNewsRecency,
  updateNewsHideRF: ConfigActions.updateNewsHideRF,
  updateVoiceNoti: ConfigActions.updateVoiceNoti,
  createVoiceNotiItem: ConfigActions.createVoiceNotiItem,
  deleteVoiceNotiItem: ConfigActions.deleteVoiceNotiItem,
  updateVoiceBuffer: ConfigActions.updateVoiceBuffer,
  updateHaltConfig: ConfigActions.updateHaltConfig,
  setConfig: ConfigActions.setConfig,
  setSectorSymbolsCnt: SettingActions.setSectorSymbolsCnt,
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withScreenSizes(Settings))));
