import React, { useMemo } from "react";
import styles from "./style.module.scss";

function NewStepSlider({ value, onChange, stepConfig }) {
  const fullMap = useMemo(() => {
    const map = [];
    for (const stepItem of stepConfig) {
      const { min, max, step } = stepItem;
      if (min > max || step <= 0 || step > max - min) {
        throw new Error("Invalid step config");
      }

      for (let i = min; i < max; i += step) {
        map.push(Math.round(i * 10) / 10);
      }
    }
    map.push(stepConfig[stepConfig.length - 1].max);
    return map;
  }, [stepConfig]);

  const valueToIndex = (value) => {
    const index = fullMap.indexOf(value);
    if (index >= 0) {
      return index;
    }

    for (let i = 0; i < fullMap.length; i++) {
      if (value >= fullMap[i]) {
        return i;
      }
    }
    return fullMap.length - 1;
  };

  const handleSliderChange = (e) => {
    onChange(fullMap[Number(e.target.value)]);
  };

  return (
    <input
      type="range"
      className={styles.slider}
      min={0}
      max={fullMap.length - 1}
      step={1}
      value={valueToIndex(value)}
      onChange={handleSliderChange}
    />
  );
}

export default NewStepSlider;
