import { createActions, createReducer } from "reduxsauce";
import { FLOW_PRICE_RANGE } from "../constants";

const defaultState = {
  buffer: [],
  bufferSize: 50,
  isPlaying: true,
  timeframe: "1min",
  filter: {
    vol: {
      "1d": 100000,
      "5m": 15000,
      "1m": 3000,
    },
    price: {
      min: FLOW_PRICE_RANGE.min,
      max: FLOW_PRICE_RANGE.max,
    },
  },
};

export const { Types, Creators } = createActions({
  addToFlow: ["value"],
  playPause: ["value"],
  updateTimeframe: ["value"],
  updateMinVolume: ["value"],
  updatePriceRange: ["value"],
  updateBufferSize: ["value"],
});

const updateBufferSize = (state, { value }) => {
  return {
    ...state,
    bufferSize: value,
  };
};

const addToFlow = (state, { value }) => {
  const { symbol, ratio } = value;
  let newBuffer = [
    ...state.buffer,
    {
      symbol,
      ratio,
      timestamp: new Date().getTime(),
    },
  ];
  if (newBuffer.length > state.bufferSize) {
    newBuffer = newBuffer.slice(newBuffer.length - state.bufferSize);
  }
  return {
    ...state,
    buffer: newBuffer,
  };
};

const playPause = (state, { value }) => {
  return {
    ...state,
    isPlaying: !!value,
  };
};

const updateTimeframe = (state, { value }) => {
  return {
    ...state,
    timeframe: value,
  };
};

const updateMinVolume = (state, { value }) => {
  const { tf, volume } = value;
  return {
    ...state,
    filter: {
      ...state.filter,
      vol: {
        ...state.filter.vol,
        [tf]: volume,
      },
    },
  };
};

const updatePriceRange = (state, { value }) => {
  const { min, max } = value;

  const newMin = min !== undefined ? min : state.filter.price.min;
  const newMax = max !== undefined ? max : state.filter.price.max;
  if (newMin > newMax) {
    return state;
  }
  if (newMin === newMax) {
    return state;
  }

  return {
    ...state,
    filter: {
      ...state.filter,
      price: {
        ...state.filter.price,
        min: newMin,
        max: newMax,
      },
    },
  };
};

export const FlowTypes = Types;

export const flowReducer = createReducer(defaultState, {
  [Types.ADD_TO_FLOW]: addToFlow,
  [Types.PLAY_PAUSE]: playPause,
  [Types.UPDATE_TIMEFRAME]: updateTimeframe,
  [Types.UPDATE_MIN_VOLUME]: updateMinVolume,
  [Types.UPDATE_PRICE_RANGE]: updatePriceRange,
  [Types.UPDATE_BUFFER_SIZE]: updateBufferSize,
});

export default Creators;
