import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import DiscoveryLockedFieldMark from "../../../shared/DiscoveryLockedFieldMark";

import { APP_COLORS, TIMEFRAME_TRANSFORM_MAP } from "../../../constants";
import { decodeDiscoveryFieldForTimeframe } from "../../../util";

import styles from "./style.module.scss";

const ColumnDropdown = ({
  selectedColumn,
  columns,
  onItemClick,
  columnTitles,
  disabledColumns,
  inactiveColumns = [],
  inactiveTooltipContent,
  allowInactiveSelection,
}) => {
  const [vibrateList, setVibrateList] = useState({});

  const selectedColumnInfo = decodeDiscoveryFieldForTimeframe(selectedColumn);

  return (
    <Dropdown
      // className="btn btn-outline-secondary"
      style={{
        border: "1px solid rgb(128, 128, 128)",
        borderRadius: 4,
        padding: 2,
      }}
    >
      <Dropdown.Toggle
        className={`industry_input ${selectedColumnInfo.timeframe ? styles["dropdown-compact"] : ""}`}
        id="dropdown-basic"
        style={{ width: 120 }}
      >
        {columnTitles[selectedColumnInfo.column] || "Column"}
        {selectedColumnInfo.timeframe ? (
          <span className={styles["timeframe"]}>({TIMEFRAME_TRANSFORM_MAP[selectedColumnInfo.timeframe]})</span>
        ) : (
          ""
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-2-columns">
        <Tooltip id="column-disabled-tooltip" place="top-start" className="app-ui-hover-tooltip" clickable>
          {inactiveTooltipContent}
        </Tooltip>
        <Tooltip id="column-upgrade-tooltip" place="top-start" className="app-ui-hover-tooltip" />
        {columns.map((c, i) => {
          const columnInfo = decodeDiscoveryFieldForTimeframe(c);

          let planDisabled = Object.keys(disabledColumns).find((k) => k === columnInfo.column);
          planDisabled = planDisabled && disabledColumns[planDisabled];

          const columnInactive = !planDisabled && inactiveColumns.includes(c);

          return (
            <Dropdown.Item
              key={`filter-column-value-${i}`}
              onClick={(e) => {
                if ((!columnInactive || allowInactiveSelection) && !planDisabled) onItemClick(c);
              }}
              style={{ paddingLeft: "30px", paddingRight: "20px" }}
            >
              <div
                className={"d-flex justify-content-start position-relative " + (vibrateList[c] ? styles.vibrate : "")}
                onClick={(e) => {
                  if ((columnInactive && !allowInactiveSelection) || planDisabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    setVibrateList((vibrateList) => {
                      return {
                        ...vibrateList,
                        [c]: !vibrateList[c],
                      };
                    });
                    setTimeout(() => {
                      setVibrateList((vibrateList) => {
                        return {
                          ...vibrateList,
                          [c]: false,
                        };
                      });
                    }, 2000);
                  }
                }}
                {...(planDisabled && {
                  "data-tooltip-id": "column-upgrade-tooltip",
                  "data-tooltip-content": `Upgrade to ${planDisabled} to access this column`,
                })}
              >
                <div
                  style={{ left: "-22px" }}
                  className="d-flex h-100 align-items-center position-absolute"
                  {...(columnInactive && { "data-tooltip-id": "column-disabled-tooltip" })}
                >
                  {columnInactive && !planDisabled && (
                    <svg width="17px" height="17px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2 2L22 22"
                        stroke={APP_COLORS.grayText}
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                        stroke={APP_COLORS.grayText}
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                        stroke={APP_COLORS.grayText}
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <span
                  className={`d-flex align-items-center ${planDisabled ? "text-symbol" : ""}`}
                  style={{ ...(columnInactive && !allowInactiveSelection ? { color: APP_COLORS.grayText } : {}) }}
                  {...(columnInactive && !allowInactiveSelection && { "data-tooltip-id": "column-disabled-tooltip" })}
                >
                  <span>{columnTitles[columnInfo.column] ?? columnInfo.column}</span>
                  {columnInfo.timeframe && (
                    <DiscoveryLockedFieldMark
                      timeframe={columnInfo.timeframe}
                      disabled={planDisabled}
                      palette={"light"}
                    />
                  )}
                </span>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ColumnDropdown.propTypes = {
  selectedColumn: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledColumns: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  columnTitles: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ColumnDropdown;
