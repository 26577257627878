import React from "react";
import PropTypes from "prop-types";
import { DEFAULT_TIMEFRAME, TIMEFRAME_FILTER } from "../../constants";
import { Dropdown } from "react-bootstrap";

function TimeframeSelector({ timeframe, setTimeframe, hideIcon }) {
  if (!timeframe) timeframe = DEFAULT_TIMEFRAME;

  return (
    <Dropdown varaint="btn btn-outline-secondary">
      <Dropdown.Toggle className="industry_input">
        {!hideIcon && <i className="fa fa-clock-o" style={{ color: "#f6c548" }} />}
        <span className="">
          {((TIMEFRAME_FILTER[timeframe] ? timeframe : "1day") + "").replace(/(\d)([^\d\s])/g, "$1 $2")}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-2-columns timeframe-dropdown-menu">
        {[...Object.keys(TIMEFRAME_FILTER)].map((key) => {
          return (
            <Dropdown.Item
              key={key}
              onClick={() => {
                setTimeframe(key);
                // props.updateDiscoveryTimeframe({
                //   widget,
                //   timeframe: key,
                // });
              }}
              tabIndex="1"
            >
              <span
                style={{
                  color: `${key === timeframe ? "#f6c548" : ""}`,
                }}
              >
                {TIMEFRAME_FILTER[key]}
              </span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

TimeframeSelector.propTypes = {
  timeframe: PropTypes.string,
  setTimeframe: PropTypes.func,
};

export default TimeframeSelector;
