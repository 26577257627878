import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import NumAbbr from "number-abbreviate";

import RadioButton, {
  ButtonLabel as RadioButtonLabel,
  ButtonWrapper as RadioButtonWrapper,
} from "../../shared/Button/RadioButton";
import StepSlider from "../../shared/StepRangeSlider/StepSlider";

import { Types as FlowActionTypes } from "../flowReducer";

import { FLOW_MINUTE_VOLUME_RANGE, FLOW_TIMEFRAME_FILTER, VOLUME_RANGE, FLOW_PRICE_STEP } from "../../constants";
import styles from "./style.module.scss";
import _ from "lodash";
import NewStepSlider from "../../shared/NewStepSlider";

const numAbbr = new NumAbbr(["k", "m"], 2);

const PopoverTriggerButton = styled.button`
  font-size: 20px;
  color: white;
  border: 0;
`;

const PopoverContentTimeframeOptionsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;

  ${RadioButtonLabel} {
    color: white;
    margin-left: 0.25rem;
  }

  ${RadioButtonWrapper} {
    margin-left: 0.5em;
    :first-child {
      margin-left: 0;
    }
    margin-top: 0;
  }
`;

const PopoverContentVolumeRow = styled.div`
  width: 255px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 85px 1fr;
  gap: 15px;

  margin-top: 12px;
`;

const PopoverContentVolumeText = styled.span`
  font-size: 14px;
  color: white;
  display: block;
  text-align: right;
`;

const PopoverContentTimeframeRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 85px 1fr;
  gap: 14px;
`;

const PopoverContentTimeframeText = styled(PopoverContentVolumeText)``;

const PopoverContentSliderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .StepRangeSlider__tooltip {
    display: none;
  }
`;

const PopoverContentVolumeLabel = styled.span`
  font-size: 12px;
  color: white;
  text-align: left;
  min-width: 45px;
  margin-left: 8px;
  display: block;
  white-space: nowrap;
`;

const minVolFilters = [
  {
    storeKey: "1m",
    label: "1min",
    range: FLOW_MINUTE_VOLUME_RANGE,
  },
  {
    storeKey: "5m",
    label: "5min",
    range: FLOW_MINUTE_VOLUME_RANGE,
  },
  {
    storeKey: "1d",
    label: "daily",
    range: VOLUME_RANGE,
  },
];

function FlowSettingPopover(props) {
  const dispatch = useDispatch();
  const [localPriceRange, setLocalPriceRange] = useState({ min: 0, max: 0 });

  const debouncedDispatch = useCallback(
    _.debounce((value) => {
      dispatch({
        type: FlowActionTypes.UPDATE_PRICE_RANGE,
        value,
      });
    }, 300),
    []
  );

  const {
    timeframe,
    filter: { vol, price },
  } = useSelector((state) => state.flow);

  useEffect(() => {
    if (price.min !== localPriceRange.min || price.max !== localPriceRange.max) {
      setLocalPriceRange({ min: price.min, max: price.max });
    }
  }, [price]);

  const popover = (
    <Popover style={{ borderRadius: 12 }}>
      <Popover.Content style={{ minWidth: 160, borderRadius: 12 }}>
        <PopoverContentTimeframeRow>
          <PopoverContentTimeframeText>Display</PopoverContentTimeframeText>
          <PopoverContentTimeframeOptionsWrapper>
            {Object.keys(FLOW_TIMEFRAME_FILTER).map((item) => (
              <RadioButton
                key={`timeframe-${item}`}
                label={FLOW_TIMEFRAME_FILTER[item]}
                checked={item == timeframe}
                onClick={() => {
                  dispatch({
                    type: FlowActionTypes.UPDATE_TIMEFRAME,
                    value: item,
                  });
                }}
              />
            ))}
          </PopoverContentTimeframeOptionsWrapper>
        </PopoverContentTimeframeRow>
        {minVolFilters.map(({ storeKey, label, range }) => (
          <PopoverContentVolumeRow key={storeKey}>
            <PopoverContentVolumeText>{label} min vol</PopoverContentVolumeText>
            <PopoverContentSliderWrapper>
              <StepSlider
                className={styles.stepSlider}
                range={range}
                value={parseFloat(vol[storeKey])}
                onChange={(value) => {
                  dispatch({
                    type: FlowActionTypes.UPDATE_MIN_VOLUME,
                    value: {
                      tf: storeKey,
                      volume: value,
                    },
                  });
                }}
                showTooltip={false}
              />
              <PopoverContentVolumeLabel>{numAbbr.abbreviate(vol[storeKey], 2)}</PopoverContentVolumeLabel>
            </PopoverContentSliderWrapper>
          </PopoverContentVolumeRow>
        ))}
        <PopoverContentVolumeRow>
          <PopoverContentVolumeText>min price</PopoverContentVolumeText>
          <PopoverContentSliderWrapper>
            <NewStepSlider
              value={localPriceRange.min}
              stepConfig={FLOW_PRICE_STEP}
              onChange={(value) => {
                if (value >= price.max) return;

                const newRange = { ...localPriceRange, min: value };
                setLocalPriceRange(newRange);
                debouncedDispatch(newRange);
              }}
            />
            <PopoverContentVolumeLabel>{numAbbr.abbreviate(localPriceRange.min, 2)}</PopoverContentVolumeLabel>
          </PopoverContentSliderWrapper>
        </PopoverContentVolumeRow>

        <PopoverContentVolumeRow>
          <PopoverContentVolumeText>max price</PopoverContentVolumeText>
          <PopoverContentSliderWrapper>
            <NewStepSlider
              value={Math.min(localPriceRange.max, FLOW_PRICE_STEP[FLOW_PRICE_STEP.length - 1].max)}
              stepConfig={FLOW_PRICE_STEP}
              onChange={(value) => {
                if (value <= price.min) return;

                if (value === FLOW_PRICE_STEP[FLOW_PRICE_STEP.length - 1].max) {
                  value = Infinity;
                }

                const newRange = { ...localPriceRange, max: value };
                setLocalPriceRange(newRange);
                debouncedDispatch(newRange);
              }}
            />
            <PopoverContentVolumeLabel>
              {localPriceRange.max === Infinity ? "MAX" : numAbbr.abbreviate(localPriceRange.max, 2)}
            </PopoverContentVolumeLabel>
          </PopoverContentSliderWrapper>
        </PopoverContentVolumeRow>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover}>
      <PopoverTriggerButton className="bg-transparent" onClick={() => {}}>
        <i className="mdi mdi-settings" />
      </PopoverTriggerButton>
    </OverlayTrigger>
  );
}

export default FlowSettingPopover;
