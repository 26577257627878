/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */

import { applyMiddleware, compose, createStore as createReduxStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import { createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import makeRootReducer from "./reducers";
import quotesSaga from "../quotes/quotesSagas";
import optionsSaga from "./optionsSaga";
import newsSaga from "../news/newsSagas";
import chatSaga from "../chat/chatSaga";
import adminSaga from "../user-pages/account/admin/adminSaga";
import stocknamesSaga from "./stocknamesSaga";
import miscSaga from "./miscSaga";
import { DEFAULT_DISCOVERY_SECTOR, DEFAULT_SEARCH_DROPDOWN, DEFAULT_SYMBOL_POPUP, SECTORS_FILTER } from "../constants";
import { complementConfig, complementDiscovery, complementFlow, complementLayout } from "../util";

const initialState = window.__INITIAL_STATE__;

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    if (key === "discovery") {
      return {
        ...inboundState,
        viewportSymbols: {
          discovery1: [],
          discovery2: [],
        },
      };
    } else if (key === "news") {
      return { ...inboundState, data: [] };
    } else if (key === "options") {
      return { ...inboundState, options: [] };
    } else if (key === "stocknames") {
      return { ...inboundState, data: {} };
    } else if (key === "misc") {
      return { ...inboundState, usernames: [] };
    } else if (key === "chat") {
      return {
        ...inboundState,
        channels: (inboundState.channels || []).map((item) => ({ ...item, last_read: null })),
        messages: [],
        replyMsg: null,
        typingInfo: null,
        loadingMessage: false,
        channelReachedEnd: {
          top: false,
          bottom: false,
        },
        input: "",
        searchInput: "",
      };
    } else if (key === "flow") {
      return { ...inboundState, buffer: [], isPlaying: true };
    } else if (key === "admin") {
      return { ...inboundState, data: [] };
    } else if (key === "dashboard") {
      return {
        ...inboundState,
        isPagePopout: false,
        isPageFlow: false,
        isPopoutBlocked: false,
        layoutRestored: false,
        alerts: [],
        alertDetailItem: null,
      };
    }
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === "discovery") {
      outboundState = complementDiscovery(outboundState);
    } else if (key === "dashboard") {
      if (outboundState.maximisedView === "stream") {
        outboundState.maximisedView === "stream1";
      }
      outboundState.layoutForceUpdate = 0;
      outboundState.symbolPopup = DEFAULT_SYMBOL_POPUP;
      outboundState.searchDropdown = DEFAULT_SEARCH_DROPDOWN;
      outboundState.layout = complementLayout(outboundState.layout);
    } else if (key === "config") {
      outboundState = complementConfig(outboundState);
    } else if (key === "flow") {
      outboundState = complementFlow(outboundState);
    }

    // convert mySet back to a Set.
    return { ...outboundState };
  },
  // define which reducers this transform gets called for.
  {
    whitelist: ["dashboard", "discovery", "news", "options", "stocknames", "misc", "chat", "flow", "admin", "config"],
  }
);

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["ui"],
  transforms: [SetTransform],
};

const persistedReducer = persistReducer(persistConfig, makeRootReducer());

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

// ======================================================
// Store Enhancers
// ======================================================
const enhancers = [];
// ======================================================
// Store Instantiation and HMR Setup
// ======================================================

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createReduxStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers)
);

sagaMiddleware.run(quotesSaga);
sagaMiddleware.run(optionsSaga);
sagaMiddleware.run(newsSaga);
sagaMiddleware.run(chatSaga);
sagaMiddleware.run(adminSaga);
sagaMiddleware.run(stocknamesSaga);
sagaMiddleware.run(miscSaga);

// let prevConfig = store.getState().config;
// store.subscribe(function () {
//   const currentConfig = store.getState().config
//   if (currentConfig != prevConfig) {
//     console.log(currentConfig);
//   }
//   prevConfig = currentConfig;
// })

if (module.hot) {
  module.hot.accept("./reducers", () => store.replaceReducer(require("./reducers")));
}
const persistor = persistStore(store, null, null);

export { store, persistor };
