import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import _ from "lodash";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

import { ChatActions } from "../../store";

import API from "../../api";

import MsgBalloon from "./MsgBalloon";
import MsgDateLabel from "./MsgDateLabel";

import { APP_COLORS } from "../../constants";

import styles from "./index.module.scss";
import "../style.scss";

const reachOffset = 20;

function MessageList({ disableActions, onReachEnd, onOpenImageViewer, loadLatest, scrollBottomReachedForward }) {
  const _scrollRef = React.useRef();
  const _scrollPos = React.useRef();
  const _channelDataLoaded = React.useRef(false);
  const _messagesPrevLength = React.useRef(0);
  const _messageFirstItemId = React.useRef();

  const _lastReach = React.useRef({});
  const _edgeCausedLoad = React.useRef(false);
  const updateLastRead = React.useRef(() => {});
  const firstUnreadMessage = React.useRef(null);
  const _messages = React.useRef([]);

  const { user: auth_user } = useSelector((state) => state.auth);
  const { channelSelected, messages, loadingMessage, channelReachedEnd, channels } = useSelector((state) => state.chat);
  const currentUsername = useSelector((state) => state.auth.user.username);
  const [intersectObserver, setIntersectObserver] = React.useState(null);
  const [showLatestButton, setShowLatestButton] = React.useState(true);
  const [currentChannel, setCurrentChannel] = React.useState(null);

  const dispatch = useDispatch();

  _messages.current = messages;

  React.useEffect(() => {
    _channelDataLoaded.current = false;
    _scrollPos.current = null;
    _messageFirstItemId.current = null;
    _messagesPrevLength.current = 0;
    _lastReach.current = {};
    _edgeCausedLoad.current = false;
    firstUnreadMessage.current = null;
    _messages.current = [];
    setShowLatestButton(true);
  }, [channelSelected]);

  React.useEffect(() => {
    if (channelReachedEnd?.bottom) {
      firstUnreadMessage.current = null;
    }
  }, [channelReachedEnd.bottom]);

  React.useEffect(() => {
    const newCurrentChannel = channels.find((chan) => chan.id === channelSelected);
    if (!firstUnreadMessage.current && newCurrentChannel?.last_read?.msg_id) {
      firstUnreadMessage.current = newCurrentChannel.last_read.msg_id;
    }

    if (newCurrentChannel) {
      setCurrentChannel(newCurrentChannel);
    }

    updateLastRead.current = _.debounce(async (msg_id) => {
      try {
        const response = await API.setChatLastRead(channelSelected, msg_id);
        if (response.last_read.count !== undefined) {
          dispatch(
            ChatActions.fetchChannelsSucceed(
              channels.map((channel) =>
                channel.id === channelSelected ? { ...channel, last_read: response.last_read } : channel
              )
            )
          );

          if (_messages.current.length && _messages.current[_messages.current.length - 1]?.id === msg_id) {
            if (!scrollBottomReachedForward.current) {
              firstUnreadMessage.current = msg_id;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);

    return () => {
      updateLastRead.current.cancel();
    };
  }, [channels, channelSelected]);

  React.useLayoutEffect(() => {
    if (_scrollRef.current) {
      if (!_channelDataLoaded.current && messages[0]) {
        // _scrollRef.current.scrollToBottom();
        // Doubt: why is this?. I suppose this is to prevent loading new messages right after channel load
        const { clientHeight, scrollHeight } = _scrollRef.current.getValues();
        _scrollRef.current.scrollTop(scrollHeight - clientHeight - reachOffset);
      }
      if (_channelDataLoaded.current && _scrollPos.current) {
        const values = _scrollRef.current.getValues();
        const prevOffsetBottom = _scrollPos.current.scrollHeight - _scrollPos.current.scrollTop;
        const newOffsetTop = values.scrollHeight - prevOffsetBottom;
        _scrollRef.current.scrollTop(newOffsetTop);
      }
    }
    if (messages[0]) {
      _channelDataLoaded.current = true;
    }
  }, [messages[0]]);

  React.useLayoutEffect(() => {
    if (Array.isArray(messages) && messages.length) {
      if (messages.length > _messagesPrevLength.current) {
        if (messages[messages.length - 1]?.user?.id === auth_user?.id && channelReachedEnd.bottom) {
          _edgeCausedLoad.current = false;
        }
      }
    }

    if (Array.isArray(messages)) {
      if (
        messages.length > _messagesPrevLength.current &&
        _messagesPrevLength.current &&
        _messageFirstItemId.current === messages[0].id &&
        _scrollRef.current &&
        _scrollPos.current
      ) {
        let shouldScrollToBottom;
        if (!_edgeCausedLoad.current) {
          if (messages[messages.length - 1]?.user?.id === auth_user?.id) {
            shouldScrollToBottom = true;
          } else {
            const offsetBottom =
              _scrollPos.current.scrollHeight - _scrollPos.current.clientHeight - _scrollPos.current.scrollTop;
            if (offsetBottom < 120) {
              shouldScrollToBottom = true;
            }
          }
        }
        if (shouldScrollToBottom) {
          _scrollRef.current.scrollToBottom();
          _scrollRef.current.scrollTop(_scrollRef.current.getScrollTop() - reachOffset);
        }
      }
    }
    _messagesPrevLength.current = messages?.length;
    _messageFirstItemId.current = messages[0]?.id;
  }, [messages, auth_user]);

  useEffect(() => {
    if (!loadingMessage) {
      _edgeCausedLoad.current = false;
    }
  }, [loadingMessage]);

  useEffect(() => {
    if (!_scrollRef.current?.view) return;

    const newObserver = new IntersectionObserver(
      (entries) => {
        if (!entries.length) return;
        entries = entries.filter((entry) => entry.isIntersecting);
        if (!entries.length) return;

        let entry = entries.find((entry) => entry.intersectionRect.bottom === entry.rootBounds.bottom);
        if (!entry && entries.length > 1) {
          entry = entries.sort((a, b) => b.intersectionRect.bottom - a.intersectionRect.bottom)[0];
        }
        if (!entry) return;

        const msg_id = entry.target.getAttribute("data-message-id");
        updateLastRead.current(msg_id);
      },
      {
        root: _scrollRef.current.view,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    setIntersectObserver(newObserver);

    return () => {
      newObserver.disconnect();
    };
  }, [_scrollRef.current?.view]);

  const onScrollFrame = (ev) => {
    if (_channelDataLoaded.current) {
      const topReached = ev.top === 0;
      const bottomReached = ev.scrollHeight - (ev.scrollTop + ev.clientHeight) < reachOffset / 2;

      if (!scrollBottomReachedForward?.current !== undefined) {
        const reached = ev.scrollHeight - (ev.scrollTop + ev.clientHeight) < reachOffset;

        scrollBottomReachedForward.current = reached;
      }

      if (topReached || bottomReached) {
        if (Array.isArray(messages) && messages.length) {
          if (topReached) {
            onReachEnd && onReachEnd(messages[0]?.created_at);
          }
          if (bottomReached && !_lastReach.current.bottom && !channelReachedEnd.bottom) {
            onReachEnd && onReachEnd(messages[messages.length - 1]?.created_at, true);
            _edgeCausedLoad.current = true;
          }
        }
      }
      if (bottomReached) {
        _.set(_lastReach.current, "bottom", true);

        if (channelReachedEnd?.bottom && currentChannel?.last_read?.count !== 0) {
          dispatch(
            ChatActions.fetchChannelsSucceed(
              channels.map((channel) =>
                channel.id === channelSelected ? { ...channel, last_read: { ...channel.last_read, count: 0 } } : channel
              )
            )
          );
        }
      } else {
        _.set(_lastReach.current, "bottom", false);
      }
      _scrollPos.current = _scrollRef.current.getValues();
    }
  };

  const groupMessages = () => {
    const groups = [];
    let curGroupMsgs = [];
    let curDateStr = null;
    for (const item of messages) {
      const dateStr = moment(item.created_at).format("dddd, MMM Do");
      if (curDateStr != dateStr) {
        if (curGroupMsgs.length) {
          groups.push({
            dateStr: curDateStr,
            messages: curGroupMsgs,
          });
        }
        curDateStr = dateStr;
        curGroupMsgs = [];
      }
      curGroupMsgs.push(item);
    }
    if (curGroupMsgs.length) {
      if (curDateStr === moment().format("dddd, MMM Do")) {
        curDateStr = "Today";
      }
      groups.push({
        dateStr: curDateStr,
        messages: curGroupMsgs,
      });
    }
    return groups;
  };

  return (
    <div className={`${styles["message-list"]}`}>
      <Scrollbars
        ref={_scrollRef}
        autoHide
        style={{
          width: "100%",
          height: "calc(100% - 5px)",
        }}
        renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
        onScrollFrame={onScrollFrame}
      >
        {loadingMessage && <div className={styles["loading-history"]}>loading history...</div>}
        {!loadingMessage && channelReachedEnd?.top && <div className={styles["reached-end"]}>Reached the End</div>}
        {groupMessages().map(({ dateStr, messages }) => (
          <div key={dateStr}>
            <MsgDateLabel date={dateStr} />
            {messages
              .filter((item) => {
                //TODO: Temporary Frontend based DM display restriction
                const msgData = getMsgData(item.content);
                if (msgData?.from && msgData?.to) {
                  return currentUsername === msgData.from || currentUsername === msgData.to;
                }
                return true;
              })
              .map(({ id: chatId, ...item }, index) => (
                <>
                  <MsgBalloon
                    key={index}
                    {...item}
                    chatId={chatId}
                    disableActions={disableActions}
                    onOpenImageViewer={onOpenImageViewer}
                    observer={intersectObserver}
                  />
                  {firstUnreadMessage.current === chatId &&
                    index !== messages.length - 1 &&
                    !!currentChannel?.last_read?.count && (
                      <MsgDateLabel
                        key={index + "unread"}
                        date={"Unread"}
                        textStyle={{
                          color: APP_COLORS.active,
                          border: "none",
                        }}
                      />
                    )}
                </>
              ))}
          </div>
        ))}
        {!!loadingMessage && !!messages.length && (
          <div className={styles["loading-history-bottom"]}>
            <div className={styles.loadingText}>loading newer...</div>
          </div>
        )}
      </Scrollbars>

      {showLatestButton &&
        !channelReachedEnd?.bottom &&
        !!messages.length &&
        !loadingMessage &&
        !!currentChannel?.last_read?.count && (
          <div
            className={styles.latestMessageCnt}
            onClick={() => {
              loadLatest();
              _scrollRef.current.scrollToBottom();
            }}
          >
            <div className={styles.latestMessage}>
              <svg
                fill="#9d9b98"
                width="10px"
                height="10px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ margin: "2px", marginLeft: "4px" }}
              >
                <path d="M3.293,14.707a1,1,0,0,1,1.414-1.414L11,19.586V2a1,1,0,0,1,2,0V19.586l6.293-6.293a1,1,0,0,1,1.414,1.414l-8,8a1,1,0,0,1-.325.216.986.986,0,0,1-.764,0,1,1,0,0,1-.325-.216Z" />
              </svg>
              {currentChannel?.last_read?.count} new messages
              <Icon
                path={mdiClose}
                size="14px"
                className="ml-1 mr-1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLatestButton(false);
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export function getMsgData(messageText) {
  const match = /.*?####start([\s\S]*)####end/.exec(messageText);
  if (match?.length > 1) {
    try {
      const msgData = JSON.parse(match[1]);
      return msgData;
    } catch {}
  }
}

MessageList.propTypes = {
  disableActions: PropTypes.bool,
  onReachEnd: PropTypes.func,
  onOpenImageViewer: PropTypes.func,
  loadLatest: PropTypes.func,
};

export default MessageList;
