import React, { useState, useEffect, useRef } from "react";
import { useSelector, connect } from "react-redux";
import SearchInput from "../shared/SearchInput";
import {
  CHART_UPDATE_INTERVAL,
  DEFAULT_TIMEFRAME,
  CHART_NEGATIVE_COLOR,
  CHART_POSITIVE_COLOR,
  SEARCH_DROPDOWN_MODE_SYMBOL,
  TIMEFRAME_TRANSFORM_MAP,
  TIMEFRAME_INVERSE_TRANSFORM_MAP,
} from "../constants";
import { isMobile } from "react-device-detect";
import { ChartActions, DashboardActions } from "../store";
import style from "./style.module.scss";
import ChartBox from "./ChartBox";
import API from "../api";
import { Spinner } from "react-bootstrap";

import TimeframeSelector from "../shared/TimeframeSelector";
import _ from "lodash";
import RecentSymbols from "../shared/RecentSymbols";

function Chart({
  updateChartWidgetSymbol,
  updateTf,
  updateChartColor,
  updateSource,
  updateSearchDropdown,
  addRecent,
  removeRecent,
}) {
  const searchedSymbol = useSelector((state) => state.dashboard.searchDropdown.clicked.symbol);
  const searchedSymbolSource = useSelector((state) => state.dashboard.searchDropdown.clicked.source);
  const chartWidgetSymbol = useSelector((state) => state.chart.symbol);
  const tf = useSelector((state) => state.chart.tf);
  const recents = useSelector((state) => state.chart.recents);
  const chartColor = useSelector((state) => state.chart.color);

  const [searchBox, setSearchBox] = useState("");
  const [data, setData] = useState(null);

  const prevValues = useRef({});
  const chosenRecent = useRef(null);

  const autoUpdaterInterval = useRef(null);
  const autoUpdaterId = useRef(null);

  const fetchData = useRef(() => {});

  fetchData.current = async (autoUpdate, updaterId) => {
    try {
      if (!autoUpdate) setData(null);

      let newData = await API.getHistoricalPrice(chartWidgetSymbol, tf);
      newData = newData.data.map((dt) => ({
        time: dt.t,
        value: dt.val,
      }));

      if (data?.length > 0) {
        const compareData = _.cloneDeep(data);
        delete compareData[compareData.length - 1].bullet;
        if (_.isEqual(newData, compareData)) {
          return;
        }
      }

      if (autoUpdate) {
        if (autoUpdaterId.current !== updaterId) return;

        if (newData?.length > 0) {
          newData[newData.length - 1] = {
            ...newData[newData.length - 1],
            bullet: true,
          };
        }
      }
      if (newData) setData(newData);
    } catch {}
  };

  useEffect(() => {
    window.addEventListener("chartWidgetUpdate", onChartWidgetUpdate, false);
    window.opener && window.opener.addEventListener("chartWidgetUpdate", onChartWidgetUpdate, false);

    return () => {
      if (autoUpdaterInterval.current) {
        clearInterval(autoUpdaterInterval.current);
      }

      window.removeEventListener("chartWidgetUpdate", onChartWidgetUpdate);
      window.opener && window.opener.removeEventListener("chartWidgetUpdate", onChartWidgetUpdate);
    };
  }, []);

  useEffect(() => {
    if (searchedSymbol && searchedSymbolSource === "chart") {
      updateChartWidgetSymbol(searchedSymbol);
      return;
    }
  }, [searchedSymbol]);

  useEffect(() => {
    if (chartWidgetSymbol) {
      if (chosenRecent.current !== chartWidgetSymbol) addRecent(chartWidgetSymbol);
    }

    fetchData.current();

    if (autoUpdaterInterval.current) {
      clearInterval(autoUpdaterInterval.current);
    }
    const timeId = (autoUpdaterId.current = Date.now());
    autoUpdaterInterval.current = setInterval(() => {
      fetchData.current(true, timeId);
    }, CHART_UPDATE_INTERVAL);
  }, [chartWidgetSymbol, tf]);

  useEffect(() => {
    if (data?.length > 0) {
      updateChartColor(data[data.length - 1].value > data[0].value ? CHART_POSITIVE_COLOR : CHART_NEGATIVE_COLOR);
    }
  }, [data]);

  function onChartWidgetUpdate(event) {
    const { symbol, color, tf, source } = event.detail;

    symbol && updateChartWidgetSymbol(symbol, source);
    color && updateChartColor(color);
    tf && updateTf(tf);
    source && updateSource(source);
  }

  return (
    <div
      //  style={{ width: "100%", height: "100%" }}
      className={"card " + style.card}
    >
      <div className={" " + style.otherContent}>
        <div className="d-flex flex-row justify-content-between mb-1 align-items-center">
          <h4 style={{ marginBottom: "0px" }}>Chart</h4>
          <div className="discovery-filter-right d-flex flex-row align-items-center">
            <TimeframeSelector
              timeframe={(() => {
                return TIMEFRAME_INVERSE_TRANSFORM_MAP[tf] ?? DEFAULT_TIMEFRAME;
              })()}
              setTimeframe={(tf) => {
                tf = TIMEFRAME_TRANSFORM_MAP[tf];
                updateTf(tf);
              }}
            />
            <SearchInput
              className={`ml-2`}
              value={searchBox}
              placeholder="Symbol..."
              onChange={onChangeSearch}
              onFocus={(e) => onFocusSearch(e, true)}
              onBlur={(e) => onFocusSearch(e, false)}
              onClear={clearSearch}
            />
          </div>
        </div>

        <RecentSymbols
          recents={recents}
          symbol={chartWidgetSymbol}
          onSelect={(item) => {
            chosenRecent.current = item;
            updateChartWidgetSymbol(item);
          }}
          onRemove={(item) => removeRecent(item)}
        />
      </div>

      {data ? (
        <div className={style.chartCnt}>
          <ChartBox data={data} chartColor={chartColor} tf={tf} symbol={chartWidgetSymbol} />
        </div>
      ) : (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="success" />
        </div>
      )}
    </div>
  );

  function onChangeSearch(text, boundingRect) {
    const value = text;
    setSearchBox(value);
    const rect = boundingRect;
    updateSearchDropdown({
      mode: SEARCH_DROPDOWN_MODE_SYMBOL,
      visible: true,
      mobileVisible: true,
      mobileSearch: value,
      search: value,
      source: "chart", // TODO: get classname
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    });
  }
  function clearSearch() {
    setSearchBox("");
    updateSearchDropdown({
      mode: "",
      visible: false,
      mobileVisible: false,
      mobileSearch: "",
      search: "",
      source: null,
    });
  }
  function onFocusSearch(e, focused) {
    if (focused) {
      const rect = e.target.getBoundingClientRect();
      updateSearchDropdown({
        mode: SEARCH_DROPDOWN_MODE_SYMBOL,
        visible: !!searchBox,
        mobileVisible: true,
        mobileSearch: searchBox,
        search: searchBox,
        source: "chart", // TODO: get classname
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
      });
    } else {
      if (!isMobile) {
        setTimeout(() => {
          updateSearchDropdown({
            mode: "",
            visible: false,
            mobileVisible: false,
            mobileSearch: "",
            search: "",
            source: null,
          });
        }, 200);
      }
    }
  }
}
const mapDispatchToProps = {
  updateSearchDropdown: DashboardActions.updateSearchDropdown,
  updateChartWidgetSymbol: ChartActions.updateSymbol,
  updateTf: ChartActions.updateTf,
  updateChartColor: ChartActions.updateColor,
  updateSource: ChartActions.updateSource,
  addRecent: ChartActions.addRecent,
  removeRecent: ChartActions.removeRecent,
};
const mapStateToProps = (state) => ({
  chartState: state.chart,
});
export default connect(mapStateToProps, mapDispatchToProps)(Chart);
