import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function MsgDateLabel({ date, textStyle }) {
  return (
    <div className={styles["message-group-separator"]}>
      <div className={styles["date-separator-bar"]}></div>
      <div className={styles["date-label"]} style={textStyle}>
        {date}
      </div>
    </div>
  );
}

MsgDateLabel.propTypes = {
  date: PropTypes.string.isRequired,
  textStyle: PropTypes.object,
};

export default MsgDateLabel;
