import moment from "moment-timezone";

import {
  DEFAULT_DISCOVERY_SECTOR,
  DISCOVERY_FILTER_TEMP_PREFIX,
  LAST_AT_FILTER_UNIT,
  SHOW_ALL_DISCOVERY_FILTER,
  TREND_TYPE,
} from "../constants";
import { decodeDiscoveryFieldForTimeframe, encodeDiscoveryFieldForTimeframe } from "../util";

// This variable has 1:1 mapping with constants.js/DISCOVERY_COLUMN_LABELS that is used in Discovery column rendering
export const DISCOVERY_COLUMN_RELATED_DATA_FIELDS = {
  price_dist: ["price_dist", "dollar_dist", "last"],
  volume: ["volume", "volume_dist"],
  marketCap: ["marketCap"],
  float: ["float"],
  momentum: ["momentum"],
  tradeCount: ["tradeCount", "tradeCount_dist"],
  uVol: ["uVol"],
  vWapDist: ["vWapDist"],
  vWapSlope: ["vWapSlope"],
  moneyflow: ["moneyflow", "moneyflow_dist"],
  short_ratio: ["short_ratio"],
  squeeze: ["squeeze"],
  atr: ["atr", "atr_dollar_dist"],
  trend: ["trend"],
  rs: ["rs"],
  gap: ["gap", "gap_percent_dist"],
  halt: ["halt", "luld", "lastHalt"],
  actions: [],
  sparkline: [],
};

// This variable has 1:1 mapping with constants.js/DISCOVERY_COLUMN_LABELS that is used in Discovery column rendering
export const DISCOVERY_COLUMN_RELATED_FILTER_FIELDS = {
  price_dist: ["last", "price_dist"],
  volume: ["rv_price", "rv_dist"],
  marketCap: ["marketCap"],
  float: ["float"],
  momentum: ["momentum"],
  tradeCount: ["tradeCount", "tradeCount_dist"],
  uVol: ["uVol"],
  vWapDist: ["vWapDist"],
  vWapSlope: ["vWapSlope"],
  moneyflow: ["mf_price", "mf_dist"],
  short_ratio: ["short_ratio"],
  squeeze: ["squeeze"],
  atr: ["atr"],
  trend: ["trend"],
  rs: ["rs"],
  gap: ["gap"],
  halt: ["halt"],
  actions: [],
  sparkline: [],
};

export const transformDiscoveryItem = (item, priceDistSPY) => {
  const res = {
    symbol: item.s,
    marketCap: item.c,
    last: item.l || 0,
    price_dist: item.p || [],
    priorDayLast: item.pl,
    volume: item.v || 0,
    avgVolume: item.av || 0,
    momentum: item.m || 0,
    uVol: item.u || 0,
    vWapDist: item.vw || 0,
    vWapSlope: item.vws || 0,
    sector: item.e,
    float: item.f || 0,
    short_ratio: item.sr || 0,
    trending: item.t,
    news: item.n,
    gap: item.g,
    squeeze: item.z,
    atr: item.tr,
    trend: item.st || [],
    moneyflow: item.mf || [],
    realVol: item.rv || [],
    halt: item.h,
    lastHalt: item.lha,
    luld: item.ud,
    tradeCount: item.tc,
  };

  const pd = res["price_dist"] || [];
  const mf = res["moneyflow"] || [];
  const rv = res["realVol"] || [];
  const tc = res["tradeCount"] || [];
  res["price_dist"] = pd[0];
  res["dollar_dist"] = pd[1];
  res["moneyflow"] = mf[0];
  res["moneyflow_dist"] = mf[1];
  res["volume"] = rv[0];
  res["volume_dist"] = rv[1];
  delete res["realVol"];
  res["tradeCount"] = tc[0];
  res["tradeCount_dist"] = tc[1];
  if (!res["squeeze"] && res["squeeze"] !== 0 && res["squeeze"] !== "0") {
    res["squeeze"] = "-";
  }
  if (!res["moneyflow"] && res["moneyflow"] !== 0 && res["moneyflow"] !== "0") {
    res["moneyflow"] = "-";
  }
  if (!res["trend"]) {
    res["trend"] = [null, 0];
  }

  res["atr_dollar_dist"] = (res["atr"] * res["last"]) / 100;
  if (!isNaN(res["atr_dollar_dist"])) {
    res["atr_dollar_dist"] = Number(res["atr_dollar_dist"].toFixed(2));
  }

  res["price_dist"] = res["price_dist"] || 0;
  res["dollar_dist"] = res["dollar_dist"] || 0;

  res["gap_percent_dist"] = 0;
  if (res["gap"] && res["priorDayLast"]) {
    res["gap_percent_dist"] = (res["gap"] / res["priorDayLast"]) * 100;
    res["gap_percent_dist"] = Number(res["gap_percent_dist"].toFixed(2));
  }

  res["rs"] = res["price_dist"] - priceDistSPY; // (Math.random() - 0.5) * 20 . toFixed(2)

  for (const key of ["volume_dist", "moneyflow_dist", "tradeCount_dist", "uVol"]) {
    if (res[key] === "Inf") {
      res[key] = Infinity;
    }
  }

  return res;
};

export const updateDiscoveryItem = (item, data, timeframe = null, validCustomDataFields = {}) => {
  const update = {};
  let shouldUpdateATRDollarDist = false;
  if (data.hasOwnProperty("l") && !isNaN(data.l)) {
    update.last = data.l || 0;
    shouldUpdateATRDollarDist = true;
  }
  if (data.hasOwnProperty("p") && Array.isArray(data.p)) {
    update.price_dist = data.p[0] || 0;
    update.dollar_dist = data.p[1] || 0;
  }
  if (data.hasOwnProperty("m")) {
    update.momentum = data.m || 0;
  }
  if (data.hasOwnProperty("rv")) {
    update.volume = data.rv[0];
    update.volume_dist = data.rv[1];
    if (update.volume_dist === "Inf") {
      update.volume_dist = Infinity;
    }
  }
  if (data.hasOwnProperty("mf")) {
    update.moneyflow = data.mf[0];
    update.moneyflow_dist = data.mf[1];
    if (!update.moneyflow && update.moneyflow !== 0 && update.moneyflow !== "0") {
      update.moneyflow = "-";
    }
    if (update.moneyflow_dist === "Inf") {
      update.moneyflow_dist = Infinity;
    }
  }
  if (data.hasOwnProperty("h")) {
    if (data.h === 1) {
      update.halt = true;
      update.lastHalt = new Date().getTime();
    }
    if (data.h === -1) {
      update.halt = false;
    }
  }
  if (data.hasOwnProperty("ud")) {
    if (data.ud === -1) {
      update.luld = null;
    }
    if (Array.isArray(data.ud)) {
      update.luld = data.ud;
    }
  }
  if (data.hasOwnProperty("g")) {
    update.gap = data.g;
    if (update.gap && item.priorDayLast) {
      update.gap_percent_dist = (update.gap / item.priorDayLast) * 100;
      update.gap_percent_dist = Number(update.gap_percent_dist.toFixed(2));
    } else {
      update.gap_percent_dist = 0;
    }
  }
  if (data.hasOwnProperty("z")) {
    update.squeeze = data.z;
    if (!update.squeeze && update.squeeze !== 0 && update.squeeze !== "0") {
      update.squeeze = "-";
    }
  }
  if (data.hasOwnProperty("tr")) {
    update.atr = data.tr;
    shouldUpdateATRDollarDist = true;
  }
  if (data.hasOwnProperty("st") && Array.isArray(data.st)) {
    update.trend = data.st;
  }
  if (data.hasOwnProperty("vw")) {
    update.vWapDist = data.vw || 0;
  }
  if (data.hasOwnProperty("vws")) {
    update.vWapSlope = data.vws || 0;
  }
  if (data.hasOwnProperty("u")) {
    update.uVol = data.u || 0;
    if (update.uVol === "Inf") {
      update.uVol = Infinity;
    }
  }
  if (data.hasOwnProperty("tc") && Array.isArray(data.tc)) {
    update.tradeCount = data.tc[0];
    update.tradeCount_dist = data.tc[1];
    if (update.tradeCount_dist === "Inf") {
      update.tradeCount_dist = Infinity;
    }
  }
  if (data.hasOwnProperty("n")) {
    update.news = data.n;
  }
  if (data.hasOwnProperty("t")) {
    update.trending = data.t;
  }
  if (shouldUpdateATRDollarDist) {
    let atr = item.atr;
    let last = item.last;
    if (update.hasOwnProperty("atr")) {
      atr = update.atr;
    }
    if (update.hasOwnProperty("last")) {
      last = update.last;
    }
    update.atr_dollar_dist = (atr * last) / 100;
    if (!isNaN(update.atr_dollar_dist)) {
      update.atr_dollar_dist = Number(update.atr_dollar_dist.toFixed(2));
    }
  }
  // TODO: update RelStr
  // TDOO: handle lha
  const customFields = validCustomDataFields[timeframe];
  for (const field in update) {
    if (!timeframe || (Array.isArray(customFields) && customFields.includes(field))) {
      item[encodeDiscoveryFieldForTimeframe(field, timeframe)] = update[field];
    }
  }
};

export const transformDiscoveryItemForTimeframe = (item, timeframe) => {
  return Object.keys(item).reduce((acc, field) => {
    acc[encodeDiscoveryFieldForTimeframe(field, timeframe)] = item[field];
    return acc;
  }, {});
};

export const extractDiscoveryItemForCustomColumn = (item, column) => {
  return (DISCOVERY_COLUMN_RELATED_DATA_FIELDS[column] || []).reduce((acc, field) => {
    acc[field] = item[field];
    return acc;
  }, {});
};

export const sectorFilter = (item, sector) => {
  if (!Array.isArray(sector) || sector.length === 0) {
    return true;
  }
  if (sector.includes(DEFAULT_DISCOVERY_SECTOR)) {
    return true;
  }
  return sector.includes(item.sector);
};

export const inRange = (item, min, max, key) => {
  let x = item[key];
  const columnInfo = decodeDiscoveryFieldForTimeframe(key);
  if (columnInfo.column === "squeeze") {
    let res = false;
    min = min || {};
    if (min["NOW"] && x == 0) res = true;
    if (min["PRE"] && !isNaN(x) && x > 0) {
      if (min["PRE"] === true || min["PRE"] === "true") {
        res = true;
      } else {
        const preParsed = parseInt(min["PRE"]);
        if (!isNaN(preParsed) && preParsed > 0 && x >= preParsed) {
          res = true;
        }
      }
    }
    if (min["POST"] && !isNaN(x) && x < 0 && x >= -5) {
      if (min["POST"] === true || min["POST"] === "true") {
        res = true;
      } else {
        const postParsed = parseInt(min["POST"]);
        if (!isNaN(postParsed) && postParsed < 0 && postParsed >= -5 && x >= postParsed) {
          res = true;
        }
      }
    }
    return res;
  } else if (columnInfo.column === "trend") {
    return (min === TREND_TYPE.BUY && x[0]) || (min === TREND_TYPE.SELL && !x[0]);
  } else if (columnInfo.column === "news") {
    let res = false;
    if (!isNaN(min?.threshold) && LAST_AT_FILTER_UNIT.includes(min?.unit) && x) {
      const thresholdMoment = moment();
      thresholdMoment.subtract(min.threshold, min.unit === "mins" ? "minutes" : min.unit);
      if (x && moment(x).isAfter(thresholdMoment)) {
        res = true;
      }
    }
    return res;
  } else if (columnInfo.column === "halt") {
    let res = false;
    x = item["lastHalt"];
    if (!isNaN(min?.threshold) && LAST_AT_FILTER_UNIT.includes(min?.unit) && x) {
      const thresholdMoment = moment();
      thresholdMoment.subtract(min.threshold, min.unit === "mins" ? "minutes" : min.unit);
      if (x && moment(new Date(x)).isAfter(thresholdMoment)) {
        res = true;
      }
    }
    return res;
  } else {
    const fieldMap = {
      mf_price: "moneyflow",
      mf_dist: "moneyflow_dist",
      rv_price: "volume",
      rv_dist: "volume_dist",
      gap: "gap_percent_dist",
    };
    if (fieldMap[columnInfo.column]) {
      x = item[encodeDiscoveryFieldForTimeframe(fieldMap[columnInfo.column], columnInfo.timeframe)];
    }
    if (columnInfo.column === "mf_dist" || columnInfo.column === "rv_dist") {
      if (isNaN(x)) return false;
    }
    if (x >= min && x <= max) {
      return true;
    }
    return false;
  }
};

export const getPersistedTableFilters = (state, widget) => {
  const { tableFilters, selectedTableFilter } = state;
  const filtered = Object.keys(tableFilters || {}).filter(
    (name) => !name.startsWith(DISCOVERY_FILTER_TEMP_PREFIX) || name === selectedTableFilter[widget]
  );
  if (filtered[0] !== SHOW_ALL_DISCOVERY_FILTER) {
    const index = filtered.indexOf(SHOW_ALL_DISCOVERY_FILTER);
    if (index > -1) {
      filtered.splice(index, 1);
      filtered.unshift(SHOW_ALL_DISCOVERY_FILTER);
    }
  }
  return filtered;
};

export const getDiscoveryFieldCodeForSubscription = (field, type, subscribeMode = true) => {
  const realtimeSortFieldMap = {
    // Sort fields
    price_dist: ["l", "p"],
    volume: ["rv"],
    moneyflow: ["mf"],
    momentum: ["m"],
    tradeCount: ["tc"],
    vWapDist: ["vw"],
    vWapSlope: ["vws"],
    squeeze: ["z"],
    trend: ["st"],
    atr: ["tr"],
    halt: ["h", "ud"],
    gap: ["g"],
    rs: ["p"],
    uVol: ["u"],
    symbol: ["n", "t"],
    // "marketCap": ["c"],
    // "float": ["f"],
    // "short_ratio": ["sr"],
  };
  const realtimeFilterFieldMap = {
    // Filter fields
    atr: ["tr"],
    // "avgVolume": ["av"],
    // "float": ["f"],
    gap: ["g"],
    halt: ["h", "ud", "lha"],
    last: ["l"],
    price_dist: ["p"],
    // "marketCap": ["c"],
    momentum: ["m"],
    mf_price: ["mf"],
    mf_dist: ["mf"],
    news: ["n"],
    rs: ["p"],
    // "short_ratio": ["sr"],
    squeeze: ["z"],
    tradeCount: ["tc"],
    tradeCount_dist: ["tc"],
    trend: ["st"],
    uVol: ["u"],
    rv_price: ["rv"],
    rv_dist: ["rv"],
    vWapDist: ["vw"],
    vWapSlope: ["vws"],
  };

  if (type === "sort") {
    return realtimeSortFieldMap[field] || [];
  } else if (type === "filter") {
    if (field === "halt") {
      if (subscribeMode) {
        return realtimeFilterFieldMap[field].slice(0, 2);
      } else {
        return realtimeFilterFieldMap[field].slice(2);
      }
    }
    return realtimeFilterFieldMap[field] || [];
  }

  return [];
};
