import React, { useEffect, useMemo, useRef, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import cogoToast from "cogo-toast";
import { isMacOs } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import MsgSendButton from "./MsgSendButton";
import MsgReplyStatus from "./MsgReplyStatus";
import MsgTypingStatus from "./MsgTypingStatus";

import ChatService from "../ChatService";
import { ChatTypes } from "../chatReducer";
import { ChatActions } from "../../store";

import API from "../../api";

import styles from "./index.module.scss";

const emojiExampleStyle = {
  flex: 1,

  control: {
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 63,
  },

  highlighter: {
    padding: 9,
    border: "1px solid transparent",
  },

  input: {
    fontSize: 16,
    lineHeight: 1.2,
    padding: 9,
    border: "1px solid silver",
    color: "white",
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      zIndex: -1,
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      color: "black",

      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

const defaultMentionStyle = {
  color: "#f6c548",
};

const typingStatusTimeTolerance = 1000; // 1 seconds

function MessageInputPanel({ slowModeTimeRemaining, files, onFileRemove, onFileRemoveAll, loadLatest }) {
  const dispatch = useDispatch();

  const { input: msgInput, searchInput, channelSelected, replyMsg, typingInfo } = useSelector((state) => state.chat);
  const { user: auth_user } = useSelector((state) => state.auth);
  const usernames = useSelector((state) => state.misc.usernames || []);

  const typingStatusLastSentTS = useRef();

  const [imgUploading, setImgUploading] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const usernameList = useMemo(() => {
    let list = Array.isArray(usernames) ? usernames : [];
    return list.map(({ username }) => ({
      id: username,
      display: username,
    }));
  }, [usernames]);

  const disableChat = slowModeTimeRemaining > 0 || (!!searchInput && !replyMsg) || imgUploading;
  const inputPlaceholder =
    slowModeTimeRemaining > 0 ? "" : searchInput && !replyMsg ? "Clear search or reply" : "Mention people using '@'";

  const onChange = (text) => {
    dispatch({
      type: ChatTypes.SET_INPUT,
      value: text.target.value,
    });
    const now = new Date().getTime();
    if (!typingStatusLastSentTS.current || now - typingStatusLastSentTS.current > typingStatusTimeTolerance) {
      ChatService.notifyTypingIndicator();
      typingStatusLastSentTS.current = now;
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Escape") {
      clearReplyStatus();
    }
    if (e.keyCode === 13) {
      if (e.ctrlKey || e.shiftKey || e.altKey) {
        dispatch({
          type: ChatTypes.SET_INPUT,
          value: msgInput + "\n",
        });
      } else {
        e.preventDefault();
        onSend();
      }
    }
  };

  const clearReplyStatus = () => {
    dispatch({
      type: ChatTypes.SET_REPLY_MSG,
      value: null,
    });
  };

  const onSend = async () => {
    let refinedInput = msgInput.trim();
    if (!refinedInput && files?.length == 0) {
      return;
    }

    let urls;
    if (files?.length > 0) {
      const formData = new FormData();
      for (const file of files) {
        formData.append("images", file);
      }

      try {
        setImgUploading(true);
        const res = await API.uploadChatImages(formData);

        if (res.error) {
          cogoToast.error("Failed to upload some images!");
        }

        urls = res.urls;
      } catch (e) {
        if (e === "LIMIT_FILE_SIZE") {
          cogoToast.error("Failed to upload images. Image file size is too large.");
        } else if (e === "LIMIT_FILE_COUNT") {
          cogoToast.error("Failed to upload images. Too many number of images.");
        } else if (e === "FILE_UPLOAD_FAIL") {
          cogoToast.error("Failed to upload images!");
        } else {
          cogoToast.error("Failed to upload images!");
        }
        urls = null;
      } finally {
        setImgUploading(false);
      }
    }

    refinedInput = refinedInput.replaceAll(/@[(](?<value>[^@)]+)[)]/g, function () {
      const group = arguments[arguments.length - 1];
      return `<span class="chat-msg-username">@${group.value}</span>`;
    });
    refinedInput = refinedInput.replaceAll(/[$][a-zA-z]+/g, function () {
      return `<span class="chat-msg-symbol">${arguments[0]}</span>`;
    });

    await loadLatest();
    ChatService.sendMessage({
      channel: channelSelected,
      msg: refinedInput,
      files: urls,
      replyMsg: replyMsg
        ? {
            id: replyMsg?.id,
            m: replyMsg?.content,
            ts: replyMsg?.created_at,
            u: {
              id: replyMsg?.user?.id,
              n: replyMsg?.user?.name,
            },
          }
        : null,
    });
    dispatch({
      type: ChatTypes.SET_INPUT,
      value: "",
    });
    clearReplyStatus();
    onFileRemoveAll && onFileRemoveAll();
  };

  const typingStatus = useMemo(() => {
    if (auth_user && typingInfo) {
      let username, others;

      const filtered = (typingInfo.users || []).filter((item) => item.id !== auth_user.id);
      username = filtered[0]?.name;
      others = filtered.length === (typingInfo.users || []).length ? typingInfo.tot - 1 : typingInfo.tot - 2;

      return username
        ? {
            username,
            others,
          }
        : null;
    } else {
      return null;
    }
  }, [auth_user, typingInfo]);

  return (
    <>
      <div className={`${styles["message-input"]}`}>
        <div style={{ width: "100%" }}>
          {typingStatus && <MsgTypingStatus {...typingStatus} />}
          {replyMsg && <MsgReplyStatus replyUser={replyMsg?.user?.name} onClose={() => clearReplyStatus()} />}
          <div style={{ width: "100%", position: "relative" }}>
            <div className={styles["message-input-wrapper"]}>
              <MentionsInput
                value={msgInput}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                className="mentions"
                classNames={styles}
                // style={emojiExampleStyle}
                placeholder={inputPlaceholder}
                disabled={disableChat}
                suggestionsPortalHost={window.document.body}
                allowSuggestionsAboveCursor={true}
              >
                <Mention
                  trigger="@"
                  displayTransform={(username) => `@${username}`}
                  markup="@(__id__)"
                  data={usernameList}
                  // regex={/@(\S+)/}
                  className={styles["mentions__mention__user"]}
                  // style={defaultMentionStyle}
                  appendSpaceOnAdd
                />
                <Mention
                  trigger="$"
                  displayTransform={(symbol) => `$${symbol.toUpperCase()}`}
                  markup="$__id__"
                  data={[]}
                  regex={/[$]([a-zA-z]+)/}
                  className={styles["mentions__mention__stock"]}
                  // style={defaultMentionStyle}
                  appendSpaceOnAdd
                />
              </MentionsInput>
              {files?.length > 0 && (
                <div className={styles["message-files"]}>
                  {files.map((file, index) => {
                    const url = URL.createObjectURL(file);
                    return (
                      <div key={`chat-img-${index}`}>
                        <img src={url} />
                        {!imgUploading && (
                          <span className={styles["message-img-remove"]}>
                            <i
                              className="mdi mdi-close"
                              onClick={() => {
                                onFileRemove && onFileRemove(file);
                              }}
                            />
                          </span>
                        )}
                        {imgUploading && (
                          <span className={styles["message-img-loading"]}>
                            <i className="fa fa-circle-o-notch fa-spin ml-1"></i>
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {slowModeTimeRemaining > 0 && (
              <div className={styles["slow-mode-status"]}>
                <span>Slow mode: {slowModeTimeRemaining} sec</span>
              </div>
            )}
          </div>
        </div>
        <MsgSendButton onClick={onSend} disabled={disableChat} />
      </div>
      <span className={styles["message-input-subtext"]} style={{ visibility: inputFocus ? "visible" : "hidden" }}>
        Drag or paste images with <strong>{isMacOs ? "Cmd" : "Ctrl"} + V</strong>
      </span>
    </>
  );
}

MessageInputPanel.propTypes = {
  slowModeTimeRemaining: PropTypes.number,
  files: PropTypes.array,
  onFileRemove: PropTypes.func,
  onFileRemoveAll: PropTypes.func,
};

export default MessageInputPanel;
