import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";

import ModalFormRowDesc from "../../../shared/ModalFormRowDesc";

import {
  getConditionalAlertColumnTitle,
  isMoneyColumn,
  isPercentColumn,
  isBillionColumn,
  isSingleColumn,
  isMinMaxColumn,
  isBiDirectionalColumn,
} from "../helper";
import {
  DEFAULT_DISCOVERY_SECTOR,
  LAST_AT_FILTER_UNIT,
  SECTORS_FILTER,
  TREND_CHANGE_TYPE,
  TREND_TYPE,
  CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER,
  CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA,
} from "../../../constants";

import styles from "./index.module.scss";

const getFilterSectorValue = (filterSector) => {
  if (filterSector.length === 1) return filterSector[0];
  const labels = Object.keys(SECTORS_FILTER);
  for (let item of labels) {
    if (filterSector.includes(item)) {
      return item + ` [${filterSector.length - 1}]`;
    }
  }
  return "";
};

function AlertFieldInput(props) {
  const {
    type,
    value,
    columnSelectable,
    columnList,
    removable,
    updateFieldColumn,
    updateFieldValue,
    deleteFilterItem,
  } = props;

  const [sectorDropdownShown, setSectorDropdownShown] = useState(false);
  const [sign, setSign] = useState(1);

  useEffect(() => {
    const hasSignButton = isBiDirectionalColumn(value.column, type);
    if (hasSignButton && !isNaN(value.min) && value.min) {
      if (value.min > 0) {
        setSign(1);
      } else {
        setSign(-1);
      }
    }
  }, [value, type]);

  const renderSingleValueFilterItem = () => {
    const hasSignButton = isBiDirectionalColumn(value.column, type);
    return (
      <div className="d-flex flex-row align-items-center">
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMin">
          threshold
        </Form.Label>
        {hasSignButton && (
          <div
            className={styles["input-sign-button"]}
            onClick={() => {
              if (!isNaN(value.min) && value.min) {
                updateFieldValue && updateFieldValue("min", -1 * value.min);
              } else {
                setSign(-sign);
              }
            }}
          >
            <i className={`mdi ${sign > 0 ? "mdi-plus" : "mdi-minus"}`} />
          </div>
        )}
        <NumberFormat
          thousandSeparator={true}
          className={`form-control form-control-sm px-1 ${hasSignButton ? "ml-0" : "ml-1"} text-right`}
          inputMode="numeric"
          placeholder="value"
          prefix={isMoneyColumn(value.column) ? "$" : ""}
          suffix={isPercentColumn(value.column) ? "  %" : isBillionColumn(value.column) ? "  B" : ""}
          allowNegative={isBiDirectionalColumn(value.column, type)}
          style={{ color: "white" }}
          value={value.min}
          onValueChange={(values) => {
            let newValue = values.floatValue;
            if (hasSignButton && !value.min && value.min !== 0) {
              newValue = newValue * sign;
            }
            updateFieldValue && updateFieldValue("min", newValue);
          }}
        />
      </div>
    );
  };

  const renderMinMaxFilterItem = () => {
    return (
      <div className="d-flex flex-row align-items-center">
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMin">
          Min
        </Form.Label>
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="min"
          prefix={isMoneyColumn(value.column) ? "$" : ""}
          suffix={isPercentColumn(value.column) ? "  %" : isBillionColumn(value.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={value.min}
          onValueChange={(values) => {
            updateFieldValue && updateFieldValue("min", values.floatValue);
          }}
        />
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMax">
          Max
        </Form.Label>
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="max"
          prefix={isMoneyColumn(value.column) ? "$" : ""}
          suffix={isPercentColumn(value.column) ? "  %" : isBillionColumn(value.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={value.max}
          onValueChange={(values) => {
            updateFieldValue && updateFieldValue("max", values.floatValue);
          }}
        />
      </div>
    );
  };

  const renderSqueezeFilterItem = () => {
    const values = ["PRE", "NOW", "POST"];
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {values.map((item, id) => (
          <div
            key={`filter-squeeze-${id}`}
            className={`input-group-checkbox`}
            onClick={() => {
              const min = value.min;
              if (!min[item]) {
                if (item === "PRE") {
                  if (min["POST"]) return;
                  else {
                    min[item] = 7;
                  }
                } else if (item === "POST") {
                  if (min["PRE"]) return;
                  else {
                    min[item] = -2;
                  }
                } else {
                  if (min["PRE"] && min["POST"]) return;
                  else min[item] = true;
                }
              } else {
                min[item] = false;
              }
              updateFieldValue && updateFieldValue("min", min);
            }}
          >
            <span className={`chk-box ${value.min[item] ? "checked" : ""}`}></span>
            <span className="chk-label">{item}</span>
            {item !== "NOW" && (
              <NumberFormat
                className="form-control form-control-sm px-1 ml-1 text-right"
                inputMode="numeric"
                placeholder=""
                style={{ color: "white", maxWidth: "40px", height: "24px" }}
                disabled={!value.min[item]}
                value={value.min[item] || ""}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onValueChange={(values) => {
                  const min = value.min;
                  min[item] = values.value;
                  if (item === "PRE") {
                    if (min[item] !== "" && min[item] < 1) {
                      min[item] = 1;
                    }
                  }
                  if (item === "POST") {
                    if (min[item] !== "") {
                      if (min[item] < -5) {
                        min[item] = -5;
                      } else if (min[item] > -1) {
                        min[item] = -1;
                      }
                    }
                  }
                  updateFieldValue && updateFieldValue("min", min);
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderTrendFilterItem = () => {
    const trendOptions = [TREND_TYPE.BUY, TREND_TYPE.SELL];
    return (
      <div className="d-flex justify-content-end align-items-center flex-grow-1">
        <Dropdown varaint="btn btn-outline-secondary" className="mx-2" style={{ borderBottom: "1px solid white" }}>
          <Dropdown.Toggle className="industry_input d-flex align-items-center px-1">
            {renderTrendStatusItem(value.min)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {trendOptions.map((option) => {
              return (
                <Dropdown.Item
                  key={`filter-trend-${option}`}
                  onClick={() => {
                    updateFieldValue && updateFieldValue("min", option);
                  }}
                  tabIndex="1"
                >
                  {renderTrendStatusItem(option)}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderTrendChangeFilterItem = () => {
    const trendChangeOptions = [TREND_CHANGE_TYPE.BUY_TO_SELL, TREND_CHANGE_TYPE.SELL_TO_BUY];
    return (
      <div className="d-flex justify-content-end align-items-center flex-grow-1">
        <Dropdown varaint="btn btn-outline-secondary" className="mx-2" style={{ borderBottom: "1px solid white" }}>
          <Dropdown.Toggle className="industry_input d-flex align-items-center px-1">
            {value.min === TREND_CHANGE_TYPE.BUY_TO_SELL && (
              <div className="d-flex justify-content-between align-items-center">
                {renderTrendStatusItem(TREND_TYPE.BUY)}
                <span className="mx-1">&rarr;</span>
                {renderTrendStatusItem(TREND_TYPE.SELL)}
              </div>
            )}
            {value.min === TREND_CHANGE_TYPE.SELL_TO_BUY && (
              <div className="d-flex justify-content-between align-items-center">
                {renderTrendStatusItem(TREND_TYPE.SELL)}
                <span className="mx-1">&rarr;</span>
                {renderTrendStatusItem(TREND_TYPE.BUY)}
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {trendChangeOptions.map((option) => {
              return (
                <Dropdown.Item
                  key={`filter-trend-change-${option}`}
                  onClick={() => {
                    updateFieldValue && updateFieldValue("min", option);
                  }}
                  tabIndex="1"
                >
                  {option === TREND_CHANGE_TYPE.BUY_TO_SELL && (
                    <div className="d-flex justify-content-between align-items-center">
                      {renderTrendStatusItem(TREND_TYPE.BUY)}
                      <span className="mx-1">&rarr;</span>
                      {renderTrendStatusItem(TREND_TYPE.SELL)}
                    </div>
                  )}
                  {option === TREND_CHANGE_TYPE.SELL_TO_BUY && (
                    <div className="d-flex justify-content-between align-items-center">
                      {renderTrendStatusItem(TREND_TYPE.SELL)}
                      <span className="mx-1">&rarr;</span>
                      {renderTrendStatusItem(TREND_TYPE.BUY)}
                    </div>
                  )}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderTrendStatusItem = (option) => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <div className={`trend-wrapper ${option === TREND_TYPE.BUY ? "trending mb-1" : "mb-1"} mr-2`}>
          <div className="triangle"></div>
        </div>
        <span style={{ textTransform: "capitalize" }}>{option}</span>
      </div>
    );
  };

  const renderLastAtFilterItem = () => {
    const { threshold, unit } = value.min || {};
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {/* <div className={`input-group-checkbox`} onClick={() => {
        }}>
          <span className={`chk-box checked`}></span>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <NumberFormat
            className="form-control form-control-sm px-1 ml-1 text-right"
            inputMode="numeric"
            placeholder=""
            style={{ color: "white", maxWidth: "40px", marginTop: "2px" }}
            value={threshold}
            onValueChange={(values) => {
              updateFieldValue &&
                updateFieldValue("min", {
                  threshold: values.value,
                  unit,
                });
            }}
          />
          <Dropdown varaint="btn btn-outline-secondary" className="ml-2" style={{ borderBottom: "1px solid white" }}>
            <Dropdown.Toggle className="industry_input px-1">
              <span className="" style={{}}>
                {unit || "unit"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {LAST_AT_FILTER_UNIT.map((key) => {
                return (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      updateFieldValue &&
                        updateFieldValue("min", {
                          threshold,
                          unit: key,
                        });
                    }}
                    tabIndex="1"
                  >
                    <span
                      style={{
                        color: `${key === unit ? "#f6c548" : ""}`,
                      }}
                    >
                      {key}
                    </span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  const renderCategoryFilterItem = () => {
    return (
      <Dropdown
        varaint="btn btn-outline-secondary"
        className="mx-2"
        style={{ borderBottom: "1px solid white" }}
        show={sectorDropdownShown}
        onToggle={(isOpen, event, metadata) => {
          if (isOpen || metadata.source !== "select") {
            setSectorDropdownShown(isOpen);
          }
          // event.persist();
        }}
      >
        <Dropdown.Toggle className="industry_input px-1">
          {!Array.isArray(value.min) || value.min.length === 0 ? (
            <span className="">Select...</span>
          ) : (
            <span>{getFilterSectorValue(value.min)}</span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-2-columns">
          {[
            DEFAULT_DISCOVERY_SECTOR,
            ...Object.keys(SECTORS_FILTER).sort((a, b) => {
              if (a === "MISCELLANEOUS") return 1;
              if (b === "MISCELLANEOUS") return -1;
              if (a < b) return -1;
              if (b < a) return 1;
              return 0;
            }),
          ].map((sector) => {
            return (
              <Dropdown.Item
                key={sector}
                onClick={() => {
                  let newSectors = value.min;
                  if (!Array.isArray(newSectors)) newSectors = [];
                  if (newSectors.includes(sector)) {
                    newSectors = newSectors.filter((item) => item !== sector);
                  } else {
                    if (newSectors.includes(DEFAULT_DISCOVERY_SECTOR)) {
                      newSectors = [];
                    }
                    newSectors.push(sector);
                  }
                  if (newSectors.includes(DEFAULT_DISCOVERY_SECTOR)) newSectors = [DEFAULT_DISCOVERY_SECTOR];
                  updateFieldValue && updateFieldValue("min", newSectors);
                }}
                tabIndex="1"
              >
                <span
                  style={{
                    color: `${value.min.includes(sector) ? "#f6c548" : ""}`,
                  }}
                >
                  {sector}
                </span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderFilterItem = () => {
    if (value.column === "squeeze") {
      return renderSqueezeFilterItem();
    } else if (value.column === "trend") {
      return renderTrendFilterItem();
    } else if (value.column === "trend_change") {
      return renderTrendChangeFilterItem();
    } else if (value.column === "news" || value.column === "halt") {
      return renderLastAtFilterItem();
    } else if (isSingleColumn(value.column, type)) {
      return renderSingleValueFilterItem();
    } else if (!value.column || isMinMaxColumn(value.column, type)) {
      return renderMinMaxFilterItem();
    } else {
      return null;
    }
  };

  return (
    <div>
      <Form.Group className="d-flex align-items-center mb-0 py-1">
        {columnSelectable ? (
          <Dropdown
            varaint="btn btn-outline-secondary"
            style={{
              border: "1px solid rgb(128, 128, 128)",
              borderRadius: 4,
              padding: 2,
            }}
          >
            <Dropdown.Toggle className="industry_input" id="conditional-alert-column-dropdown" style={{ width: 120 }}>
              {value.column ? getConditionalAlertColumnTitle(value.column) : "Column"}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-2-columns">
              {columnList.map((c, i) => (
                <Dropdown.Item
                  key={`filter-column-value-${c}`}
                  onClick={() => {
                    updateFieldColumn && updateFieldColumn(c);
                  }}
                >
                  <span className="">{getConditionalAlertColumnTitle(c)}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <span className={styles["field-title"]}>{getConditionalAlertColumnTitle(value.column)}</span>
        )}
        {value.column === "category" && (
          <div
            className={`d-flex align-items-center justify-content-start flex-grow-1 ${styles["category-input-wrapper"]}`}
          >
            {renderCategoryFilterItem()}
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end flex-grow-1">
          {renderFilterItem()}
          {removable && (
            <i
              className="mdi mdi-trash-can pl-2 pr-0 pl-sm-3 pr-sm-3"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                deleteFilterItem && deleteFilterItem(value.column);
              }}
            />
          )}
        </div>
      </Form.Group>
      {value.column === "vWapSlope" && <ModalFormRowDesc content="Vector value range:  -10 to 10" />}
    </div>
  );
}

AlertFieldInput.propTypes = {
  type: PropTypes.oneOf([CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA, CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER]).isRequired,
  value: PropTypes.shape({
    column: PropTypes.string,
    min: PropTypes.any,
    max: PropTypes.any,
  }).isRequired,
  columnSelectable: PropTypes.bool,
  columnList: PropTypes.array,
  removable: PropTypes.bool,
  updateFieldColumn: PropTypes.func,
  updateFieldValue: PropTypes.func,
  deleteFilterItem: PropTypes.func,
};

AlertFieldInput.defaultProps = {
  columnSelectable: false,
  removable: false,
};

export default AlertFieldInput;
