import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import TimeframeSelector from "../../../shared/TimeframeSelector";

import { DISCOVERY_COLUMN_LABELS } from "../../../constants";
import { isColumnAccessible } from "../../../util";

import styles from "./index.module.scss";

const columns = Object.keys(DISCOVERY_COLUMN_LABELS)
  .filter((item) => !["marketCap", "float", "short_ratio", "gap", "halt", "actions", "sparkline"].includes(item))
  .sort((a, b) => {
    const labelA = DISCOVERY_COLUMN_LABELS[a].toUpperCase();
    const labelB = DISCOVERY_COLUMN_LABELS[b].toUpperCase();
    if (labelA < labelB) return -1;
    else if (labelA > labelB) return 1;
    else return 0;
  });

function CustomColumnPopup(props) {
  const { id, open, editItem, onHide, onSave, plan } = props;

  const [column, setColumn] = useState(null);
  const [timeframe, setTimeframe] = useState("1day");

  useEffect(() => {
    if (open) {
      if (editItem) {
        setColumn(editItem.column);
        setTimeframe(editItem.timeframe);
      } else {
        setColumn(null);
        setTimeframe("1day");
      }
    }
  }, [open, editItem]);

  const onClickSave = () => {
    onSave &&
      onSave(
        {
          column,
          timeframe,
        },
        editItem
      );
  };

  const isFormValid = () => {
    return column && timeframe;
  };

  return (
    <Modal
      id={id}
      className={"modal-drak-overlay"}
      show={open}
      onHide={onHide}
      aria-labelledby="Custom Column Modal"
      backdrop={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span style={{ fontSize: "1rem", fontWeight: "normal" }}>{editItem ? "Edit" : "Add"} Custom Column</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="d-flex justify-content-between align-items-center mb-0">
          <Dropdown
            varaint="btn btn-outline-secondary"
            style={{
              border: "1px solid rgb(128, 128, 128)",
              borderRadius: 4,
              padding: 2,
            }}
          >
            <Dropdown.Toggle className="industry_input" id="custom-column-dropdown" style={{ width: 120 }}>
              {column ? DISCOVERY_COLUMN_LABELS[column] : "Column"}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-2-columns">
              {columns
                .filter((c) => c !== column)
                .map((c, i) => (
                  <Dropdown.Item
                    key={`custom-column-value-${i}`}
                    onClick={() => {
                      setColumn(c);
                    }}
                    disabled={!isColumnAccessible({ column: c }, plan)}
                  >
                    <span className={`${!isColumnAccessible({ column: c }, plan) ? "text-symbol" : ""}`}>
                      {DISCOVERY_COLUMN_LABELS[c]}
                    </span>
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex flex-row align-items-center">
            <Form.Label className={`mt-1 mb-0 mr-2 d-none d-sm-block ${styles["custom-field-label"]}`}>
              Timeframe
            </Form.Label>
            <TimeframeSelector
              timeframe={timeframe}
              setTimeframe={(value) => {
                setTimeframe(value);
              }}
            />
          </div>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer className="justify-content-end">
        <div>
          <Button variant="success" disabled={!isFormValid()} onClick={onClickSave}>
            {editItem ? "Update" : "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

CustomColumnPopup.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  editItem: PropTypes.object,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  plan: PropTypes.object,
};

export default CustomColumnPopup;
