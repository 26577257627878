import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Modal, Form, Button } from "react-bootstrap";
import cogoToast from "cogo-toast";
import TextInput from "./TextInput";
import ChatService from "../../../chat/ChatService";

function ShareModal({ close, open, shareData }) {
  const [shareScope, setShareScope] = useState("everyone");
  const usernames = useSelector((state) => state.misc.usernames || []);
  const [shareUser, setShareUser] = useState("");

  const currentUsername = useSelector((state) => state.auth.user.username);
  const { channelSelected } = useSelector((state) => state.chat);

  const usernameList = useMemo(() => {
    let list = Array.isArray(usernames) ? usernames : [];
    return list.map(({ username }) => ({
      id: username,
      display: username,
    }));
  }, [usernames]);

  function sendFilter() {
    const filterShareData = {
      data: shareData,
      scope: shareScope,
      user: shareUser,
    };
    const msgData = {
      from: currentUsername,
      to: shareScope === "everyone" ? null : (shareUser + "").replace("@", ""),
      filterName: shareData.name,
    };

    if (filterShareData && msgData) {
      sendFilterMessage(channelSelected, filterShareData, msgData);
    }
    const message = `Filter "${msgData.filterName}" shared to ${
      shareScope === "everyone" ? "everyone" : msgData.to
    } in Chat`;
    cogoToast.success(message);
    close();
  }
  return (
    <Modal
      id="discovery-share-filter-modal"
      show={open}
      onHide={() => {
        close();
        setShareScope("everyone");
        setShareUser("");
      }}
      aria-labelledby="Discovery Filter Modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
          <div>Share Filter</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="discovery-filters">
        <Form.Group className="d-flex align-items-center mb-0">
          <div>Share via chat</div>
        </Form.Group>

        <Form.Group className="cursor-pointer ml-2">
          <Form.Check
            className="d-flex white"
            inline
            label={"Everyone"}
            type="radio"
            id={`scope-inline-radio-1`}
            name="type"
            checked={shareScope === "everyone"}
            onChange={(e) => {
              if (e.target.checked) {
                setTimeout(() => {
                  setShareScope("everyone");
                }, 10);
              }
            }}
          />
          <Form.Check
            className="d-flex cursor-pointer"
            inline
            label={"Private"}
            type="radio"
            id={`scope-inline-radio-2`}
            name="type"
            checked={shareScope === "private"}
            onChange={(e) => {
              if (e.target.checked) {
                setTimeout(() => {
                  setShareScope("private");
                }, 10);
              }
            }}
          />

          {shareScope === "private" && (
            <div className="ml-3">
              <TextInput usernames={usernameList} setShareUser={setShareUser} action={sendFilter} />
            </div>
          )}
        </Form.Group>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={sendFilter} disabled={!(shareScope === "everyone" || shareUser)}>
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

function sendFilterMessage(channel, filterMessage, msgData = {}) {
  if (filterMessage?.data?.name === undefined) {
    console.error("Invalid filter message", { filterMessage });
    return;
  }

  const messageParts = [];

  if (filterMessage.scope === "private" && filterMessage.user) {
    const userMention = `<span class="chat-msg-username">${filterMessage.user}</span>`;
    messageParts.push(userMention);
  }

  const filterData = `<span class="chatWidget-msg-sharedfilter"
  onClick='window.discoveryLoadFilter && window.discoveryLoadFilter(${JSON.stringify(filterMessage.data).replace(
    /'/g,
    ""
  )})'
  msgData='####start${JSON.stringify(msgData).replace(/'/g, "")}####end'
  >${filterMessage.data.name}</span>`;

  messageParts.push(filterData);

  ChatService.sendMessage({
    channel: channel,
    msg: messageParts.join(" "),
    files: null,
    replyMsg: null,
  });
}

export default ShareModal;
