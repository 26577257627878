import React, { useState, useMemo, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import styles from "./style.module.scss";

function TextInput({ usernames, setShareUser, action }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (value === "") {
      setTimeout(() => {
        setValue("@");
      }, 50);
    }
  }, []);

  let suggest = [];
  if (value.length > 2) {
    suggest = usernames;
  }

  return (
    <MentionsInput
      autoFocus
      value={value}
      onChange={(e, val, txtVal) => {
        let valid;
        let validUsername;

        try {
          validUsername = /^@\([a-zA-Z0-9_.-]+\)$/.test(val);

          valid = valid || /^@[a-zA-Z0-9_.-]+$/.test(val);
          valid = valid || validUsername;
        } catch (e) {
          valid = false;
        }
        valid = valid || val === "" || val === "@";

        if (valid) {
          setValue(val);
          if (validUsername || val === "") {
            setShareUser(txtVal);
          }
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          action();
        }
      }}
      className="mentions"
      classNames={styles}
      placeholder="Type '@' to find user to share with"
    >
      <Mention
        displayTransform={(username) => `@${username}`}
        markup="@(__id__)"
        data={suggest}
        // regex={/@(\S+)/}
        className={styles["mentions__mention__user"]}
        // style={defaultMentionStyle}
      />
    </MentionsInput>
  );
}

export default TextInput;
