import React from "react";
import PropTypes from "prop-types";

function ModalFormRowDesc({ content }) {
  return (
    <div className="mb-1" style={{ color: "#bfbfbf", fontSize: "12px" }}>
      {content}
    </div>
  );
}

ModalFormRowDesc.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ModalFormRowDesc;
