import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SECTORS_FILTER } from "../../constants";
import PropTypes from "prop-types";
import { DEFAULT_DISCOVERY_SECTOR } from "../../constants";

const ALL_SECTORS = "All";

const SectorSelector = ({
  selectedSectors = [DEFAULT_DISCOVERY_SECTOR],
  onChange,
  handleMultiSelect = false,
  hideIcon,
}) => {
  const [dropdownShown, setDropdownShown] = useState(false);

  const handleSectorSelect = (sector) => {
    let newSectors;

    if (handleMultiSelect) {
      if (sector === ALL_SECTORS) {
        newSectors = [ALL_SECTORS];
      } else {
        newSectors = selectedSectors.filter((s) => s !== ALL_SECTORS);
        if (selectedSectors.includes(sector)) {
          newSectors = newSectors.filter((s) => s !== sector);
        } else {
          newSectors.push(sector);
        }
        if (newSectors.length === 0) {
          newSectors = [ALL_SECTORS];
        }
      }
    } else {
      newSectors = [sector];
    }

    onChange(newSectors);
  };

  const getDisplayValue = () => {
    if (selectedSectors.includes(ALL_SECTORS)) return "All";
    if (selectedSectors.length === 1) return selectedSectors[0];
    if (!(selectedSectors.length > 0)) return "";

    return `${selectedSectors[0]} [${selectedSectors.length}]`;
  };

  const isSelected = (sector) => selectedSectors.includes(sector);

  return (
    <Dropdown
      show={dropdownShown}
      onToggle={(isOpen, event, metadata) => {
        if (isOpen || metadata.source !== "select") {
          setDropdownShown(isOpen);
        }
      }}
    >
      <Dropdown.Toggle className="industry_input">
        {!hideIcon && (
          <i
            className={`fa fa-industry`}
            style={{
              transform: "scale(0.8)",
              marginRight: ".4rem",
              ...(selectedSectors.includes(ALL_SECTORS) ? {} : { color: "#f6c548" }),
            }}
          />
        )}
        <span>{getDisplayValue()}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-2-columns">
        {[
          DEFAULT_DISCOVERY_SECTOR,
          ...Object.keys(SECTORS_FILTER).sort((a, b) => {
            if (a === "MISCELLANEOUS") return 1;
            if (b === "MISCELLANEOUS") return -1;
            if (a < b) return -1;
            if (b < a) return 1;
            return 0;
          }),
        ].map((sector) => (
          <Dropdown.Item key={sector} onClick={() => handleSectorSelect(sector)} tabIndex="1">
            <span
              style={{
                color: isSelected(sector) ? "#f6c548" : "",
              }}
            >
              {sector}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

SectorSelector.propTypes = {
  selectedSectors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  handleMultiSelect: PropTypes.bool,
};

export default SectorSelector;
